import React from 'react'
import { withProps } from 'recompose'
import { emptyObject } from '../common/constants'
import { composeWithDisplayName } from './hocs'

const Wrappable = ({ children, tag: Tag = 'div', wrap, ...props }) => {
  if (wrap) {
    return <Tag {...props}>{children}</Tag>
  }
  return children
}

export default Wrappable

export const WrapTag = composeWithDisplayName(
  'WrapTag',
  withProps(({ tag }) => {
    if (tag) {
      return { wrap: true }
    }
  })
)(Wrappable)

export const withWrapTag = Component => ({
  tag,
  wrapperProps = emptyObject,
  ...props
}) => (
  <WrapTag tag={tag} {...wrapperProps}>
    <Component {...props} />
  </WrapTag>
)
