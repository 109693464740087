import React, { Fragment, useState, useEffect } from 'react';
import styled from 'styled-components';
import {
	centionGreen,
	centionLightGrey,
	centionBlue
 } from '../styles/_variables'
import {
	StaticSnoopActions
 } from './Snoop'
 import {
	ONLINE_STATUS,
	AWAY_STATUS,
	INPROGRESS_STATUS,
	ONHOLD_STATUS,
	INSESSION_STATUS
 } from '../common/v5/constants';

const StyledOnlineStatus = styled.span`
	display: contents;
	&:before {
		display: ${props => props.hide ? 'none' : 'inline-block'};
		width: 11px;
		height: 11px;
		border-radius: 30px;
		background-color: ${props => props.online ? centionGreen : centionLightGrey};
		content: '';
		position: relative;
		transition: background-color .2s ease;
		margin: 0px 5px;
	}
`

export const SimpleTable = ({columns, dataSrc, customClass, customStyle, rowsPerPage, hideHeader, hideFooter, handleClick, onRowClick}) => {
	const [page, setPage] = useState(1);
	const { slice, range } = useTable(dataSrc, page, rowsPerPage);
	let className = "table table-striped";
	if(customClass){
		className = customClass;
	}
	return(
		<div>
			<table className={className} style={customStyle}>
				{
					!hideHeader &&
						<THeader columns={columns} />
				}
				<TBody dataSrc={dataSrc} columns={columns} slice={slice} handleClick={handleClick} onRowClick={onRowClick}/>
			</table>
			{ !hideFooter &&
				<TFooter range={range} slice={slice} setPage={setPage} page={page} total={dataSrc.length}/>
			}
		</div>
	)
}

const hideOnlineStatus = (status) => {
	if(status === ONLINE_STATUS || status  === AWAY_STATUS) {
		return false;
	} else {
		return true;
	}
}

const renderStatus = (status) => {
	switch(status) {
		case ONLINE_STATUS:
			return I("Online");
		case AWAY_STATUS:
			return I("Away");
		case INPROGRESS_STATUS:
		case Workflow.ErrandStates.STATE_IN_PROGRESS:
			return I("In Progress");
		case INSESSION_STATUS:
			return I("In Session");
		case ONHOLD_STATUS:
			return I("On hold");
		case Workflow.ErrandStates.STATE_ACQUIRED:
			return I("Acquired");
		case Workflow.ErrandStates.STATE_ARRIVED:
			return I("Arrived");
		case Workflow.ErrandStates.STATE_DEVELOPMENT:
			return I("Development");
		case Workflow.ErrandStates.STATE_INVESTIGATION:
			return I("Investigation");
		case Workflow.ErrandStates.STATE_PAUSED:
			return I("Paused");
		case Workflow.ErrandStates.STATE_POSTPONED:
			return I("Postponed");
		case Workflow.ErrandStates.STATE_RESOLVED:
			return I("Resolved");
		default:
			return "";
	}
}

const TDCroppedText = styled.td`
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	width: auto;
`

const TData = ({ children, ...props }) => {
	return <TDCroppedText {...props} >
		{children}
	</TDCroppedText>
}

const TBody = ({dataSrc, columns, slice, handleClick, onRowClick}) =>{
	let tr = (ds) =>{
		if(columns) {
			return columns.map((elm, i)=>{
				if(typeof elm !== "undefined"){
					if(elm.type === 'rating'){
						let star = '★';
						for(let j = 1; j < parseInt(ds[elm.key], 10); j++){
							star += '★';
						}
						return <TData key={`td${i}`}>{star}</TData>;
					}else if(elm.type === 'emoticon'){
						return <TData key={`td${i}`} className={`${ds[elm.key]}`}></TData>;
					}else if(elm.type === 'image'){
						return <TData key={`td${i}`}><img src={process.env.PATH_PREFIX + ds[elm.key]} /></TData>;
					}else if(elm.type === 'profile'){
						return <TData key={`td${i}`}>
							<span>{ds[elm.key].id}</span>
							<img src={process.env.PATH_PREFIX + ds[elm.key].img} />
							<span>{ds[elm.key].name}</span>
						</TData>;
					} else if(elm.type === 'preIcon'){
						return <TData key={`td${i}`} title={typeof ds[elm.key] === "number" ? renderStatus(ds[elm.key]) : ds[elm.key]}>
							<i className={ds["icon"]} style={{paddingRight: '3px'}}></i>
							<StyledOnlineStatus hide={hideOnlineStatus(ds[elm.key])} online={ds[elm.key] === ONLINE_STATUS}></StyledOnlineStatus>
							{typeof ds[elm.key] === "number" ? renderStatus(ds[elm.key]) : ds[elm.key]}
						</TData>;
					} else if(elm.type === 'icon') {
						return <TData key={`td${i}`} width={elm.width} hidden={elm["hide"] ? true : false}>
						<i className={ds[elm.key]}></i>
						</TData>;
					} else if(elm.type === 'list'){
						//list of array in text format , like "firstItem, secondItem, thirdItem")
						//will return something like ' firstItem + 2 ' which hover to 2 will see the rest of the 2 item in the list
						let list = ds[elm.key] ? ds[elm.key].split(",") : [];
						let first = "", other = [], count = 0, rest = "";
						if(list.length > 1) {
							list.map((el, j)=>{
								if(j === 0) {
									first = el;
								} else {
									other.push(el);
								}
							});
							rest = other.join(", ");
							count = other.length;
							return <TData key={`td${i}`} title={ds[elm.key]}>
								{first} + <span style={{color: '#0c87f7', fontWeight: '500', cursor: 'default'}} title={rest}>{count}</span>
							</TData>;
						} else {
							return <TData key={`td${i}`} title={ds[elm.key]}>{ds[elm.key]}</TData>;
						}
					} else if(elm.type === 'withText'){
						return <TData key={`td${i}`} title={ds[elm.key] +' '+ elm["text"]}>
							<StyledOnlineStatus hide={!elm["onlineStatus"]} online={true}></StyledOnlineStatus>
							<span style={{color: '#0c87f7', fontWeight: '500'}}>{ds[elm.key]}</span> {elm["text"]}
						</TData>;
					} else if(elm.type === 'withCustomText') {
						return <TData key={`td${i}`} title={ds[elm.key] +' '+ ds["customText"]}>
							<StyledOnlineStatus hide={!elm["onlineStatus"]} online={true}></StyledOnlineStatus>
							<span style={{color: '#0c87f7', fontWeight: '500'}}>{ds[elm.key]}</span> {ds["customText"]}
						</TData>;
					} else if(elm.type === 'snoop') {
						return <TData key={`td${i}`}><StaticSnoopActions src={ds} handleClick={handleClick} /></TData>;
					} else if(elm.type === 'postIcon'){
						return <TData key={`td${i}`} title={ds[elm.key]}>
							{ds[elm.key]}
							<i className={ds["icon"]} style={{paddingLeft: '5px', color: '#868e96'}} title={ds["iconTitle"]}></i>
						</TData>;
					} else {
						return <TData key={`td${i}`} hidden={elm["hide"] ? true : false} title={ds[elm.key]} >{ds[elm.key]}</TData>;
					}
				}
			});
		}
	}

	let handleRowClick = (id, thrId, qryId) => {
		if(typeof onRowClick === "function" && id !== "") {
			onRowClick(id, thrId, qryId);
		}
	}

	if(dataSrc.length > 0){
		return(<tbody>
				{slice.map((d, i) => (
					<tr key={i} style={{cursor: 'pointer'}} onClick={() => handleRowClick(d.errand ? d.errand : "", d.threadId ? d.threadId : "", d.queryId ? d.queryId : "")}>{tr(d)}</tr>
				))}
			</tbody>
		);
	}else{
		return <tbody><tr><td colSpan={columns.length}>{I("No items found for this errand.")}</td></tr></tbody>;
	}
}
const THeader = ({columns}) =>{
	return(<thead className="thead-inverse">
			<tr>
				{columns.map((elm, i)=><th key={i} width={elm["width"]} title={elm["title"]} hidden={elm["hide"] ? true : false}>{elm["header"]}</th>)}
			</tr>
	</thead>
	);
};

export const PaginationSize = ({size, sizePerPage, onChangeSizePerPage}) => {
	let page = [];
	$.each(size, (i,v) => {
		let separator = "";
		if(i !== (size.length-1)){
			separator = <span className="page-separator">|</span>;
		}
		page.push(<span key={"page-size-"+v}> <a className={sizePerPage === v ? "active" : ""} onClick={ () => onChangeSizePerPage(v) }>{v}</a>{separator}</span>);
	});
	return <div className="align-right display-option">
				<span className="page-show-label">{I("Show")}</span>{page}
			</div>;
}

const calculateRange = (total, rowsPerPage) => {
	const range = [];
	const num = Math.ceil(total / rowsPerPage);
	let i = 1;
	for (let i = 1; i <= num; i++) {
		range.push(i);
	}
	return range;
};

const sliceData = (data, page, rowsPerPage) => {
	return data.slice((page - 1) * rowsPerPage, page * rowsPerPage);
};

const useTable = (data, page, rowsPerPage) => {
	const [tableRange, setTableRange] = useState([]);
	const [slice, setSlice] = useState([]);

	useEffect(() => {
		const range = calculateRange(data.length, rowsPerPage);
		setTableRange([...range]);

		const slice = sliceData(data, page, rowsPerPage);
		setSlice([...slice]);
	}, [data, setTableRange, page, setSlice]);

	return { slice, range: tableRange };
};

const ArrowNav = styled.a`
	cursor: pointer;
	&:hover {
		i{
			&:before {
				color: #0c87f7;
			}
		}
	}
`;

const TPageNav = ({ previous, hide, onClick }) => {
	return(
		<ArrowNav title={previous ? I("Previous") : I("Next")}
			hidden={hide}
			onClick={onClick}>
			<i className={previous ? "icon-chevron-left" : "icon-chevron-right"} />
		</ArrowNav>
	)
}

const TPageLink = styled.div`
	display: flex;
	font-size: 12px;
	border-radius: 4px;
	padding: 0px 4px 0px 4px;
	cursor: pointer;
	color: ${props => props.active ? "#FFF" : "#6f6f6f"};
	background: ${props => props.active ? centionBlue : "none"};
`

const TPagination = ({ range, page, onClick}) => {
	let pagination = [];
	if(range.length > 8) {
		range.map((el, index) => {
			if(index === 0) {
				//show first page and arrow
				pagination.push(
					<Fragment key={"firstPage"+index}>
						<TPageNav key={"tpagenav"+index} previous={true} hide={page===1} onClick={() => onClick(page-1)} />
						<TPageLink
							key={index}
							onClick={() => onClick(el)}
							active={page === el}
						>
						{el}
						</TPageLink>
					</Fragment>
				)
			} else if ((index === (range.length-1))) {
				pagination.push(
					<Fragment key={"lastPage"+index}>
						<TPageLink
							key={index}
							onClick={() => onClick(el)}
							active={page === el}
						>
						{el}
					</TPageLink> <TPageNav key={"tpagenav"+index} previous={false} hide={page===(range.length)} onClick={() => onClick(page+1)}/>
					</Fragment>
				)
			} else {
				if(page <= 5) {
					//show 5 pages at early
					if (index <= 4) {
						pagination.push(
							<TPageLink
								key={index}
								onClick={() => onClick(el)}
								active={page === el}
								>
								{el}
							</TPageLink>
						)
					} else if (index === 5) {
						pagination.push("...");
					}
				} else if (page > 5 && page <= (range.length - 5)) {
					//show 3 pages at middle, the selected page at the center
					if (index === 2) {
						pagination.push("...");
					} else if (index === (page - 2) || index === (page - 1) || index === (page)) {
						pagination.push(
							<TPageLink
								key={index}
								onClick={() => onClick(el)}
								active={page === el}
								>
								{el}
							</TPageLink>
						)
					} else if (index === (range.length - 2)) {
						pagination.push("...");
					}
				} else {
					//show last 5 pages at the end
					if (page >= (range.length - 5)) {
						if (index === 1) {
							pagination.push("...");
						} else if(index >= (range.length - 5)) {
							pagination.push(
								<TPageLink
									key={index}
									onClick={() => onClick(el)}
									active={page === el}
									>
									{el}
								</TPageLink>
							)
						}
					}
				}
			}
		})
	} else {
		//return normal pagination
		range.map((el, index) => (
			pagination.push(
				<TPageLink
				key={index}
				onClick={() => onClick(el)}
				active={page === el}
				>
				{el}
			</TPageLink>
			)
		))
	}
	return <Fragment>{pagination}</Fragment>
}

const TFooter = ({ range, setPage, page, slice, total }) => {
	useEffect(() => {
		if (slice.length < 1 && page !== 1) {
			setPage(page - 1);
		}
	}, [slice, page, setPage]);
	let pageCount = slice.length, pageDisplay = "flex", pageWidth = "unset";
	return (
		<div style={{margin: '10px'}}>
			<div style={{float: 'left', fontSize: '12px'}}>{I("Showing {CURRENT} of {TOTAL}")
					.replace('{CURRENT}', pageCount).replace('{TOTAL}', total)}</div>
			<div style={{display: pageDisplay, width: pageWidth, maxWidth: '100%', gap: '8px', float: 'right'}}>
				<TPagination range={range} page={page} onClick={setPage} />
			</div>
		</div>
	);
};

export default SimpleTable;
