import 'jquery';
import {
	SocketSlash,
	AgentAPISlash,
	AgentCallAPISlash
} from '../../constants/endpoints';
import RESTful from './restful';

const CHAT_AGENT_API = SocketSlash + AgentAPISlash,
	CALL_AGENT_API =  SocketSlash + AgentCallAPISlash;

function SocketAPI(endpoint, query) {
	if(!(this instanceof SocketAPI)) {
		endpoint = SocketSlash + endpoint.replace(/^\/+|\/+$/g, '');
		return new SocketAPI(endpoint, query);
	}
	this.endpoint = endpoint;
	this.q = query;
}

SocketAPI.prototype = RESTful.prototype;

export default SocketAPI;

export function ChatAgentAPI(endpoint, query) {
	if(!(this instanceof ChatAgentAPI)) {
		endpoint = CHAT_AGENT_API + endpoint.replace(/^\/+|\/+$/g, '');
		return new ChatAgentAPI(endpoint, query);
	}
	this.endpoint = endpoint;
	this.q = query;
}

ChatAgentAPI.prototype = RESTful.prototype;

export function CallAgentAPI(endpoint, query) {
	if(!(this instanceof CallAgentAPI)) {
		endpoint = CALL_AGENT_API + endpoint.replace(/^\/+|\/+$/g, '');
		return new CallAgentAPI(endpoint, query);
	}
	this.endpoint = endpoint;
	this.q = query;
}

CallAgentAPI.prototype = RESTful.prototype;
