import { UNSELECT } from '../../common/v5/constants';
import { TM_AGENT_ID, TM_PHONE_NUMBER } from '../../common/v5/callConstants';
import {
	CALL_AGENTS_CHANGES
	, CALL_CHANGE_AGENT_SELECTION
	, CALL_CHANGE_FORWARDEE_INDEX
	, CALL_PHONE_AREA
	, CALL_RESET_TRANSFER
	, CALL_RESET_TWILIO_STATUS
	, CALL_SHOW_HIDE_POPUP
	, CALL_UPDATE_ACCEPT
	, CALL_UPDATE_AUDIO_INPUT
	, CALL_UPDATE_BUSY
	, CALL_UPDATE_C3_CONNECTED_PHONE
	, CALL_UPDATE_INBOUND_CALL_SID
	, CALL_UPDATE_INBOUND_ERRAND
	, CALL_UPDATE_INBOUND_ERRAND_ID
	, CALL_UPDATE_ME
	, CALL_UPDATE_MY_CALL
	, CALL_UPDATE_MY_EXIST
	, CALL_UPDATE_OUTBOUND_CALL_SID
	, CALL_UPDATE_OUTBOUND_DIAL_BACK
	, CALL_UPDATE_OUTBOUND_ERRAND_ID
	, CALL_UPDATE_OUTBOUND_PHONE_ID
	, CALL_UPDATE_OTHERS
	, CALL_UPDATE_PHONE
	, CALL_UPDATE_READY
	, CALL_UPDATE_REPLACING
	, CALL_UPDATE_TRANSFER
	, CALL_UPDATE_TRANSFER_METHOD
	, CALL_UPDATE_TRANSFER_STATE
	, CALL_UPDATE_TWILIO_CALL_TYPE
	, CALL_UPDATE_TWILIO_STATUS
	, CALL_UPDATE_TWILIO_TOKEN
	, CALL_UPDATE_AVENTA_STATUS
	, CALL_UPDATE_SIP_TRANSFER_STATUS
	, CALL_SIP_INCOMING_ONGOING
	, SIP_UPDATE_AGENT_LIST
	, SIP_UPDATE_RECORDING_STATE
	, SIP_UPDATE_XFER_REF
	, SIP_MAKE_CALL_FROM_ERRAND
	, SIP_UPDATE_TRANSFER_DATA
} from '../constants/constants';
import { callActionMap, phonesActionMap } from '../reducers/call';

export const toggleCallPadPopup = show => ({
	type: CALL_SHOW_HIDE_POPUP
	, payload: show
});

export const closeCallPadPopup = () => toggleCallPadPopup(false);

export const changeTransfereeAgent = selection => ({
	type: CALL_CHANGE_AGENT_SELECTION
	, payload: selection
});

export const updateAgentChanges = payload => ({
	type: CALL_AGENTS_CHANGES
	, payload
});

export const {
	[CALL_PHONE_AREA]: phoneArea
} = phonesActionMap;

export const {
	[CALL_RESET_TRANSFER]: resetTransfer
	, [CALL_UPDATE_ACCEPT]: updateAccept
	, [CALL_UPDATE_AUDIO_INPUT]: updateAudioInput
	, [CALL_UPDATE_BUSY]: updateBusy
	, [CALL_UPDATE_C3_CONNECTED_PHONE]: updateC3ConnectedPhone
	, [CALL_UPDATE_INBOUND_CALL_SID]: updateInboundCallSid
	, [CALL_UPDATE_INBOUND_ERRAND_ID]: updateInboundErrandId
	, [CALL_UPDATE_ME]: updateMe
	, [CALL_UPDATE_MY_CALL]: updateMyCall
	, [CALL_UPDATE_MY_EXIST]: updateMyExist
	, [CALL_UPDATE_OUTBOUND_CALL_SID]: updateOutboundCallSid
	, [CALL_UPDATE_OUTBOUND_DIAL_BACK]: updateOutboundDialBack
	, [CALL_UPDATE_OUTBOUND_ERRAND_ID]: updateOutboundErrandId
	, [CALL_UPDATE_OUTBOUND_PHONE_ID]: updateOutboundPhoneId
	, [CALL_UPDATE_OTHERS]: updateOthers
	, [CALL_UPDATE_READY]: updateReady
	, [CALL_UPDATE_REPLACING]: updateReplacing
	, [CALL_UPDATE_TRANSFER]: updateTransfer
	, [CALL_UPDATE_TRANSFER_METHOD]: updateTransferMethod
	, [CALL_UPDATE_TRANSFER_STATE]: updateTransferState
	, [CALL_UPDATE_TWILIO_CALL_TYPE]: updateTwilioCallType
	, [CALL_RESET_TWILIO_STATUS]: resetTwilioStatus
	, [CALL_UPDATE_TWILIO_STATUS]: updateTwilioStatus
	, [CALL_UPDATE_TWILIO_TOKEN]: updateTwilioToken
	, [CALL_UPDATE_AVENTA_STATUS]: updateAventaStatus
	, [CALL_UPDATE_SIP_TRANSFER_STATUS]: updateSipTransferStatus
	, [CALL_SIP_INCOMING_ONGOING]: updateSipIncomingOngoing
	, [SIP_UPDATE_RECORDING_STATE]: updateSipRecordingState
	, [SIP_UPDATE_XFER_REF]: updateSipXferRef
	, [SIP_UPDATE_AGENT_LIST]: updateSipAgentList
	, [SIP_UPDATE_TRANSFER_DATA]: updateSipTransferData
} = callActionMap;

const changeForwardeeIndex = callActionMap[CALL_CHANGE_FORWARDEE_INDEX];

export const updatePhone = (number, mailOrigin) => {
	const param = {number};
	if (typeof mailOrigin === "number") {
		param.mailOrigin = mailOrigin;
	}
	return callActionMap[CALL_UPDATE_PHONE](param);
};

export const updateInboundErrand = (errandId, targetAreaId) => {
	let param;
	if (typeof errandId === "number") {
		param = {errand: errandId}
	}
	if (typeof targetAreaId === "number") {
		if (param) {
			param.area = targetAreaId;
		} else {
			param = {area: targetAreaId}
		}
	}
	return callActionMap[CALL_UPDATE_INBOUND_ERRAND](param);
};

export const clearOutboundCallSid = () => updateOutboundCallSid("");

export const clearTwilioToken = () => updateTwilioToken("");

export const resetOutboundErrandId = () => updateOutboundErrandId(0);

export const resetOutboundPhoneId = () => updateOutboundPhoneId(UNSELECT);

export const resetInboundErrand = () => updateInboundErrand(0, 0);

export const resetInboundErrandAreaId = () => updateInboundErrand(null, 0);

export const resetPhone = () => dispatch => {
	dispatch(updateC3ConnectedPhone(""));
	dispatch(updatePhone("", 0));
};

export const changeTransferee = (method, transferee) => dispatch => {
	if (method === TM_PHONE_NUMBER) {
		dispatch(changeForwardeeIndex(transferee));
		dispatch(updateTransferMethod(TM_PHONE_NUMBER));
		dispatch(changeTransfereeAgent(UNSELECT));
	} else {
		dispatch(changeTransfereeAgent(transferee));
		dispatch(updateTransferMethod(TM_AGENT_ID));
		dispatch(changeForwardeeIndex(UNSELECT));
	}
}

export const sipMakeCallFromErrand = (toggle, eid) => ({
	type: SIP_MAKE_CALL_FROM_ERRAND
	, payload: {sipCallFromErrand: toggle, errandId: eid}
});

export const sipSetCallTransferData = (transferData) => ({
	type: SIP_UPDATE_TRANSFER_DATA
	, payload: {sipTransferData: transferData}
});
