import 'jquery';
import pathToRegexp from 'path-to-regexp';
import {
	CentionSlash,
	CentionWeb,
	CentionFile,
	FilePrefix
} from '../../constants/endpoints';
import { end } from '@popperjs/core';

const WEB_HOST = window.location.protocol + "//" + window.location.hostname
	, WEB_ROOT = WEB_HOST + CentionWeb
	;
const FORM_TYPE = 0
	, JSON_TYPE = 1
	, JSONAPI_TYPE = 2
	, ENCTYPE_MULTIPART = 3
	;

export function CheckEndpoint(endpoint) {
	if (endpoint.indexOf(FilePrefix) === 0) {
		endpoint = endpoint.replace(FilePrefix, '')
		if (cflag.IsActive("2024-04-01.CEN-1479.new.service.serve.file.attachment") ) {
			endpoint = WEB_HOST+ '/s/'+space+ CentionFile + endpoint.replace(/^\/+|\/+$/g, '');
		} else {
			endpoint = WEB_ROOT + endpoint.replace(/^\/+|\/+$/g, '');	
		}
	} else {
		endpoint = WEB_ROOT + endpoint.replace(/^\/+|\/+$/g, '');
	}
	return endpoint;
}

function RESTful(endpoint, query, urlCreator) {
	if (!(this instanceof RESTful)) {
		endpoint = CheckEndpoint(endpoint);
		return new RESTful(endpoint, query, urlCreator);
	}
	this.endpoint = endpoint;
	this.q = query;
	this.urlCreator = urlCreator;
}

RESTful.prototype.createIdEndpoint = function(id) {
	const { endpoint } = this;
	if (typeof this.urlCreator === "function") {
		return this.urlCreator(endpoint, id);
	}
	let url = endpoint;
	if(typeof id !== 'undefined') {
		url += "/" + id
	}
	return url;
};

RESTful.prototype.get = function(id) {
	let d = {
		url: this.createIdEndpoint(id)
		, data: this.q
		, method: 'GET'
	}
	if (this.q && this.q.autoRefresh) {
		d.headers = {"X-Cention-Robot-Polling": true};
	}
	return $.ajax(d);
};

RESTful.prototype.postBase = function(id, type) {
	let d = {
		url: this.createIdEndpoint(id)
		, method: 'POST'
	};
	if (this.q && this.q.autoRefresh) {
		d.headers = {"X-Cention-Robot-Polling": true};
	}
	if(type === JSON_TYPE) {
		d.data = JSON.stringify(this.q);
		d.contentType = 'application/json';
		d.dataType = 'json';
	}else if(type === ENCTYPE_MULTIPART){
		d.cache = false;
		d.contentType = false;
		// Telling jQuery not to process data or worry about content-type
		d.processData = false;
		d.data = this.q;
	}
	else {
		d.data = this.q;
	}
	return $.ajax(d);
};

RESTful.prototype.postMultipart = function(id){
	return this.postBase(id, ENCTYPE_MULTIPART);
}

RESTful.prototype.post = function(id) {
	return this.postBase(id);
};

RESTful.prototype.postJSON = function(id) {
	return this.postBase(id, JSON_TYPE);
};

RESTful.prototype.put = function(id) {
	return $.ajax({
		url: this.createIdEndpoint(id)
		, data: this.q
		, method: 'PUT'
	});
};

RESTful.prototype.patch = function(id) {
	return $.ajax({
		url: this.createIdEndpoint(id)
		, data: this.q
		, method: 'PATCH'
	});
};

RESTful.prototype.delete = function(id) {
	let url = this.createIdEndpoint(id);
	// force DELETE carry data as URL to allow Golang backend server to parse
	// DELETE method RESTful because Golang net/http library never parse data
	// that carry inside request body if the HTTP method is 'DELETE'.
	if(typeof this.q !== 'undefined') {
		url += '?' + $.param(this.q);
	}
	return $.ajax({
		url
		, method: 'DELETE'
	});
};

RESTful.prototype.download = function(id) {
	window.location = this.createIdEndpoint(id);
};

export default RESTful;

export function CentionREST(endpoint, query) {
	if(!(this instanceof RESTful)) {
		endpoint = CentionSlash + endpoint.replace(/^\/+|\/+$/g, '');
		return new CentionREST(endpoint, query);
	}
	this.endpoint = endpoint;
	this.q = query;
}

CentionREST.prototype = RESTful.prototype;

const pathToRegexpUrl = (endpoint, id) => pathToRegexp.compile(endpoint)(id)

// Dynamic URL REST.
export function DurlREST(endpoint) {
	const r = p => RESTful(endpoint, p, pathToRegexpUrl)
	return {
		get: (id, p) => () => r(p).get(id),
		post: (id, p) => () => r(p).postJSON(id),
		delete: (id, p) => () => r(p).delete(id),
		url: id => r().createIdEndpoint(id)
	};
}
