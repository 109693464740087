import React from 'react';
import classNames from 'classnames';
import { withDisableableOnClick } from './hocs';
import { L } from '../common/v5/config';

export class AnchorBase extends React.PureComponent {
	constructor (props) {
		super(props);
		this.handleClick = this.handleClick.bind(this);
	}
	handleClick (e) {
		e.preventDefault()
		if (typeof this.props.onClick === "function" && !this.props.readOnly) {
			this.props.onClick(e);
		}
	}
	render() {
		const {
				children
				, className
				, onClick
				, readOnly
				, style
				, ...props
			} = this.props
			;
		let Tag
			, href
			;
		if (readOnly) {
			Tag = "span";
		} else {
			Tag = "a";
			href = "#";
		}
		return (
			<Tag
				{...props}
				href={href}
				className={classNames(className)}
				onClick={this.handleClick}
				style={style}
			>
				{L(children)}
			</Tag>
		);
	}
}

const Anchor = withDisableableOnClick(AnchorBase);

export default Anchor;
