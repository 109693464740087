import * as constant from '../../redux/constants/constants';

import {
	ME_ST_IDLE,
	RPLY_MANUAL_IM
} from '../../common/v5/constants'

import {
	closeMessageView
} from './async/internalMessages';

import {
	getTotalSelectedMessages
} from '../selectors/imrds';

export const internalMessageFetching = tgl =>({
	type: constant.INTERNAL_MESSAGES_ISFETCHING,
	payload: tgl
});

// export const fetchInternalMessagesDone = dSrc => ({
// 	type: constant.FETCH_INTERNAL_MESSAGES_DONE,
// 	payload: dSrc
// });

export const filterMessageList = by =>({
	type: constant.FILTER_MESSAGE_LIST_BY,
	payload: by
})

export const selectFolder = id =>({
	type: constant.FILTER_FOLDER,
	payload: id
})

export const clearIMInputs = () => ({
	type: constant.CLEAR_IM_INPUTS
});

export const IMState = (state, id) => ({
	type: constant.IM_BUSY,
	payload: {state, id}
});

export const inputTextChanges = (which, value, plainOrReply, reply) => {
	let plain;
	if(which !== 'update_answer') {
		reply = plainOrReply;
	} else {
		if(!value) {
			plain = '';
			reply = plainOrReply;
		} else {
			plain = plainOrReply;
		}
	}
	return {
		type: constant.INPUT_IM_TEXT_CHANGES,
		payload: {which, value, plain, reply}
	};
};

export const toggleIMPopup = (value, edit, did) => (dispatch, getState) => {
	//if is edit, get folder id from state, if folder is 4. then assign all value to Manulinput state and togglepopout new message window
	const im = getState().app.internalMessage;
	const wf = getState().app.workflow;
	if (edit) {
		const folderID = im.ui.filter.selectedFolder;
		if (folderID == 4) {  //if other folder than 4, do nothing
			const imIDSelected = im.currentMessage.id;
			// need to add state for agent & agent groups
			const agentList = wf.agents.data.agents;
			const groupList = wf.agentGroups.data.groups;
			const msgListData = im.messageList.data;
			dispatch(clearIMInputs())
			dispatch(isNewIM(!edit, did))
			if ((msgListData != null) && (imIDSelected!=0)) {
				const msgBody = msgListData.norm[imIDSelected].body;
				const msghtmlBody = msgListData.norm[imIDSelected].htmlBody;
				const subject = msgListData.norm[imIDSelected].subject;
				const targets = msgListData.norm[imIDSelected].targets;
				const groups = msgListData.norm[imIDSelected].groups;
				let agentName = "", agentId = 0;
				let groupName = "", groupId = 0;

				//get each agent id from targets state
				$.each(targets, function(k,v) {
					agentId = v.id;
					//need to get agent name by id
					agentName = "";
					$.each(agentList, (i,v) => {
						if (v.Id == agentId) {
							agentName = v.Name;
							return false;
						}
					});
					if(agentName != ""){
						dispatch(appendToAgent(agentId, agentName))
					}
				});

				//get each group id from groups state
				$.each(groups, function(k,v) {
					groupId = v.id;
					//need to get group name by id
					groupName = "";
					$.each(groupList, (i,v) => {
						if (v.Id == groupId) {
							groupName = v.Name;
							return false;
						}
					});
					if(groupName != ""){
						dispatch(appendToGroup(groupId, groupName))
					}
				});

				dispatch(inputTextChanges('update_answer', msghtmlBody, msgBody, RPLY_MANUAL_IM));
				dispatch(inputTextChanges('update_subject', subject, RPLY_MANUAL_IM));

				dispatch({type: constant.TOGGLE_IM_POPUP, payload: value});
				dispatch(isNewThread(value))
			}
		}
	} else {
	//else pop new message with clear fields if was edit msg previously
		const imID = im.ui.isNewIM.imID;
		if (imID != 0) {
			dispatch(clearIMInputs());
			dispatch(isNewIM(true, 0));
		}
		dispatch({type: constant.TOGGLE_IM_POPUP, payload: value});
		dispatch(isNewThread(value))
	}
	dispatch(IMState(ME_ST_IDLE));
};

export const appendToAgent = (agentId, agentName) => ({
	type: constant.IM_APPEND_TO_AGENT,
	payload: {agentId, agentName}
});

export const appendToGroup = (groupId, groupName) => ({
	type: constant.IM_APPEND_TO_GROUP,
	payload: {groupId, groupName}
});

export const showAgentBook = (tgl) => ({
	type: constant.SHOW_AGENT_BOOK,
	payload: tgl
});

export const showGroupBook = tgl => ({
	type: constant.SHOW_GROUP_BOOK,
	payload: tgl
});

export const setIMReplyAddressType = (replyType, context, AgentOrGroup) => ({
	//agentBook/groupBook
	type: constant.IM_SET_REPLY_ADDRESS_TYPE,
	payload: {replyType, context, AgentOrGroup}
});

export const updateAgentCard = (field, obj) => ({
	type: constant.UPDATE_AGENT_CARD,
	payload: {field, obj}
});

export const updateGroupCard = (field, obj) => ({
	type: constant.UPDATE_GROUP_CARD,
	payload: {field, obj}
});

export const updateAgentBook = (field, obj) => ({
	type: constant.UPDATE_AGENT_BOOK,
	payload: {field, obj}
});

export const updateGroupBook = (field, obj) => ({
	type: constant.UPDATE_GROUP_BOOK,
	payload: {field, obj}
});

export const showAgentCard = (tgl, obj) => ({
	type: constant.SHOW_AGENT_CARD,
	payload: tgl
});

export const appendReplyAgentAddress = () => ({
	type: constant.IM_APPEND_AGENT_REPLY_ADDRESS,
	payload: {}
});

export const appendReplyGroupAddress = () => ({
	type: constant.IM_APPEND_GROUP_REPLY_ADDRESS,
	payload: {}
});

export const IMCounter = (count, colour) => ({
	type: constant.IM_COUNTER,
	payload: {count, colour}
});

export const IMSentCounter = (count, colour) => ({
	type: constant.IM_SENT_COUNTER,
	payload: {count, colour}
});

export const IMDraftCounter = (count, colour) => ({
	type: constant.IM_DRAFT_COUNTER,
	payload: {count, colour}
});

export const IMTrashCounter = (count, colour) => ({
	type: constant.IM_TRASH_COUNTER,
	payload: {count, colour}
});

export const NewIMCounter = (count, colour) => ({
	type: constant.NEW_IM_COUNTER,
	payload: {count, colour}
});

export const setCurrentIM = (id) => (dispatch, getState) => {
	dispatch(doSetCurrentIM(id));
};

export const doSetCurrentIM = (id) => ({
	type: constant.SET_CURRENT_IM,
	payload: {id}
});

export const updateCurrentInternalMessage = (msg) => ({
	type: constant.UPDATE_CURRENT_IM,
	payload: {data: msg}
});

export const changeMessageListSelection = (id, select) => ({
	type: constant.SELECT_MESSAGE_IN_MESSAGE_LIST,
	payload: {id, select}
});

export const selectMessageFromList = (id, select) => (dispatch, getState) => {
	const app = getState().app, currentID = app.internalMessage.currentMessage.id,
		totalSelected = getTotalSelectedMessages(app.internalMessage.messageList.data);
	return new Promise((resolve, reject) => {
		if(id
			// unselect the last selected message
			&& ((!select && (totalSelected === 1 || currentID === id))
				// select more than 1 message
				|| (select && totalSelected > 0))) {
					resolve(dispatch(closeMessageView()));
		} else {
			resolve();
		}
	})
	.then(() => dispatch(changeMessageListSelection(id, select)))
	.catch(() => {/* rejected close view*/});
};

export const selectAllMessages = selectAll => ({
	type: constant.SELECT_ALL_MESSAGES_IN_MESSAGE_LIST,
	payload: selectAll
});

export const setIMSortParams = (params) => ({
	type: constant.SORT_PARAM,
	payload: params
});

export const IMRecipientsChange = (value, which, reply, agents) => ({
	type: constant.IM_RECIPIENTS_CHANGE,
	payload: {value, which, reply, agents}
});

export const IMGroupsChange = (value, which, reply, groups) => ({
	type: constant.IM_GROUPS_CHANGE,
	payload: {value, which, reply, groups}
});

export const selectOrToggleSort = (sortOrToggle, index) => ({
	type: constant.SELECT_TOGGLE_SORT_MESSAGE_LIST,
	payload: {value: sortOrToggle, index}
});

export const toggleShowIMReply = tgl => ({
	type: constant.TOGGLE_SHOW_IM_REPLY,
	payload: tgl
});

export const updateMessageListRead = id => ({
	type: constant.UPDATE_MESSAGE_READ,
	payload: id
});

export const isNewIM = (newIM, imID) => ({
	type: constant.IS_NEW_IM,
	payload: {newIM, imID}
});

export const isNewThread = (newThread) => ({
	type: constant.IS_NEW_THREAD,
	payload: newThread
});

export const changeAgentListSelection = (id, select) => ({
	type: constant.SELECT_AGENT_IN_AGENT_LIST,
	payload: {id, select}
});

export const setSelectAgentList = (agents) => ({
	type: constant.IM_SET_AGENT_LIST,
	payload: {agents}
});

export const updateSelectedAgent = (value, agents) => ({
	type: constant.IM_UPDATE_SELECTED_AGENT,
	payload: {value, agents}
});

export const changeGroupListSelection = (id, select) => ({
	type: constant.SELECT_GROUP_IN_GROUP_LIST,
	payload: {id, select}
});

export const setSelectGroupList = (groups) => ({
	type: constant.IM_SET_GROUP_LIST,
	payload: {groups}
});

export const updateSelectedGroup = (value, groups) => ({
	type: constant.IM_UPDATE_SELECTED_GROUP,
	payload: {value, groups}
});

export const isIMLoad = tgl => ({
	type: constant.IS_IM_LOAD,
	payload: tgl
});


