import {
	postAcceptInternalChat,
	getFavouriteChatAgent,
	postFavouriteChatAgent,
	postChatAgentAPIAgentlist,
	// todo
	getChatAgentAPIAnswerinvite,
	// getChatAgentAPIForward,
	// getChatAgentAPIGetAreas,
	// getChatAgentAPIGetAgents,
	getChatAgentAPICanClose,
	getChatAgentAPIQueues,
	postChatAgentAPISessiondata,
	getClosedChatErrand,
	getChatAgentAPIStatus
} from './ajax';
import {
	keyChangeAcceptInternalChat,
	keyChangeFavouriteChatAgent,
	keyAllChatAgent,
	keyFavouriteChatAgent,
	// todo
	// keyAgentAPIAgentlist,
	keyAgentAPIAnswerinvite,
	// keyAgentAPIForward,
	// keyAgentAPIGetAreas,
	// keyAgentAPIGetAgents,
	keyAgentAPICanClose,
	keyAgentAPIQueues,
	keyAgentAPISessiondata,
	keyGetClosedChatErrand,
	keyGetAgentAPIChatStatus
} from '../../constants/keys';
import {
	CHAT_ON_FINISH_SESSION,
	CHAT_ON_SESSION_INIT
} from '../../constants/constants';
import { togglePopAlert } from '../hmf';
import { optionalConfirm } from './hmf';
import {
	closeErrands
} from './workflow';
import {
	setPostMessageChat
} from '../errand';
import {
	async,
	getChatSocket,
	getAppState,
	getStateName,
	mapKeyToActionsAndStateName
} from '../../util';
import {
	DummyFunction
	, getExtQueueType
	, isTelavox
} from '../../../common/v5/helpers.js';
import {
	CLOSE_BROWSER_MANUALLY
	, SOLIDUS
} from '../../../common/v5/constants.js';

export const chat = mapKeyToActionsAndStateName([
	[keyChangeAcceptInternalChat], "acceptChat",
	[keyFavouriteChatAgent, "favouriteChatAgent"],
	[keyChangeFavouriteChatAgent, "changeFavouriteChatAgent"],
	[keyAllChatAgent, "allChatAgent"],
	// todo
	// [keyAgentAPIAgentlist, 'agentList'],
	[keyAgentAPIAnswerinvite, 'answerInvite'],
	// [keyAgentAPIForward, 'forward'],
	// [keyAgentAPIGetAreas, 'getAreas'],
	// [keyAgentAPIGetAgents, 'getAgents'],
	[keyAgentAPICanClose, 'canClose'],
	[keyAgentAPIQueues, 'queues'],
	[keyAgentAPISessiondata, 'sessionData'],
	[keyGetClosedChatErrand, 'closedChatErrand'],
	[keyGetAgentAPIChatStatus, 'chatStatus']
]);

//
// AJAX
//
export const getQueueChat = areas => async(
	getChatAgentAPIQueues({areas})
	, chat[keyAgentAPIQueues]
);

export const getFullChatStatus = () => async(
	getChatAgentAPIStatus()
	, chat[keyGetAgentAPIChatStatus]
);

export const closedChatErrand = errandId => async(
	getClosedChatErrand({errandId})
	, chat[keyGetClosedChatErrand]
);

export const acceptInternalChat = p => async(postAcceptInternalChat(p), chat[keyChangeAcceptInternalChat]);

const favouriteChatAgent = p => async(getFavouriteChatAgent(p), chat[keyFavouriteChatAgent]);
const allChatAgent = p => async(postChatAgentAPIAgentlist(p), chat[keyAllChatAgent]);

export const fetchChatAgentList = o => (dispatch, getState) => {
	return dispatch(allChatAgent(o))
		.then(result => {
			if(!result.error) {
				dispatch(favouriteChatAgent());
			}
		});
};

export const setFavouriteChatAgent = p => async(postFavouriteChatAgent(p), chat[keyChangeFavouriteChatAgent]);
// todo
// export const agentList = p => async(postChatAgentAPIAgentlist(p), chat[keyAgentAPIAgentlist]);
const answerinvite = p => async(getChatAgentAPIAnswerinvite(p), chat[keyAgentAPIAnswerinvite]);

export const updateAnswerInvite = o => (dispatch, getState) => {
	return dispatch(answerinvite(o))
	.then(result => {
		if (result.error) {
			dispatch(togglePopAlert("Accept invitation error: " + result.error));
			return;
		}
		if (result.isStale) {
			dispatch(onFinishSession(result));
		}
	})
	.catch(err => {
		dispatch(togglePopAlert(I("An error occurred while answering the invitation, please try again")));
	});
}
// export const forward = p => async(getChatAgentAPIForward(p), chat[keyAgentAPIForward]);
// export const getAreas = p => async(getChatAgentAPIGetAreas(p), chat[keyAgentAPIGetAreas]);
// export const getAgents = p => async(getChatAgentAPIGetAgents(), chat[keyAgentAPIGetAgents]);
export const canClose = p => async(getChatAgentAPICanClose(p), chat[keyAgentAPICanClose]);

export const chatCheckCanClose = o => (dispatch, getState) => {
	clearTimeout(chatCheckCanClose);
	return dispatch(canClose(o))
	.then(result =>{
		if (result.canClose == true){
			const qType = getExtQueueType();
			if (qType != SOLIDUS && isTelavox() == false) {
				try {
					window.close();
				} catch (e) {
					console.log("dbg: unable to close browser window: ", e);
				}
				window.location.href = "about:blank";
			} else if(isTelavox() == true){
				externalqueue.isChat = false;
				dispatch(setPostMessageChat(false));
				return;
			} else {
				window.location.href = "about:blank";
			}
		} else {
			setTimeout(function(){
				dispatch(chatCheckCanClose(o));
			}.bind(this), 2000);
		}
	});
}
//
// SOCKET
//
export const onFinishSession = payload => (dispatch, getState) => {
	const {sessionId} = payload, state = getChatSocket(getState()),
		chatSession = state.chatSessions[sessionId];
	if(!chatSession) {
		return Promise.resolve();
	}
	const {error} = payload;
	if(error) {
		// CHAT-05 - error alert popup
		dispatch(togglePopAlert(I("There was an error closing the chat: {ERROR}")
			.replace('{ERROR}', error)));
		return Promise.resolve();
	}
	dispatch({type: CHAT_ON_FINISH_SESSION, payload});
	const {isOwner} = payload;
	if(isOwner) {
		// CHAT-06 - close errands
		let tags;
		if(chatSession.tags) {
			tags = chatSession.tags;
		} else {
			tags = [];
		}
		tags = tags.filter(item => item.selected == true).map(item => item.id);
		if(chatSession.associatedErrands &&
			chatSession.associatedErrands.length > 0) {
			return dispatch(closeErrands({
				list: chatSession.associatedErrands.join(','),
				update_tags: tags.join(',')
			}));
		}
	}
	return Promise.resolve();
};

const sessiondata = p => async(postChatAgentAPISessiondata(p.args),	chat[keyAgentAPISessiondata]);

export const onSessionInit = o => (dispatch, getState) => {
	return dispatch(sessiondata(o))
		.then(result => {
			if(result.error) {
				o.onFail && o.onFail(result.error);
			} else {
				dispatch({type: CHAT_ON_SESSION_INIT, payload: result});
				o.onDone && o.onDone(result);
			}
		})
		.catch(err => {
			o.onFail && o.onFail(err);
		});
};

export const confirmExternalLink = href => dispatch => dispatch(optionalConfirm(I("Do you want to follow the link {LINK}?").replace("{LINK}", href)))
	.then(() => window.open(href, '_blank'))
	.catch(DummyFunction);
