import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import styled from 'styled-components';
import { branch } from 'recompose';
import { I } from '../common/v5/config';
import {
	ET_EXCEL,
	EXPORT_TYPES,
	ICON_CONVERSATION_VIEW,
	ET_PDF
} from '../common/v5/constants';
import { getChevronIcon } from './common';
import {
	removeProps,
	withDisableableOnClick,
	withDisabledClassNameWhenDisabled,
	withToggle
} from './hocs';
import {
	ActionDropdown as NormalActionDropdown,
	newDropdownUI
} from './Dropdown';
import onClickOutside from "react-onclickoutside";

const baseClassName = "c3-header-actions";

const HeaderActionItemTooltip = ({
	children
	, className
	, hide
	, innerRef
	, style
}) => {
	if (hide) {
		return null;
	}
	return (
		<div
			ref={innerRef}
			className={classNames("tooltip-content", className)}
			style={style}
		>
			{children}
		</div>
	);
};

const SmallTooltip = ({ className, ...props }) => (
	<HeaderActionItemTooltip {...props}
		className={classNames("small text-center", className)}
	/>
);

export const StandardTooltip = ({ hide, overflow, text, fontSize }) => {
	let className;
	let style = {};
	if (overflow) {
		className = "adjust-overflow";
		style = {left: "calc(50% - " + overflow + "px)"}
	}
	if(typeof fontSize !== 'undefined' && fontSize != ""){
		style.fontSize=fontSize;
	}
	return (
		<SmallTooltip
			className={className}
			hide={hide}
			style={style}
		>
			<div className="content">{text}</div>
		</SmallTooltip>
	);
};

const RawHeaderActionItemIcons = ({ children, tag, ...props }) => {
	let Tag;
	if (tag) {
		Tag = tag;
	} else {
		Tag = "div";
	}
	return <Tag {...props}>{children}</Tag>
};

export const HeaderActionItemIcons = ({ className, ...props }) => (
	<RawHeaderActionItemIcons {...props}
		className={classNames("icons-content", className)}
	/>
);

const Li = branch(
	() => newDropdownUI,
	removeProps(['data-dropdown-menu'])
)('li')

const OneActionBase = ({hide, children, attrTooltip, attrMenu, ...props}) => {
	if (hide) {
		return null;
	}
	return (
		<Li {...props} data-tooltip={attrTooltip} data-dropdown-menu={attrMenu}>
			{children}
		</Li>
	);
};

const OneHeaderAction = withDisabledClassNameWhenDisabled(OneActionBase);

export const HeaderActionItem = props => (
	<OneHeaderAction {...props} attrTooltip={true} attrMenu={true} />
);

const HeaderActionSelect = props => (
	<OneHeaderAction {...props} attrMenu={true} />
);

export const HeaderActionSelectItem = ({text, textClass, icon, ...props}) => {
	return <HeaderActionSelect {...props}>
			<i className={icon} aria-hidden="true" title={text} />
			<span className={textClass}>{text}</span>
		</HeaderActionSelect>;
};

const StyledUL = styled.ul`
	&.c3-header-actions {
		> li {
			.dropdown-menu {
				margin-top: 0px;
			}
		}
	}
`
// TODO: useless
const StyledOldUL = styled.ul`
	&.c3-header-actions {
		> li {
			.dropdown-menu {
				&::before {
					content: " ";
					border-bottom: 10px solid white;
					border-left: 10px solid transparent;
					border-right: 10px solid transparent;
					height: 0;
					width: 0;
					position: absolute;
					left: calc(50% - 10px);
					z-index: 10;
					top: -10px;
					display: block;
				}
			}
		}
	}
`
const UL = newDropdownUI ? StyledUL : 'ul'

export class HeaderActions extends React.Component {
	handleClickOutside = evt => {
		this.props.onClickOutside();
	}
	render() {
		const {
			onClickOutside
			, hide
			, className
			, children
			, ...props
		} = this.props;

		if (hide) {
			return null;
		}
		return (
			<UL className={classNames(baseClassName, className)}>
				{children}
			</UL>
		);
	}
};

export const HideableHeaderActions = onClickOutside(HeaderActions);

const OneIcon = ({ icon, ...props }) => (
	<HeaderActionItemIcons {...props}>
		<i className={icon} />
	</HeaderActionItemIcons>
);

export const ScheduleAction = ({onClick, id, ...props}) => (
	<HeaderActionItem {...props} className="grey dropdown">
		<OneIcon onClick={onClick} data-qa-id={"header-action-"+id} title={I("Schedule report")}
			icon="icon-report-scheduled" />
		<StandardTooltip text={I("Schedule")} />
	</HeaderActionItem>
);

export const CreateNewAction = ({onClick, id, ...props}) => (
	<HeaderActionItem {...props} className="yellow dropdown">
		<OneIcon onClick={onClick} data-qa-id={"header-action-"+id} title={I("Create report")}
			icon="icon-folder-new" />
		<StandardTooltip text={I("Create report")} />
	</HeaderActionItem>
);

export const CreateNewTimeAction = ({onClick, id, ...props}) => (
	<HeaderActionItem {...props} className="grey dropdown">
		<OneIcon onClick={onClick} data-qa-id={"header-action-"+id} title={I("Create")}
			icon="icon-calendar-plus" />
		<StandardTooltip text={I("Create")} />
	</HeaderActionItem>
);

export const ShareAction = ({onClick, id, ...props}) => (
	<HeaderActionItem {...props} className="grey dropdown">
		<OneIcon onClick={onClick} data-qa-id={"header-action-"+id} title={I("Share report")}
			icon="icon-share" />
		<StandardTooltip text={I("Share")} />
	</HeaderActionItem>
);

const OneIconAction = ({
	onClick
	, icon
	, iconWrapper
	, iconWrapperClassName
	, children
	, title
	, ...props
}) => (
	<HeaderActionItem {...props}>
		<OneIcon
			className={iconWrapperClassName}
			icon={icon}
			onClick={onClick}
			tag={iconWrapper}
			title={title}
		/>
		{children}
	</HeaderActionItem>
);

// overflow: (width of the tooltip / 2) - 20
export const StandardAction = ({ overflow, text, children, fontSize, noTooltip, ...props }) => (
	<OneIconAction {...props}>
		{children}
		<StandardTooltip hide={noTooltip} overflow={overflow} text={text} fontSize={fontSize}/>
	</OneIconAction>
);

export const ActionWithDisabled = withDisableableOnClick(StandardAction);

export const DropdownAction = ({
	children
	, noTooltip
	, overflow
	, text
	, ...props
}) => (
	<HeaderActionItem {...props}>
		{children}
		<StandardTooltip hide={noTooltip} overflow={overflow} text={text} />
	</HeaderActionItem>
);

export const BackToList = ({ onClick, iconClassName, ...props }) => (
	<OneHeaderAction {...props}>
		<OneIcon
			onClick={onClick}
			title={I("Back to list")}
			icon={iconClassName ? iconClassName : "icon-arrow-left"}
		/>
	</OneHeaderAction>
);

export const BackToSearch = ({linkTo, ...props}) =>(
	<OneHeaderAction {...props}>
		<Link to={linkTo}>
			<i className={"icon-arrow-left icons-content"} />
		</Link>
	</OneHeaderAction>
);

export const SwitchView = ({onClick, ...props}) => (
	<OneHeaderAction {...props}>
		<OneIcon
			onClick={onClick}
			title={I("Switch to conversation view")}
			icon={ICON_CONVERSATION_VIEW}
		/>
	</OneHeaderAction>
);

export const ToggleMoreHeaderActions = ({ className, onClick, iconClassName, ...props }) => {
	className = classNames(className, "ignore-header-actions-onclickoutside");
	return (
		<OneHeaderAction {...props} className={className}>
			<OneIcon
				onClick={onClick}
				title={I("Back to list")}
				icon={"fas fa-ellipsis-v"}
			/>
		</OneHeaderAction>
	);
};

export const ButtonIcon = ({ overflow, text, children, noTooltip, ...props }) => (
	<OneIconAction {...props}>
		{children}
		<StandardTooltip hide={noTooltip} overflow={overflow} text={text} />
	</OneIconAction>
);

export const Seperator = ({hide}) => {
	if (hide) {
		return null;
	}
	return <li className="separator">&nbsp;</li>;
};

const ActionDropdownBase = ({
	className
	, data
	, hide
	, iconClassName
	, innerMenuClassName
	, id
	, toggle
	, title
	, onSelectItem
	, onToggle
}) => (
	<DropdownAction
		className={classNames("dropdown", className)}
		hide={hide}
		text={title}
		noTooltip={toggle}
	>
		<NormalActionDropdown
			id={id}
			show={toggle}
			isHeader={true}
			headerText={title}
			menuClassName={innerMenuClassName}
			data={data}
			onToggle={onToggle}
			onSelectItem={onSelectItem}
		>
			<i className={iconClassName} />
			<i className={getChevronIcon(toggle)} />
		</NormalActionDropdown>
	</DropdownAction>
);

const ActionDropdown = withToggle(ActionDropdownBase);

const exportReportStr = I("Export")
	, exportReportData = [{id: ET_EXCEL, value: EXPORT_TYPES[ET_EXCEL]}];
	;
export const ExportAction = ({ id, onClick, ...props }) => (
	<ActionDropdown {...props}
		className="white"
		innerMenuClassName="small last"
		data={exportReportData}
		iconClassName="icon-export"
		id={id}
		onSelectItem={onClick}
		title={exportReportStr}
	/>
);
