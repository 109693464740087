import {
	getExportList,
	getSimpleTagListByArea,
	postCreateExport,
	putSaveExport,
	deleteExport,
	patchActivateExport,
	postGeneratedPreview,
	getGeneratedExport,
	deleteGeneratedExport,
	getGeneratedCdrExport,
	getExportFile,
	getExportCallList,
	putExportCall,
	deleteExportCall,
	patchActivateExportCall
} from './ajax';
import {
	cancelCurrentExport,
	updateExportCallList
} from '../export';
import {
	keyExportList,
	keySimpleTagListByArea,
	keyCreateExport,
	keyUpdateExport,
	keyRemoveExport,
	keyActivateExport,
	keyGeneratedExport,
	keyRemoveGeneratedExport,
	keyGeneratedPreview,
	keyGeneratedCdrExport,
	keyRemoveGeneratedCdrExport,
	keyExportCallList,
	keyExportCallSave,
	keyExportCallRemove,
	keyExportCallActivate
} from '../../constants/keys';
import {
	async,
	createActionCreators
} from '../../util';
import {
	clearPopWaiting
	, popError
	, popPleaseWait
} from '../hmf';
import { adminActionStatus } from '../admin';

export const exports = createActionCreators([
	keyExportList,
	keySimpleTagListByArea,
	keyCreateExport,
	keyUpdateExport,
	keyRemoveExport,
	keyActivateExport,
	keyGeneratedCdrExport,
	keyGeneratedExport,
	keyRemoveGeneratedExport,
	keyGeneratedPreview,
	keyExportCallList,
	keyExportCallSave,
	keyExportCallRemove,
	keyRemoveGeneratedCdrExport,
	keyExportCallActivate
]);

export const exportList = () => {
	return async(getExportList(), exports[keyExportList]);
}

export const doExportCallList = () => {
	return async(getExportCallList(), exports[keyExportCallList]);
}

export const exportCallList = () => (dispatch, getState) => {
	dispatch(popPleaseWait(I("fetching list")));
	return dispatch(doExportCallList())
	.then(() => dispatch(clearPopWaiting()))
	.catch(() => dispatch(clearPopWaiting()))
	;
}

export const exportCallCreate = (activate) => (dispatch, getState) => {
	const params = getState().app.export.ui.currentExport;
	if(params.name === "" || (params.emailExport && params.emailAddress === "")) {
		return dispatch(popError("Please fill in all required fields"));
	}
	dispatch(popPleaseWait(I("saving info")));
	return dispatch(exportCallSave(params))
	.then(() => {
		if (!activate) {
			dispatch(exportCallList());
		} else {
			dispatch(updateExportCallList({id: params.id, action: "activate", value: true}));
		}
		dispatch(cancelCurrentExport());
		dispatch(clearPopWaiting())
		dispatch(adminActionStatus({ status: 2, msg: I("Finished") }));
	})
	.catch(err => {
		dispatch(popError(err));
		dispatch(adminActionStatus({ status: 3, msg: err }));
	}).catch(() => {
		dispatch(clearPopWaiting());
	})
	;
}

const exportCallSave = (params) => {
	const p = { params: JSON.stringify(params) };
	return async(putExportCall(params.id, p), exports[keyExportCallSave], p);
}

export const doExportCallRemove = (id) => {
	return async(deleteExportCall(id), exports[keyExportCallRemove]);
}

const doExportCallActivate = (id, value) => {
	return async(patchActivateExportCall(id, {activate: value}), exports[keyExportCallActivate])
}

export const exportCallActivate = (id, value) => (dispatch) => {
	return dispatch(doExportCallActivate(id, value))
	.then((data) => {
		const { id } = data;
		if (id) {
			dispatch(updateExportCallList({id: data.id, action: "activate", value}));
		}
	})
	.catch(err => dispatch(popError(err)))
	;
}

export const exportCallRemove = (id) => (dispatch) => {
	return dispatch(doExportCallRemove(id))
	.then((data) => {
		const { id } = data;
		if (id) {
			dispatch(updateExportCallList({id: data.id, action: "remove"}));
		}
	})
	.catch(err => dispatch(popError(err)))
	;
}

export const fetchTagList = items => async(getSimpleTagListByArea({params:
		JSON.stringify({
			areaIds: items,
			includeUntagged: true
		}, null, '  ')
	}),
	exports[keySimpleTagListByArea]
)
export const createExportAjax = params => {
	return async(postCreateExport(JSON.stringify(params)), exports[keyCreateExport], params)
}
export const createExport = () => (dispatch, getState) => {
	const params = getState().app.export.ui.currentExport
	return dispatch(createExportAjax(params))
	.then((data) => {
		if(data != null) {
			dispatch(cancelCurrentExport())
		}
	})
}
export const saveExportAjax = params => {
	return async(putSaveExport(params.id, JSON.stringify(params)), exports[keyUpdateExport], params)
}
export const saveExport = () => (dispatch, getState) => {
	const params = getState().app.export.ui.currentExport
	return dispatch(saveExportAjax(params))
	.then((data) => {
		if(data != null) {
			dispatch(cancelCurrentExport())
		}
	})
}
export const removeExportAjax = id => async(deleteExport(id), exports[keyRemoveExport])
export const removeExport = id => (dispatch, getState) => {
	return dispatch(removeExportAjax(id))
}
export const activateExportAjax = (id, value) => {
	return async(patchActivateExport(id, {activate: value}), exports[keyActivateExport])
}
export const activateExport = (id, value) => (dispatch, getState) => {
	return dispatch(activateExportAjax(id, value))
}
export const fetchGeneratedListAjax = id => {
	return async(getGeneratedExport(id), exports[keyGeneratedExport])
};
export const fetchGeneratedList = id => (dispatch, getState) => {
	return dispatch(fetchGeneratedListAjax(id))
}
export const removeGeneratedExportAjax = id => {
	return async(deleteGeneratedExport(id), exports[keyRemoveGeneratedExport])
}
export const removeGeneratedExport = id => (dispatch, getState) => {
	return dispatch(removeGeneratedExportAjax(id))
}
export const generatePreviewAjax = params => {
	return async(postGeneratedPreview(JSON.stringify(params)), exports[keyGeneratedPreview])
}
export const generatePreview = () =>  (dispatch, getState) => {
	const params = getState().app.export.ui.currentExport
	dispatch(popPleaseWait(I("Generating preview")));
	return dispatch(generatePreviewAjax(params))
	.then(() => {
		dispatch(clearPopWaiting())
	})
	.catch(err => dispatch(popError(err)))
	.catch(() => dispatch(clearPopWaiting()))
}
export const fileDownload = id => getExportFile(id)

export const fetchGeneratedCdrListAjax = id => {
	return async(getGeneratedCdrExport(id), exports[keyGeneratedCdrExport])
};
export const fetchGeneratedCdrList = id => (dispatch, getState) => {
	return dispatch(fetchGeneratedCdrListAjax(id))
};
export const removeGeneratedCdrExportAjax = id => {
	return async(deleteGeneratedExport(id), exports[keyRemoveGeneratedCdrExport])
}
export const removeGeneratedCdrExport = id => (dispatch, getState) => {
	return dispatch(removeGeneratedCdrExportAjax(id))
}
