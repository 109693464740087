import {
	SET_SELECTED_EXPORT,
	SET_EXPORT_INFORMATION,
	SET_ERRAND_CATEGORY,
	REMOVE_ERRAND_CATEGORY,
	CANCEL_CURRENT_EXPORT,
	REMOVE_SEARCH_TERM,
	EXPORT_PREVIEW_CLOSE,
	TOGGLE_EXPORT_POPUP,
	CALL_EXPORT_CDR_CLOSE,
	UPDATE_EXPORT_CALL_LIST
} from '../constants/constants';

export const setSelectedExport = (id, view) => ({
	type: SET_SELECTED_EXPORT,
	payload: {id, view}
});
export const doSetExportValue = (field, value, isRemove) => ({
	type: SET_EXPORT_INFORMATION,
	payload: {field, value, isRemove}
});

export const setExportValue = (field, value, isRemove) => {
	return (dispatch) => {
		dispatch(doSetExportValue(field, value, isRemove));
		return Promise.resolve();
	};
};

export const setErrandCategory = (field, value) => ({
	type: SET_ERRAND_CATEGORY,
	payload: {field, value}
});
export const removeErrandCategory = (field, value) => ({
	type: REMOVE_ERRAND_CATEGORY,
	payload: {field, value}
});
export const cancelCurrentExport = () => ({
	type: CANCEL_CURRENT_EXPORT,
	payload: {}
});
export const updateExportCallList = (params) => ({
	type: UPDATE_EXPORT_CALL_LIST,
	payload: params
});
export const closeGeneratedCdr = () => ({
	type: CALL_EXPORT_CDR_CLOSE,
	payload: {}
});
export const removeSearchTerm = (field, value) => ({
	type: REMOVE_SEARCH_TERM,
	payload: {field, value}
});
export const previewClose = () => ({
	type: EXPORT_PREVIEW_CLOSE,
	payload: {}
});

export const toggleExportPopUp = show => ({
	type: TOGGLE_EXPORT_POPUP,
	payload: show
});
