import { useEffect, useRef } from 'react'
import { createPortal } from 'react-dom'
import { branch, renderNothing } from 'recompose'

const portal = document.getElementById('portals')

const createEffect = (dom, current) => () => {
  if (dom) {
    dom.appendChild(current)
  }
  return () => {
    if (dom) {
      dom.removeChild(current)
    }
  }
}

const useGlobalPortal = () => {
  const el = useRef(document.createElement('div'))
  const { current } = el
  useEffect(createEffect(portal, current), [el])
  return current
}

const usePortal = ({ current: modalCurrent }) => {
  const el = useRef(document.createElement('div'))
  const { current } = el
  useEffect(createEffect(modalCurrent, current), [el, modalCurrent])
  return current
}

const Children = ({ children }) => children

const withPortal = () => ({ children, modalRef }) => createPortal(
  children,
  usePortal(modalRef)
)

const isValidModalRef = ({ modalRef }) => !!modalRef

export const Modal = branch(isValidModalRef, withPortal)(Children)

export const Popup = branch(isValidModalRef, withPortal, renderNothing)()

export const GlobalModal = ({ children }) => createPortal(
  children,
  useGlobalPortal()
)

export default usePortal
