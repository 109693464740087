// withDropdownHeaderToggle:
//   <Dropdown>
//     <DropdownToggle>
//       children
//     </DropdownToggle>
//     <FertileMenu />
//   </Dropdown>
// <FertileMenu data={data} {...props} />:
//   <Portal>
//     <DropdownMenu>
//     </DropdownMenu>
//   </Portal>
// case 1: dropdown toggle independent of menu children processing.
//   a. create component menu with menu content using FertileMenu or extended
//      version as the menu container / wrapper.
//   b. apply withDropdownHeaderToggle onto the menu component.
//   c. done.
// case 2: dropdown toggle dependent of menu children processing (refer
//         to assist component).
//   a. create HOC which take dropdown component as argument, create menu and
//      menu content components using FertileMenu or extended version as the
//      menu container / wrapper. Put the menu component as menu prop to
//      dropdown component.
//   b. compose the HOC together withDropdownHeaderToggle.
//   c. use component that return menu prop as result. Done.
// NOTE: pass 'data' along and set dynamicData true make sure popper update
// correctly.
import React, {
	Fragment
	, PureComponent
	, memo
	, useCallback
	, useContext
	, useEffect
	, useState
	, useMemo
} from 'react';
import PropTypes from 'prop-types';
import { Popper } from 'react-popper';
import classNames from 'classnames';
import styled from 'styled-components';
import each from 'lodash/each';
import {
	branch,
	compose,
	mapProps,
	renameProp,
	renderComponent,
	withProps
} from 'recompose';
import update from 'immutability-helper';
import EllipsisText from "react-ellipsis-text";
import { L } from '../common/v5/config';
import {
	emptyArray,
	emptyObject,
	idNameSmall,
	identity
} from '../common/constants';
import {
	centionBackgroundBlue,
	centionBackgroundGrey,
	centionBlue,
	centionDisabled,
	centionDropdownBackground,
	centionGrey,
	centionTitleFontWeight,
	centionTitleGrey,
	fontSizeDropdown,
	fontSizeDropdownAppendIcon,
	inputBorderRadius,
	inputMinHeight,
	tintLightGray,
	tintDarkGrey
} from '../styles/_variables';
import {
	DummyFunction
	, getChevronIcon
	, truncateHtml
	, createMarkup
} from '../common/v5/helpers';
import { StandardTooltip } from './HeaderActions';
import { DROPDOWN_TEXT_LIMIT, UNSELECT, ICON_STAR, ICON_STAR_OUTLINE } from '../common/v5/constants';
import {
	TXT_ALL,
	TXT_NO_SELECTION,
	TXT_NUMBER_SELECTED,
	TXT_SELECT_ORGS_AREAS
} from '../common/v5/receiptGreetingConstants';
import { I } from '../common/v5/config';
import {
	Dropdown as ReactstrapDropdown
	, DropdownContext
	, DropdownToggle
	, DropdownMenu
	, DropdownItem
	, Tooltip
	, Util
	, Collapse
} from 'reactstrap';
import { cursorAuto } from "./common";
import {
	composeWithDisplayName,
	createUnclickable,
	createWithMountCondition,
	createWithMountWhenPropTrue,
	createWithOnClickOutside,
	removeProps,
	withUnmountWhenHidden
} from "./hocs";
import Anchor from "./Anchor";
import { focusedBorderStyled } from "./Button";
import { Spinner } from "./Spinner";
import SquareCheckbox from "./SquareCheckbox";
import Wrappable from "./Wrappable";
import {
	useCallbackMultiValues,
	useDisableableCallback
} from "../hooks/callback";
import { GlobalModal, Modal } from "../hooks/portal";
import { useArrayToArrayObject, useDenormalize } from '../hooks/state';
import { useToggle } from '../hooks/toggle';
import Select from "react-dropdown-select";

export var newDropdownUI = true

const baseClassName = "c3-dropdown"

const withMenuClassName = renameProp('menuClassName', 'className')

// Portal allows dropdown avoid z-index without using css z-index. It uses react
// portal. It support global which is located at fixed top most document
// position and local where one can decide which element to portal.
const Portal = composeWithDisplayName(
	'Portal',
	memo,
	branch(
		({ enableGlobalModal, modalRef }) => {
			if (!modalRef && enableGlobalModal) {
				if (process.env.NODE_ENV !== 'production') {
					console.log('dbg: reactstrap dropdown menu can only be portaled ' +
					"into component that's ancestor to the portal-ed menu's dropdown. " +
					"GlobalModal isn't within the app DOM tree, so can't be used. " +
					'But reactstrap latest commit seem solved it though not release yet.')
				}
			}
			return false
		},
		renderComponent(GlobalModal)
	)
)(Modal)

const withReadOnlyNoToggle = createUnclickable(
	"toggle"
	, "readOnly"
	, cursorAuto
);

export const BSDropdown = withReadOnlyNoToggle(ReactstrapDropdown);

const withDropdownToggle = Component => ({
	'aria-expanded': ariaExpanded,
	children,
	'data-toggle': dataToggle,
	disabled,
	showCaret,
	tag,
	title,
	toggleDataQAId,
	toggleClassName,
	...props
}) => (
	<Component {...props}>
		<DropdownToggle
			aria-expanded={ariaExpanded}
			caret={showCaret}
			className={toggleClassName}
			data-qa-id={toggleDataQAId}
			data-toggle={dataToggle}
			disabled={disabled}
			tag={tag || 'span'}
		>
			{children || title}
		</DropdownToggle>
	</Component>
)

const withDropdownHeader = Component => ({
	children,
	className,
	'data-qa-id': dataQAId,
	direction,
	inNavbar,
	onToggle,
	readOnly,
	show,
	size,
	...props
}) => (
	<BSDropdown
		data-qa-id={dataQAId}
		className={classNames(baseClassName, className)}
		inNavbar={inNavbar}
		isOpen={show}
		readOnly={readOnly}
		size={size}
		toggle={onToggle}
		direction={direction}
	>
		{children}
		<Component {...props} />
	</BSDropdown>
)

const withDropdownHeaderToggleNoClassName = composeWithDisplayName(
	'withDropdownHeaderToggleNoClassName',
	renameProp('isHeader', 'hasHeader'),
	withDropdownToggle,
	withDropdownHeader,
	createWithMountCondition(({ notReady }) => !notReady),
	withMenuClassName
)

const withDropdownHeaderToggle = composeWithDisplayName(
	'withDropdownHeaderToggle',
	withProps({ toggleClassName: 'icons-content toggle-full-width' }),
	withDropdownHeaderToggleNoClassName
)

class DropdownCheckbox extends PureComponent {
	constructor(props){
		super(props);
		this.handleClick = this.handleClick.bind(this);
	}
	handleClick(){
		this.props.onClick(false);
	}
	render(){
		return (
			<div className="dropdown-checkbox rounded">
				<SquareCheckbox
					className={classNames(
						'small',
						{ 'icon-minus-circle' : this.props.selected === 'partial'}
					)}
					data-qa-id={this.props["data-qa-id"]}
					checked={this.props.selected}
					onClick={this.handleClick}
				/>
			</div>
		);
	}
}

export class NotifCheckbox extends PureComponent {
	constructor(props){
		super(props);
		this.handleClick = this.handleClick.bind(this);
	}
	handleClick(e){
		this.props.onClick(this.props.id, !this.props.selected );
	}
	render(){
		return (
			<div className="dropdown-checkbox rounded">
				<SquareCheckbox
					className={classNames(
						'small',
						{ 'icon-minus-circle' : this.props.selected === 'partial'}
					)}
					data-qa-id={this.props["data-qa-id"]}
					checked={this.props.selected}
					onClick={this.handleClick}
				/>
			</div>
		);
	}
}

const getItemDataQAId = (active, me, value) => {
	let ddItemId = "dd-one-item"
	if (typeof value === "object") {
		if (value.props && value.props.text) {
			ddItemId += value.props.text
		} else {
			ddItemId += me
		}
	} else {
		if (value) {
			ddItemId += value
		} else {
			ddItemId += me
		}
	}
	if (active) {
		ddItemId += "-active"
	}
	return ddItemId
}

const Item = ({
	active,
	children,
	className,
	'data-qa-id': dataQAId,
	multiSelect,
	onClick
}) => {
	let handleClick
	let tag
	if (multiSelect) {
		tag = 'a'
	} else {
		handleClick = onClick
	}
	return (
		<DropdownItem
			active={active}
			className={classNames('item', 'selected', className)}
			data-qa-id={dataQAId}
			onClick={handleClick}
			tag={tag}
			toggle={!multiSelect}
		>
			{children}
		</DropdownItem>
	)
}

const SelectableHeader = ({ children, onClick, selected }) => (
	<Fragment>
		<DropdownCheckbox
			className="dd-header"
			onClick={onClick}
			selected={selected}
		/>
		<span onClick={onClick}>{children}</span>
	</Fragment>
)

const HeaderItem = ({ children, className }) => (
	<DropdownItem className={classNames("dd-header", className)} header>
		{children}
	</DropdownItem>
)

const headerItemStyle = `
	span {
		color: ${centionTitleGrey};
		font-weight: ${centionTitleFontWeight};
	}
`
const StyledHeaderItem = styled(HeaderItem)`
	&.dd-header {
		${headerItemStyle}
	}
`
const ReadOnlyHeader = ({ children, className }) => (
	<div className={className}>
		<StyledHeaderItem>{children}</StyledHeaderItem>
	</div>
)

const withHeader = Component => ({
	className,
	groupSelect,
	selected,
	...props
}) => (
	<div
		className={classNames(
			className,
			{ 'dd-header': groupSelect },
			{ partial: groupSelect && selected === 'partial' }
		)}
	>
		<Component groupSelect={groupSelect} selected={selected} {...props} />
	</div>
)

const Header = composeWithDisplayName(
	'Header',
	withHeader,
	branch(({ groupSelect }) => groupSelect, renderComponent(SelectableHeader))
)(ReadOnlyHeader)

const StyledHeader = styled(Header)`
	div& {
		${({ groupSelect }) => groupSelect ? 'float: none;' : ''}
		&.dd-header, .dd-header {
			${headerItemStyle}
		}
	}
`
const singleItemHoverActive = `
	// &:hover {
	// 	background-color: ${tintLightGray};
	// }
	&.active {
		background-color: ${centionBackgroundBlue};
		color: ${centionBlue};
		span {
			font-weight: ${centionTitleFontWeight};
		}
	}
`
const StyledItem = styled(Item)`
	a&, button& {
		&.item.selected.dropdown-item {
			color: ${centionGrey};
			${({ multiSelect }) => multiSelect ? '' : singleItemHoverActive}
		}
	}
`
const styleRed = { color: 'red' };

const OneItemBookmark = ({
	className,
	title,
	onSelect,
	favouriteList,
	itemId
}) => {

	const starEmpty = ICON_STAR_OUTLINE;
	const starInProgress = ICON_STAR;
	const starFill = ICON_STAR;

	const [loading, setLoading] = useState(false)
	const [icon, setIcon] = useState(starEmpty)

	const handleBookmarkClick = (e) => {
		setIcon(starInProgress) //inprogress
		setLoading(true)
		onSelect(e);
	}

	useEffect(() => {
		switch(true){
			case favouriteList && favouriteList.includes(itemId):
				setIcon(starFill)
				break
			default:
				setIcon(starEmpty)
				break
		}
	}, [favouriteList])

	useEffect(() => {
		//stop loading state whenever list is changed
		setLoading(false)
	}, [favouriteList])

	let loaderClass = loading ? "loading" : "";

	return (
		<div className={`one-item ${className ? className : ""}`}>
			<span className='title'>{title}</span>
			<i className={`${icon} ${loaderClass}`} onClick={handleBookmarkClick}></i>
		</div>
	)
}

//TODO: Style Dropdown as designed
class OneItem extends PureComponent {
	constructor(props) {
		super(props);
		this.handleClick = this.handleClick.bind(this);
	}
	handleClick(singleSelect) {
		const { onClick } = this.props
		if (typeof onClick === 'function') {
			const { clickEventReturnCurrentSelected, index, selected } = this.props
			onClick(
				index,
				selected,
				typeof singleSelect === "undefined" ||
					singleSelect ||
					clickEventReturnCurrentSelected
			)
		}
	}
	render() {
		const {
			className,
			isHeader,
			groupSelect,
			value,
			multiSelect,
			selected,
			children,
			isActive,
			withFavourite,
			favouriteList,
			onClickFavourite,
			me
		} = this.props;
		if (isHeader) {
			return (
				<StyledHeader
					className={className}
					groupSelect={groupSelect}
					onClick={this.handleClick}
					selected={selected}
				>
					{value}
				</StyledHeader>
			)
		} else {
			const active = !multiSelect && selected;
			let child;
			if(!children) {
				if(!multiSelect) {
					if(withFavourite) {
						child = (
							<OneItemBookmark
								className={"with-favourites"}
								title={value}
								onSelect={e => onClickFavourite(e, me)}
								favouriteList={favouriteList}
								itemId={me}
							/>
							)
					} else {
						child = value;
					}
				} else {
					let style;
					if (isActive === false) {
						style = styleRed;
					}
					child = (
						<span style={style}>
							<DropdownCheckbox
								data-qa-id={"ddcb-"+me}
								selected={selected}
								onClick={this.handleClick}
							/>&nbsp;
							<span onClick={this.handleClick}>{value}</span>
						</span>
					);
				}
			} else {
				child = children;
			}
			return (
				<StyledItem
					active={active}
					className={className}
					data-qa-id={getItemDataQAId(active, me, value)}
					multiSelect={multiSelect}
					onClick={this.handleClick}
				>
					{child}
				</StyledItem>
			)
		}
	}
}

const dataFieldValue = (data, fieldKey) => {
	if (typeof fieldKey === 'function') {
		return fieldKey(data)
	}
	return data[fieldKey]
}

// index here is NOT the array index but just another object key name 'index'.
const getIdValueIndexTitle = (idFields, _value) => {
	let idKey, valueKey, titleKey, indexKey
	if (typeof idFields === 'object') {
		idKey = idFields.id
		valueKey = idFields.value
		if (idFields.index) {
			indexKey = idFields.index
		} else {
			indexKey = idKey
		}
		if (idFields.title) {
			titleKey = idFields.title
		} else {
			titleKey = valueKey
		}
	} else {
		idKey = 'id'
		valueKey = 'value'
		indexKey = idKey
		titleKey = valueKey
	}
	return {
		id: dataFieldValue(_value, idKey),
		value: dataFieldValue(_value, valueKey),
		index: dataFieldValue(_value, indexKey),
		title: dataFieldValue(_value, titleKey)
	}
}

const getIdValueIndexTitle2 = (idFields, _value) => {
	let idKey, valueKey, titleKey, indexKey
	if (typeof idFields === 'object') {
		idKey = idFields.id
		valueKey = idFields.value
		if (idFields.index) {
			indexKey = idFields.index
		} else {
			indexKey = idKey
		}
		if (idFields.title) {
			titleKey = idFields.title
		} else {
			titleKey = valueKey
		}
	} else {
		idKey = 'id'
		valueKey = 'value'
		indexKey = idKey
		titleKey = valueKey
	}
	return {
		id: _value.id,
		value: _value.name,
		index: _value.id,
		title: _value.name
	}
}

const SingleItem = ({
	disableEllipsis,
	id,
	index,
	onClick,
	title,
	value,
	...props
}) => {
	if (!disableEllipsis && typeof value === 'string' && false) {
		value = (
			<EllipsisText
				text={value}
				title={title}
				length={DROPDOWN_TEXT_LIMIT}
			/>
		)
	}
	return (
		<OneItem
			index={index}
			me={id}
			onClick={onClick}
			value={L(value)}
			{...props}
		/>
	)
}

const themeDropdownBackground = centionDropdownBackground

const StyledMenuScroll = styled.div`
	max-height: 50vh;
	max-width: 33vw;
	min-width: 190px;
	overflow: auto;
`
const StyledMarginTop = styled.div`
	width: 100%;
	${StyledItem} + ${StyledHeader} {
		margin-top: 10px;
	}
`
const dropdownMenuActionStyle = `
	.multiselect-btn {
		font-size: ${fontSizeDropdown};
		margin: 0;
	}
	.left-btn {
		float: left;
		margin: 0;
		// margin-left: 20px;
		// margin-right: 20px;
		&::after {
			content: "|";
			color: ${centionGrey};
			margin: 0 10px;
		}
	}
`
const dropdownMenuContentStyle = `
	height: auto;
	padding: 10px;
	&.small {
		min-width: unset;
		width: 90px;
		&.last {
			// NOTE: this '!important' is needed because can not find anyway
			// to customize position true style of reactstrap library which
			// use Popper, because 5.0 using old version of reactstrap,
			// because any newer version of reactstrap requires React-v16.
			left: -20px!important;
		}
	}
	.dd-header {
		padding: 8px 10px;
		width: 100%;
	}
	${StyledItem} {
		cursor: pointer;
	}
	a, button {
		padding: 8px 20px;
		font-size: ${fontSizeDropdown};
		width: 100%;
		line-height: 1;
		background: ${themeDropdownBackground};
		color: ${centionGrey};
		outline: none;
		&:last-child {
			border-bottom: none;
		}
		&${StyledItem} {
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}
	${dropdownMenuActionStyle}
	.multiselect-btn {
		padding-bottom: 8px;
		margin-bottom: 10px;
	}
`
const dropdownMenuStyle = `
	padding: 0px;
	${StyledMenuScroll} {
		${dropdownMenuContentStyle}
		${StyledMarginTop} {
			${StyledHeader}.dd-header {
				.dropdown-checkbox {
					float: unset;
				}
			}
			${StyledItem}.dropdown-item {
				// TODO: this created arm-race between manual errand classes. Proper
				// solution will be avoid using css rule that govern any re-usable
				// component.
				a.assist-item-preview-add {
					background-color: transparent;
					color: ${centionBlue};
					font-size: ${fontSizeDropdown};
					line-height: 1;
					padding: 0px;
					text-align: right;
					text-decoration: underline;
					width: 20%;
					&:hover {
						color: ${centionBlue};
					}
				}
			}
		}
	}
`
const noFlipModifier = { flip: { enabled: false } }

const directionPositionMap = {
	up: 'top',
	left: 'left',
	right: 'right',
	down: 'bottom'
}

const StyledDivArrow = styled.div`
	div {
		position: absolute;
		width: 2em;
		height: 2em;
		&[data-placement*='bottom'] {
			top: 0;
			left: 0;
			margin-top: -0.9em;
			width: 2em;
			height: 1em;
			&::before {
				border-width: 0 0.8em 1em 0.8em;
				border-color: transparent transparent ${themeDropdownBackground} transparent;
			}
		}
		&[data-placement*='top'] {
			bottom: 0;
			left: 0;
			margin-bottom: -0.9em;
			width: 2em;
			height: 1em;
			&::before {
				border-width: 1em 0.8em 0 0.8em;
				border-color: ${themeDropdownBackground} transparent transparent transparent;
			}
		}
		&[data-placement*='right'] {
			left: 0;
			margin-left: -0.9em;
			height: 2em;
			width: 1em;
			&::before {
				border-width: 0.8em 1em 0.8em 0;
				border-color: transparent ${themeDropdownBackground} transparent transparent;
			}
		}
		&[data-placement*='left'] {
			right: 0;
			margin-right: -0.9em;
			height: 2em;
			width: 1em;
			&::before {
				border-width: 0.8em 0 0.8em 1em;
				border-color: transparent transparent transparent ${themeDropdownBackground};
			}
		}
		&::before {
			content: '';
			margin: auto;
			display: block;
			width: 0;
			height: 0;
			border-style: solid;
		}
	}
`
const Arrow = ({ innerRef, ...props }) => (
	<StyledDivArrow>
		<div ref={innerRef} {...props} />
	</StyledDivArrow>
)

// Re-implement reactstrap source (v8.7.1) dropdown menu component with own
// popper so menu can have arrow.
const PopperableMenu = ({ enableArrow, persist, ...props }) => {
	const { direction, inNavbar, isOpen } = useContext(DropdownContext)
	if (!enableArrow || !(persist || (isOpen && !inNavbar))) {
		return <DropdownMenu {...props} />
	}
	const {
		children,
		className,
		cssModule,
		flip = true,
		modifiers,
		positionFixed,
		right,
		style: _style,
		tag = 'div',
		...attrs
	} = props
	const classes = Util.mapToCssModules(classNames(
		className,
		'dropdown-menu',
		{
			'dropdown-menu-right': right,
			show: isOpen
		}
	), cssModule);
	const position1 = directionPositionMap[direction] || 'bottom'
	const position2 = right ? 'end' : 'start'
	const poperPlacement = `${position1}-${position2}`
	const poperModifiers = !flip ? {
		...modifiers,
		...noFlipModifier,
	} : modifiers
	const popperPositionFixed = !!positionFixed
	const Tag = tag
	return (
		<Popper
			modifiers={poperModifiers}
			placement={poperPlacement}
			positionFixed={popperPositionFixed}
		>
			{({ ref, style, placement, arrowProps }) => (
				<Tag
					tabIndex="-1"
					role="menu"
					ref={ref}
					{...attrs}
					style={{ ..._style, ...style }}
					aria-hidden={!isOpen}
					className={classes}
					x-placement={placement}
				>
					{children}
					<Arrow
						innerRef={arrowProps.ref}
						data-placement={placement}
						style={arrowProps.style}
					/>
				</Tag>
			)}
		</Popper>
	)
}

const flipModifier = {
	flip: {
		enabled: true,
		flipVariations: true,
		flipVariationsByContent: true
	}
}

const offsetModifier = {
	offset: {
		enabled: true,
		offset: '0, 10'
	}
}

const preventOverflowModifier = {
	preventOverflow: {
		enabled: true,
		padding: 20
	}
}

const preventOverflowXModifier = {
	preventOverflow: {
		enabled: true,
		padding: 20,
		priority: ['left', 'right']
	}
}

// NOTE: this is purposely NOT to be memoized as it need to be constantly as
// popper to update the position when there is any change within the component.
const calculateModifier = (data, flip = true, modifiers) => {
	const ov = flip ? preventOverflowModifier : preventOverflowXModifier
	const overflow = { ...ov, ...offsetModifier }
	if (!modifiers) {
		modifiers = overflow
	} else {
		modifiers = { ...overflow, ...modifiers }
	}
	if (flip) {
		return { ...flipModifier, ...modifiers }
	}
	return modifiers
}

const MenuWithModifier = ({ data, flip, modifiers, ...props }) => (
	<PopperableMenu
		flip={flip}
		modifiers={useMemo(
			() => calculateModifier(data, flip, modifiers),
			// NOTE: data is need to refresh internal popper component.
			[data, flip, modifiers]
		)}
		{...props}
	/>
)

const MenuBase = createWithOnClickOutside('onClickOutside')(MenuWithModifier)

const StyledMenu = styled(MenuBase)`
	${dropdownMenuStyle}
	&.dropdown-menu.show {
		max-height: unset;
		overflow: unset;
	}
`
const withDropdownMenu = Component => ({
	data,
	enableArrow,
	flip,
	modifiers,
	positionFixed,
	...props
}) => (
	<StyledMenu
		className={props.menuClassName}
		data={data}
		enableArrow={enableArrow}
		flip={flip}
		modifiers={modifiers}
		positionFixed={positionFixed}
	>
		<StyledMenuScroll>
			<Component {...props} />
		</StyledMenuScroll>
	</StyledMenu>
)

const withPortalableMenu = Component => ({
	enableGlobalModal,
	modalRef,
	...props
}) => (
	<Portal enableGlobalModal={enableGlobalModal} modalRef={modalRef}>
		<Component disableOnClickOutside={false} {...props} />
	</Portal>
)

const StyledMaxHeightOldDropdownMenu = styled(DropdownMenu)`
	max-height: 60vh;
	overflow: auto;
`
const withOldDropdownMenu = Component => props => (
	<StyledMaxHeightOldDropdownMenu className={props.menuClassName}>
		<Component {...props} />
	</StyledMaxHeightOldDropdownMenu>
)

const withAutoInitialBase = Component => ({
	noValidSelection,
	onAutoInitialization,
	...props
}) => {
	useEffect(() => {
		if (noValidSelection) {
			if (process.env.NODE_ENV !== 'production') {
				console.log('dbg: auto initialize')
			}
			onAutoInitialization()
		}
	}, [noValidSelection, onAutoInitialization])
	return <Component {...props} />
}

const withAutoInitial = branch(
	({ onAutoInitialization }) => typeof onAutoInitialization === 'function',
	withAutoInitialBase
)

const MenuWrapper = composeWithDisplayName(
	'MenuWrapper',
	memo,
	branch(
		({ newDropdownMenu }) => newDropdownMenu || newDropdownUI,
		compose(
			withPortalableMenu,
			withProps({ positionFixed: true }),
			mapProps(({ data, dynamicData, ...props }) => {
				if (!dynamicData) {
					data = emptyObject
				}
				return { data, ...props }
			}),
			withDropdownMenu,
			withAutoInitial
		),
		withOldDropdownMenu
	),
	mapProps(({ children }) => ({ children }))
)(Fragment)

const StyledDropdown = styled.div`
	${StyledMenu}, .dropdown-menu.show {
		${dropdownMenuStyle}
	}
`
const CursorPointerDiv = styled.div`
	cursor: ${({ disabled }) => disabled ? 'not-allowed' : 'pointer'};
	color: ${({ disabled }) => disabled ? tintDarkGrey : centionBlue};
`
const ClickableDivBase = ({ className, disabled, onClick, text }) => (
	<CursorPointerDiv
		className={classNames("multiselect-btn", className)}
		disabled={disabled}
		onClick={onClick}
	>
		{text}
	</CursorPointerDiv>
);

const ClickableDiv = composeWithDisplayName(
	'ClickableDiv',
	withUnmountWhenHidden,
	memo
)(ClickableDivBase)

const getItemSelected = (data, selected) => {
	// only selected > 0 will get into this function
	if (selected.length > 1) {
		return '*';
	}
	let name;
	each(data, (v, i) => {
		if (v.id === selected[0]) {
			name = v.name;
			return false;
		}
	});
	if (name) {
		if (name.length <= 4) {
			return name;
		}
		return name.substring(0, 4) + '..';
	}
	return "<unknown>";
};

const withSelected = Component => ({ data, selected }) => (
	<Component style={selectedStyle}>
		&nbsp;{getItemSelected(data, selected)}
	</Component>
);

const Selected = composeWithDisplayName(
	"Selected"
	, memo
	, createWithMountCondition(({ multiSelect, selected }) => !multiSelect && selected.length > 0)
	, withSelected
)("i")

const withTitleBase = Component => ({ title }) => (
	<Component>{title}&nbsp;</Component>
);

export const Chevron = ({ className, show }) => (
	<i className={classNames(className, getChevronIcon(show))} />
)

const StyledChevron = styled(Chevron)`
	margin-left: 0.4em;
`
const withChevronTitle = Component => ({ data, multiSelect, selected, show, showChevron, title }) => (
	<Component>
		{title}
		<Selected data={data} multiSelect={multiSelect} selected={selected} />
		<StyledChevron show={show} />
	</Component>
);

const Title = composeWithDisplayName(
	"Title"
	, memo
	, createWithMountCondition(({ title }) => !!title)
	, branch(({ showChevron }) => showChevron, withChevronTitle, withTitleBase)
)("span");

const getName = props => {
    const p = props;
    if (p.staticHeaderText) {
        return p.staticHeaderText;
    }
    if (p.multiSelect) {
        const selectedLength = p.selected.length;
        if (selectedLength > 0 && selectedLength === p.data.length) {
            return [I('All')];
        } else if (selectedLength > 1) {
            return p.selected.map(id => {
                const selectedItem = p.data.find(item => item.id === id);
                return selectedItem ? selectedItem.name : '';
            });
        } else if (selectedLength === 1) {
            const selectedItem = p.data.find(item => item.id === p.selected[0]);
            return selectedItem ? selectedItem.name : p.textNoItemSelected;
        } else {
            return [p.textNoItemSelected];
        }
    } else {
        if (!(p.selected < 0)) {
            const data = p.data[p.selected];
            if (data) {
                return [data.name];
            }
        }
        return [p.textNoItemSelected];
    }
};

const getNameTwoSelections = (
	{ byId, allIds },
	{ byId: secById, allIds: secAllIds },
	{ data, multiGroups, selected, textNoItemSelected }
) => {
	let selectedLength = 0
	each(selected, v => { selectedLength += v.length })
	if (selectedLength > 0 && selectedLength === data.length) {
		return I('All')
	} else if (selectedLength > 1) {
		return I('Multiple')
	} else if (selectedLength == 1) {
		let name
		each(p.data, (v, i) => {
			if (v.id === p.selected[0]) {
				name = v.name;
				return false;
			}
		});
		if(name) {
			return name;
		} else {
			return p.textNoItemSelected;
		}
	} else {
		return p.textNoItemSelected;
	}
	if (p.multiSelect) {
		const selectedLength = p.selected.length;
		if (selectedLength > 0 && selectedLength === p.data.length) {
			return I('All');
		} else if (selectedLength > 1) {
			return I('Multiple');
		} else if (selectedLength == 1) {
			let name;
			each(p.data, (v, i) => {
				if (v.id === p.selected[0]) {
					name = v.name;
					return false;
				}
			});
			if(name) {
				return name;
			} else {
				return p.textNoItemSelected;
			}
		} else {
			return p.textNoItemSelected;
		}
	} else {
		if (!(p.selected < 0)) {
			const data = p.data[p.selected];
			if(data) {
				return data.name;
			}
		}
		return p.textNoItemSelected;
	}
}

const withLinkBase = Component => ({
    children,
    readOnly,
    splitTitleAndLink,
    ...props
}) => {
	const renderArea= (names, readOnly, title, displayText) => {

		const renderedContent = (
			<div style={{ display: 'inline-block' }} title={title} readOnly={readOnly}>
				{displayText}
			</div>
		);
	
		return renderedContent;
	};
    let names;
    if (!children) {
        names = getName(props);
        children = Array.isArray(names) ? names.join(', ') : names;
    }

    let displayText = children;
    let title = '';

    if (Array.isArray(names)) {
        if (names.length > 1) {
            displayText = I("Multiple");
            title = names.join(', ');
        } else if (names.length === 1) {
            displayText = names[0];
            title = names[0];
        }
    }

    return (
        <Wrappable wrap={splitTitleAndLink}>
            <Component 
                data-qa-id={"anchor_"+(Array.isArray(names) ? names[0] : names)} 
                readOnly={readOnly} 
            >
               {renderArea(names, readOnly, title, displayText)}
            </Component>
        </Wrappable>
    );
};



const withLink = composeWithDisplayName(
	"withLink"
	, createWithMountCondition(({ showChevron }) => !showChevron)
	, withLinkBase
);

const Link = composeWithDisplayName(
	"Link"
	, memo
	, branch(({ ready }) => ready, withLink, renderComponent(Spinner))
)(Anchor);

const selectedStyle = { color: '#0c87f7', fontSize: '9px' };

const TitleAndLink = ({
	data
	, multiSelect
	, notReady
	, readOnly
	, selected
	, showChevron
	, show
	, title
	, ...props
}) => (
	<Fragment>
		<Title
			data={data}
			multiSelect={multiSelect}
			selected={selected}
			show={show}
			showChevron={showChevron}
			title={title}
		/>
		<Link
			data={data}
			multiSelect={multiSelect}
			readOnly={readOnly}
			ready={!notReady}
			selected={selected}
			showChevron={showChevron}
			{...props}
		/>
	</Fragment>
);

const useSelect = ({
	data
	, groupSelect
	, multiSelect
	, onSelect
	, selected
}) => useCallback((index, toggle, selectOnlyOne) => {
	const indexData = data[index]
	const datas = []
	if (!indexData.isHeader) {
		// only allow value selection to be callback
		onSelect(index, indexData, toggle, datas, selectOnlyOne);
		//force select ONLY the selected
	} else if (multiSelect && groupSelect) {
		let selectedAll = true;
		for (let i=index+1; i<data.length; i++) {
			if (!data.isHeader) {
				datas.push(data[i]);
				if (selectedAll) {
					let found;
					each(selected, (v, i) => {
						if (data[i] && v.id === data[i].id) {
							found = true;
							return false;
						}
					});
					if (!found) {
						selectedAll = false;
					}
				}
			} else {
				break;
			}
		}
		onSelect(index, indexData, selectedAll, datas, selectOnlyOne);
	}
}, [data, groupSelect, multiSelect, onSelect, selected]);

// isSelected return falsy value the item is not selected or non of its child is
// selected.
const isSelected = (props, idx, id, isHeader) => {
	const p = props
	const datas = p.data
	const data = p.data[idx]
	let selected
	let totalChild = 0
	let totalChildSelected = 0
	if (p.multiSelect) {
		if (isHeader) {
			each(datas, v => {
				if (data.id === parseInt(v.parent, 10)) { // match with parent
					totalChild++;
					if (p.selected.includes(v.id)) {
						totalChildSelected ++;
					}
				}
			})
			if (totalChild === totalChildSelected) {
				return selected = true
			} else if (totalChild !== totalChildSelected &&
				totalChildSelected !== 0) {
				return selected = 'partial'
			} else {
				return selected = false
			}
		}
		each(p.selected, v => {
			if (id === v) {
				selected = true;
				return false;
			}
		})
	} else if (idx === p.selected) {
		selected = true;
	}
	return selected;
}

const isActive = ({ active }) => {
	if (typeof active !== 'undefined' && active === false) {
		return false;
	}
	return true;
}

const ResetOrSelectAll = ({ disabled, hidden, id, onClick, text }) => (
	<ClickableDiv
		data-qa-id={"dd-select-all-"+id}
		disabled={disabled}
		className="left-btn"
		hidden={hidden}
		onClick={onClick}
		text={text ? text : I("Select all")}
	/>
)

const withFertileMenu = Component => ({
	allSelected,
	children,
	className,
	clickEventReturnCurrentSelected,
	data,
	disableOnClickOutside,
	disabledResetOrSelectAll,
	disabledSelectNone,
	dynamicData,
	enableArrow,
	enableGlobalModal,
	flip,
	headerText,
	id,
	isInitializedSelection,
	modalRef,
	modifiers,
	multiSelect,
	noDropdownMenu,
	noSelection,
	onClickOutside,
	onResetOrSelectAll,
	onSelectNone,
	resetOrSelectAll,
	resetOrSelectAllText,
	selectNone,
	selected
}) => {
	const hasResetOrSelectAll = resetOrSelectAll && typeof onResetOrSelectAll === 'function'
	const hasSelectNone = selectNone && typeof onSelectNone === 'function'
	const isAutoInitialization = typeof isInitializedSelection === 'function'
	const onAutoInitialization = useMemo(() => {
		if (isAutoInitialization) {
			if (multiSelect) {
				return onSelectNone
			}
			return onResetOrSelectAll
		}
	}, [isAutoInitialization, multiSelect, onResetOrSelectAll, onSelectNone])
	let disableSelectNone
	let noValidSelection
	if (isAutoInitialization) {
		if (isInitializedSelection(selected)) {
			disableSelectNone = true
		} else if (noSelection) {
			noValidSelection = true
		}
	}
	return (
		<MenuWrapper
			className={classNames(
				{ CheckUncheckAll: hasResetOrSelectAll || hasSelectNone },
				className
			)}
			data={data}
			disableOnClickOutside={disableOnClickOutside}
			dynamicData={dynamicData}
			enableArrow={enableArrow}
			enableGlobalModal={enableGlobalModal}
			flip={flip}
			modalRef={modalRef}
			modifiers={modifiers}
			noValidSelection={noValidSelection}
			onAutoInitialization={onAutoInitialization}
			onClickOutside={onClickOutside}
		>
			<ResetOrSelectAll
				disabled={isAutoInitialization && (multiSelect ? allSelected : disableSelectNone)}
				hidden={!hasResetOrSelectAll}
				id={id}
				onClick={onResetOrSelectAll}
				text={resetOrSelectAllText}
			/>
			<ClickableDiv
				className="right-btn"
				data-qa-id={"dd-select-none-"+id}
				disabled={disableSelectNone}
				hidden={!hasSelectNone}
				onClick={onSelectNone}
				text={I("Select none")}
			/>
			<StyledMarginTop>
				<Component
					hidden={!headerText}
					isHeader={true}
					title={headerText}
					value={headerText}
				/>
				{children}
			</StyledMarginTop>
		</MenuWrapper>
	)
}

const FertileMenu = composeWithDisplayName(
	"FertileMenu",
	memo,
	mapProps(({
		multiSelect,
		onReset,
		onSelectAll,
		selectAll,
		...props
	}) => {
		if (multiSelect) {
			return {
				multiSelect,
				onResetOrSelectAll: onSelectAll,
				resetOrSelectAll: selectAll,
				...props
			}
		}
		const { onSelectNone, selectNone,...p } = props
		const resetOrSelectAll = typeof onReset === 'function'
		let resetOrSelectAllText
		if (resetOrSelectAll) {
			resetOrSelectAllText = I('Reset')
		}
		return {
			multiSelect,
			onResetOrSelectAll: onReset,
			resetOrSelectAll,
			resetOrSelectAllText,
			...p
		}
	}),
	withFertileMenu,
	withUnmountWhenHidden
)(SingleItem)

const withMenu = Component => ({
	clickEventReturnCurrentSelected,
	...props
}) => {
	const handleSelect = useSelect(props)
	const d = []
	const { multiSelect, selected } = props
	let allSelected = true
	let noSelection = true
	each(props.data, (v, i) => {
		let key
		if (v.key) {
			key = v.key
		} else {
			key = ''+v.parent+v.id
		}
		const selected = isSelected(props, i, v.id, v.isHeader)
		if (selected) {
			if (noSelection) {
				noSelection = false
			}
		} else if (allSelected) {
			allSelected = false
		}
		d.push(
			<Component
				clickEventReturnCurrentSelected={clickEventReturnCurrentSelected}
				groupSelect={props.groupSelect}
				id={v.id}
				index={i}
				isActive={isActive(v)}
				isHeader={v.isHeader}
				key={key}
				multiSelect={props.multiSelect}
				onClick={handleSelect}
				parent={v.parent}
				selected={selected}
				title={v.title}
				value={v.name}
				withFavourite={props.withFavourite}
				favouriteList={props.favouriteList}
				onClickFavourite={props.onClickFavourite}
			/>
		)
	})
	return (
		<FertileMenu allSelected={allSelected} noSelection={noSelection} {...props}>
			{d}
		</FertileMenu>
	)
}

const getTitle = (title, data, name) => {
	if (typeof title === 'undefined' || typeof data[title] === 'undefined') {
		return name
	}
	return dataFieldValue(data, title)
}

const findIdFromArray = (result, id, array) => {
	if (array) {
		each(array, (_id, index) => {
			if (_id === id) {
				result.found = true
				result.index = index
				return false
			}
		})
	}
}

const idInArray = (id, array) => {
	const result = {}
	findIdFromArray(result, id, array)
	return result.found
}

const updateSelection = (selection, isSelected) => {
	if (isSelected) {
		if (selection.none) {
			selection.none = false
		}
	} else if (selection.all) {
		selection.all = false
	}
}

const getAndCheckMultiSelections = (
	selection,
	selected,
	id,
	groupOnly,
	subResult
) => {
	if (groupOnly) {
		if (!subResult) {
			return false
		}
		const { all, none } = subResult
		if (all) {
			return true
		} else if (none) {
			return false
		}
		return 'partial'
	}
	const _selected = idInArray(id, selected)
	updateSelection(selection, _selected)
	return _selected
}

const getAndCheckSelected = (
	multiSelect,
	selection,
	selected,
	id,
	...args
) => {
	if (multiSelect) {
		const [normKey] = args
		const restArgs = args.slice(1)
		return getAndCheckMultiSelections(
			selection,
			selected[normKey],
			id,
			...restArgs
		)
	}
	const result = id === selected
	updateSelection(selection, result)
	return result
}

const getNestedAllIds = (nested, idData) => {
	if (nested) {
		if (typeof nested === 'function') {
			return nested()
		} else if (idData) {
			return idData[nested]
		}
	}
}

const getSubNormKeyAndAllIdsBase = (sub, data, idData, id, norms, normKey) => {
	let _sub
	if (typeof sub === 'function') {
		_sub = sub(data, idData, id, norms, normKey)
	} else {
		_sub = sub
	}
	return {
		key: _sub.key,
		allIds: getNestedAllIds(_sub.nested, idData)
	}
}

const getSubNormKeyAndAllIds = (sub, data, idData, id, norms, normKey) => {
	if (!sub) {
		return emptyObject
	}
	return getSubNormKeyAndAllIdsBase(sub, data, idData, id, norms, normKey)
}

const subNormKeyAndAllIds = (sub, data, id, norms, normKey) => {
	if (!sub) {
		return emptyObject
	}
	return getSubNormKeyAndAllIdsBase(
		sub,
		data,
		data[normKey].byId[id],
		id,
		norms,
		normKey
	)
}

const addNormItems = (
	Component,
	result,
	props,
	normKey,
	allIds,
	parentNormKey,
	parentId
) => {
	const { groupOnly, parent, sub } = props.norms[normKey]
	if (!parentNormKey && parent) {
		// always add from parent
		if (!result.seen[parent]) {
			addNormItems(
				Component,
				result,
				props,
				parent,
				props.data[parent].allIds,
				'',
				''
			)
		}
		return
	}
	if (result.seen[normKey]) {
		return
	}
	if (!parentNormKey) {
		result.seen[normKey] = true
	}
	const {
		clickEventReturnCurrentSelected,
		data,
		groupSelect,
		idFields: { name: nameKey, title } = idNameSmall,
		multiSelect,
		norms,
		onSelect,
		selected
	} = props
	const { byId } = data[normKey]
	each(allIds, id => {
		let subResult
		const idData = byId[id]
		const {
			key: subNormKey,
			allIds: nestedAllIds
		} = getSubNormKeyAndAllIds(sub, data, idData, id, norms, normKey)
		if (nestedAllIds && nestedAllIds.length) {
			subResult = { all: true, list: [], none: true, seen: result.seen }
			addNormItems(
				Component,
				subResult,
				props,
				subNormKey,
				nestedAllIds,
				normKey,
				id
			)
		}
		const name = dataFieldValue(idData, nameKey)
		result.list.push(
			<Component
				clickEventReturnCurrentSelected={clickEventReturnCurrentSelected}
				groupSelect={groupSelect}
				id={id}
				index={result.list.length}
				isActive={isActive(idData)}
				isHeader={!!subNormKey}
				key={normKey+id}
				multiSelect={multiSelect}
				normKey={normKey}
				onClick={onSelect}
				parent={parentId}
				parentNormKey={parentNormKey}
				selected={getAndCheckSelected(
					multiSelect,
					result,
					selected,
					id,
					normKey,
					groupOnly,
					subResult
				)}
				title={getTitle(title, idData, name)}
				value={name}
			/>
		)
		if (subResult && subResult.list.length) {
			result.list.push(...subResult.list)
			if (result.all && !subResult.all) {
				result.all = false
			}
			if (result.none && !subResult.none) {
				result.none = false
			}
		}
	})
}

const getFirstNormAllIds = (data, id) => {
	if (typeof id === 'undefined' || !data[id]) {
		return emptyArray
	}
	return data[id].allIds
}

const withNormalizedDataMenu = Component => properties => {
	const { clickEventReturnCurrentSelected, idFields, ...props } = properties
	const result = { all: true, list: [], none: true, seen: {} }
	each(props.norms, (v, k) => {
		addNormItems(
			Component,
			result,
			properties,
			k,
			getFirstNormAllIds(props.data, k),
			'',
			''
		)
	})
	const { all: allSelected, list, none: noSelection } = result
	return (
		<FertileMenu allSelected={allSelected} noSelection={noSelection} {...props}>
			{list}
		</FertileMenu>
	)
}

const withNormalizedItem = Component => ({
	normKey,
	onClick,
	parent,
	parentNormKey,
	...props
}) => (
	<Component
		onClick={useCallbackMultiValues(
			onClick,
			normKey,
			props.id,
			parentNormKey,
			parent
		)}
		{...props}
	/>
)

const withNormalizedMenu = composeWithDisplayName(
	'withNormalizedMenu',
	withNormalizedDataMenu,
	withNormalizedItem
)

// NOTE1: share only part of dropdown builder HOCs because the menu component is
// built with mind to allow the use of react createPortal.
const DropdownBase = composeWithDisplayName(
	'DropdownBase',
	memo,
	withProps({ newDropdownMenu: true }),
	withDropdownHeaderToggle,
	branch(
		({ norms }) => norms,
		withNormalizedMenu,
		withMenu
	)
)(SingleItem)

// NOTE: changing the core of fundamental of this component need to understand
// following:
// a) multiSelect props determine whether the component use index based or id
//    based selection where when multiSelect is true then id based is used else
//    selected value will be indexed based.
// b) LIKELY that the modification on this component is not needed (especially
//    on how the selection work). Most of the intricated logic handled by
//    AreaDropdown component.
// NOTE: this dropdown Menu component is broken in the sense that it not
//       compatible with DropdownMenu from reactstrap because it lack of
//       reactstrap context.
const Dropdown = ({
	className,
	"data-qa-id": dataQAId,
	enableGlobalModal,
	groupSelect,
	id,
	isInitializedSelection,
	modalRef,
	onClick,
	onClickOutside,
	onToggle,
	showCaret,
	showChevron,
	hideTitleLink,
	...props
}) => (
	<StyledDropdown
		className={classNames(
			baseClassName,
			{ "ignore-react-onclickoutside": !!modalRef },
			className
		)}
		show={props.show}
	>
		<DropdownBase
			data-qa-id={dataQAId ? dataQAId : id}
			enableGlobalModal={enableGlobalModal}
			id={id}
			isInitializedSelection={isInitializedSelection}
			modalRef={modalRef}
			onClickOutside={onClickOutside}
			size='sm'
			groupSelect={groupSelect}
			onClick={onClick}
			onToggle={onToggle}
			showCaret={showCaret}
			{...props}
		>
			<TitleAndLink hidden={hideTitleLink} showChevron={showChevron} {...props} />
		</DropdownBase>
	</StyledDropdown>
);

export default Dropdown;

// custom toggle is needed because reactstrap just throw out toggle state when
// menu is portal-ed out (likely because old version issue). Custom toggle allow
// us to ignore reactstrap toggle and handle the toggle ourself.
const withCustomToggle = Component => ({ onToggle, show, ...props }) => {
	const handleClickOutside = useMemo(() => {
			if (show) {
				return onToggle;
			}
		}, [onToggle, show])
		;
	return (
		<Component
			onClick={onToggle}
			onClickOutside={handleClickOutside}
			onToggle={DummyFunction}
			show={show}
			{...props}
		/>
	);
};

const CustomToggleDropdown = branch(
	({ modalRef }) => !!modalRef
	, withCustomToggle
)(Dropdown);

const isNoSingleSelect = selected => selected === UNSELECT
const isEmptyMultiSelection = selected => !selected || !selected.length

export class AreaDropdown extends PureComponent {
	constructor(props) {
		super(props);
		this.handleSelect = this.handleSelect.bind(this);
		this.handleToggle = this.handleToggle.bind(this);
		this.handleSelectAll = this.handleSelectAll.bind(this);
		this.handleSelectNone = this.handleSelectNone.bind(this);
	}
	handleSelectAll(){
		const p = this.props;
		let data = p.data, ids = [];
		let id = p.idFields ? p.idFields.id : "id";
		if(p.nested){
			$.each(data, (i,val) => {
				let subs = val[p.nested.key];
				$.each(subs, (n, v) => {
					ids.push(dataFieldValue(v, id));
				});
			});
		}else{
			$.each(data, (i,val) => {
				ids.push(dataFieldValue(val, id));
			});
		}
		this.props.onSelect(ids);
	}
	handleSelectNone(){
		this.props.onSelect([]);
	}
	handleSelect(index, data, toggle, datas, selectOnlyOne) {
		const p = this.props;
		if(!p.multiSelect) {
			if(!data.isHeader) {
				p.onSelect(data.id);
			}
		} else {
			if (!data.isHeader) {
				if (selectOnlyOne) {
					p.onSelect([data.id])
				} else {
					let found, index
					each(p.selected, (v, i) => {
						if(v === data.id) {
							found = true;
							index = i
							return false;
						}
					})
					if (!found) {
						if (!toggle) {
							p.onSelect(update(p.selected, {$push: [data.id]}))
						}
					} else {
						if (toggle) {
							p.onSelect(update(p.selected, {$splice: [[index, 1]]}))
						}
					}
				}
			} else if(p.groupSelect) {
				let ids = [];
				let totalChild = 0, totalChildPushed = 0;
				if(data.id){
					$.each(datas, (i, v) => {
						if(data.id == parseInt(v.parent, 10)){
							totalChild ++; //count each child matching parent
							if(!p.selected.includes(v.id)){ //if not existing yet, then add
								ids.push(v.id);
								totalChildPushed ++;
							}
						}
					});
					if(totalChildPushed === 0){
						$.each(datas, (i, v) => {
							if(data.id == parseInt(v.parent, 10)){
								for( var i = 0; i < p.selected.length; i++){
									if ( p.selected[i] === v.id) {
										p.selected.splice(i, 1);
										i--; //decrement the index variable after splice to avoid any skipping
									}
								}
								ids = p.selected;
								return p.onSelect(update(p.selected, {$set: ids}));
							}
						});
					} else {
						p.onSelect(update(p.selected, {$push: ids}));
					}
				}
			}
		}
	}
	handleToggle() {
		const {id, show} = this.props;
		this.props.onToggle(id, show);
	}
	getNested(src, dst, parent, isHeader, findSelected) {
		if(src) {
			const p = this.props, multiSelect = p.multiSelect,
				idKey = p.idFields.id, nameKey = p.idFields.name;
			$.each(src, (i,v) => {
				const id = dataFieldValue(v, idKey);
				dst.push({
					id
					, name: dataFieldValue(v, nameKey)
					, parent
					, isHeader
					, active:(v.Active !== null ? v.Active : true)
				});
				if(!findSelected.found && id === findSelected.lookFor) {
					if(!multiSelect) {
						findSelected.found = true;
					}
					findSelected.index = findSelected.total;
				}
				findSelected.total++;
			});
		}
	}
	isIdInArr(id, idFieldName, areaArr){
		var found = false;
		$.each(areaArr, (i, v) => {
			if(id == v[idFieldName]){
				found = true;
				return;
			}
		});
		return found;
	}
	render() {
		const {
			children,
			enableArrow,
			flip,
			modalRef,
			onReset,
			readOnly,
			tag,
			...p
		} = this.props
		const findSelected = { lookFor: p.selected, total: 0 }
		let d = []
		let selected
		let title = p.title
		if (!p.notReady) {
			const nested = p.nested
				, idKey = p.idFields.id
				, nameKey = p.idFields.name
				, { keyId, title } = p.idFields
				;
			$.each(p.data, (i,v) =>{
				const id = dataFieldValue(v, idKey)
					, oneData = {
						id
						, name: dataFieldValue(v, nameKey)
						, parent: ''
						, isHeader: !!nested
						, active: (v.Active !== null ? v.Active : true)
					}
					;
				if (keyId) {
					oneData.key = dataFieldValue(v, keyId);
				}
				oneData.title = (title) ? dataFieldValue(v, title) : oneData.name;
				d.push(oneData);
				findSelected.total++;
				if (nested) {
					this.getNested(v[nested.key], d, id+'', false,
						findSelected);
				} else if (!p.multiSelect && !findSelected.found &&
					id === findSelected.lookFor) {
					findSelected.found = true;
					findSelected.index = i;
				} else if(p.forwardAreaInOrg == true){
					if(this.isIdInArr(p.selected, p.idFields.id,
						v[p.forwardAreaInOrgKey])){
						this.getNested(v[p.forwardAreaInOrgKey], d, id+'',
							false, findSelected);
						//remove the org
						d.shift();
						//getNested counts the index starting from the org
						//if org is not there, we need to reduce index count
						//by 1
						if(findSelected.found == true &&
							findSelected.index > 0){
							findSelected.index--;
						}
					} else {
						d.pop();
						findSelected.total--;
					}
				}
			});
		}
		if(!p.multiSelect) {
			if(findSelected.found) {
				selected = findSelected.index;
			} else {
				selected = -1;
			}
		} else {
			// array of selected id
			selected = p.selected;
			if(p.overrideTitle){
				if(selected.length > 0){
					title = "";
				}
			}
		}
		let qaId = "dd-serialSelect";
		if(p["data-qa-id"]){
			qaId = p["data-qa-id"];
		}else{
			qaId += "-"+title;
		}
		return (
			<CustomToggleDropdown
				children={children}
				className={p.className}
				clickEventReturnCurrentSelected={p.clickEventReturnCurrentSelected}
				data={d}
				data-qa-id={qaId}
				enableArrow={enableArrow}
				flip={flip}
				groupSelect={p.groupSelect}
				headerText={p.headerText}
				id={p.id}
				isHeader={p.isHeader}
				isInitializedSelection={p.multiSelect ? isEmptyMultiSelection : isNoSingleSelect}
				modalRef={modalRef}
				multiSelect={p.multiSelect}
				nested={p.nested}
				notReady={p.notReady}
				onReset={onReset}
				onSelect={this.handleSelect}
				onSelectAll={this.handleSelectAll}
				onSelectNone={this.handleSelectNone}
				onToggle={this.handleToggle}
				readOnly={readOnly}
				selectAll={p.selectAll}
				selectNone={p.selectNone}
				selected={selected}
				show={p.show}
				showChevron={p.showChevron}
				staticHeaderText={p.staticHeaderText}
				showCaret={p.showCaret}
				splitTitleAndLink={p.splitTitleAndLink}
				tag={tag}
				textNoItemSelected={p.textNoItemSelected}
				title={title}
				menuClassName={p.menuClassName}
				modifiers={p.modifiers}
				hideTitleLink={p.hideTitleLink}
				disabled={p.disabled}
				withFavourite={p.withFavourite}
				favouriteList={p.favouriteList}
				onClickFavourite={p.onClickFavourite}
			/>
		);
	}
}

const addIdToArray = (id, array) => update(array, { $pushx: [id] })

const removeIdFromArray = (id, array) => {
	const result = {}
	findIdFromArray(result, id, array)
	if (!result.found) {
		return array
	}
	return update(array, { $splice: [[result.index, 1]] })
}

const addOrRemoveIdOfArray = (remove, id, array) => {
	if (remove) {
		return removeIdFromArray(id, array)
	}
	return addIdToArray(id, array)
}

const addIdsToArray = (ids, array) => {
	const idMap = {}
	const newArray = []
	if (array) {
		each(array, id => {
			newArray.push(id)
			idMap[id] = true
		})
	}
	each(ids, id => {
		if (!idMap[id]) {
			newArray.push(id)
		}
	})
	if ((!array && !newArray.length) ||
		(array && newArray.length === array.length)) {
		return array
	}
	return newArray
}

const removeIdsFromArray = (ids, array) => {
	if (!array) {
		return array
	}
	const idMap = {}
	each(ids, id => {
		idMap[id] = true
	})
	const newArray = []
	each(array, id => {
		if (!idMap[id]) {
			newArray.push(id)
		}
	})
	if (newArray.length === array.length) {
		return array
	}
	return newArray
}

const addOrRemoveIdsOfArray = (remove, ids, array) => {
	if (remove) {
		return removeIdsFromArray(ids, array)
	}
	return addIdsToArray(ids, array)
}

const selectGroupElementsBase = (
	data,
	id,
	onSelect,
	toggle,
	norms,
	normKey,
	selected,
	{ key: subNormKey, allIds: nestedAllIds }
) => {
	if (norms[subNormKey] &&
		!norms[subNormKey].groupOnly &&
		nestedAllIds &&
		nestedAllIds.length
	) {
		onSelect(
			addOrRemoveIdsOfArray(toggle, nestedAllIds, selected[subNormKey]),
			subNormKey
		)
		// TODO: recursive all sub
	}
}

const selectGroupElements = (
	data,
	id,
	onSelect,
	toggle,
	norms,
	normKey,
	selected,
	sub
) => selectGroupElementsBase(
	data,
	id,
	onSelect,
	toggle,
	norms,
	normKey,
	selected,
	subNormKeyAndAllIds(sub, data, id, norms, normKey)
)

const useNormSelect = (
	data,
	norms,
	onSelect,
	selected,
	multiSelect
) => useCallback(
	(
		normKey,
		id,
		parentNormKey,
		parentId,
		_,
		toggle,
		selectFromText
	) => {
		const { groupOnly, parent, sub } = norms[normKey]
		if (!groupOnly && !sub && !toggle && selectFromText) {
			onSelect(multiSelect ? [id] : id, normKey)
			if (parent && !norms[parent].groupOnly) {
				onSelect(multiSelect ? emptyArray : '', parent)
				// TODO: recursive all grand-parents
			}
		} else {
			if (!groupOnly) {
				onSelect(
					multiSelect ? addOrRemoveIdOfArray(toggle, id, selected[normKey]) : id,
					normKey
				)
			}
			if (multiSelect && sub && (groupOnly || selectFromText)) {
				selectGroupElements(
					data,
					id,
					onSelect,
					toggle,
					norms,
					normKey,
					selected,
					sub
				)
			}
		}
	},
	[data, norms, onSelect, selected]
)

const normSelect = (
	selection,
	seen,
	onSelect,
	norms,
	normKey
) => {
	if (seen[normKey]) {
		return
	}
	const { parent, groupOnly, sub } = norms[normKey]
	if (parent && !seen[parent]) {
		// always start from parent
		normSelect(selection, seen, onSelect, norms, parent)
		if (seen[normKey]) {
			return
		}
	}
	if (!groupOnly) {
		onSelect(selection(normKey), normKey)
	}
	seen[normKey] = true
	if (sub && sub.key) {
		normSelect(selection, seen, onSelect, norms, sub.key)
	}
}

const normAllIds = normData => {
	if (!normData || !normData.allIds || !normData.allIds.length) {
		return emptyArray
	}
 	return normData.allIds
}

const createNormSelectAll = data => (...args) => normSelect(
	normKey => normAllIds(data[normKey]),
	...args
)

const normSelectNone = (...args) => normSelect(() => emptyArray, ...args)

const isEmptyNormSelection = selected => {
	let notEmpty
	each(selected, select => {
		if (select && select.length) {
			notEmpty = true
			return false
		}
	})
	return !notEmpty
}

const isInitialNoneSingleSelectDatabaseId = selected => !selected || selected <= 0

const useInitialedSelection = (multiSelect, def) => useMemo(
	() => {
		if (typeof def === 'function') {
			return def
		} else if (multiSelect) {
			return isEmptyNormSelection
		}
		return isInitialNoneSingleSelectDatabaseId
	},
	[multiSelect, def]
)

// NormDropdown provides general dropdown with normalized data format (eg, byId
// and allIds). The dropdown can be grouped and also be multi or single select.
// So far, only support one level of grouping eg:
//   Group 1
//     Element 1
//     Element 2
//     ...etc
//   Group 2
//    Element 1
//   ...etc
// 'norms' is the main property to control how the 'data' format look like.
export const NormDropdown = ({
	areas,
	children,
	className,
	clickEventReturnCurrentSelected,
	data,
	'data-qa-id': dataQAId,
	enableArrow,
	flip,
	groupSelect,
	headerText,
	id,
	idFields,
	isInitializedSelection,
	menuClassName,
	modalRef,
	multiSelect,
	norms,
	notReady,
	onSelect,
	onToggle,
	orgs,
	overrideTitle,
	readOnly,
	selectAll,
	selectNone,
	selected,
	show,
	showCaret,
	showChevron,
	splitTitleAndLink,
	staticHeaderText,
	tag,
	textNoItemSelected,
	title
}) => (
	<CustomToggleDropdown
		children={children}
		className={className}
		clickEventReturnCurrentSelected={clickEventReturnCurrentSelected}
		data={data}
		data-qa-id={dataQAId}
		enableArrow={enableArrow}
		flip={flip}
		groupSelect={groupSelect}
		headerText={headerText}
		id={id}
		idFields={idFields}
		isHeader={false}
		isInitializedSelection={useInitialedSelection(
			multiSelect,
			isInitializedSelection
		)}
		menuClassName={menuClassName}
		modalRef={modalRef}
		multiSelect={multiSelect}
		norms={norms}
		notReady={notReady}
		onSelect={useNormSelect(data, norms, onSelect, selected, multiSelect)}
		onSelectAll={useMemo(
			() => {
				if (selectAll) {
					return () => {
						if (!norms) {
							return
						}
						const normSelectAll = createNormSelectAll(data)
						const seen = {}
						each(norms, (_, key) => {
							normSelectAll(seen, onSelect, norms, key)
						})
					}
				}
			},
			[data, norms, onSelect, selectAll]
		)}
		onSelectNone={useMemo(
			() => {
				if (selectNone) {
					return () => {
						if (!norms) {
							return
						}
						const seen = {}
						each(norms, (_, key) => {
							normSelectNone(seen, onSelect, norms, key)
						})
					}
				}
			},
			[norms, onSelect, selectNone]
		)}
		onToggle={onToggle}
		readOnly={readOnly}
		selectAll={selectAll}
		selectNone={selectNone}
		selected={selected}
		show={show}
		showCaret={showCaret}
		showChevron={showChevron}
		splitTitleAndLink={splitTitleAndLink}
		staticHeaderText={staticHeaderText}
		tag={tag}
		textNoItemSelected={textNoItemSelected}
		title={title}
	/>
)

const propTypeStringOrNumber = PropTypes.oneOfType([PropTypes.string, PropTypes.number])

NormDropdown.propTypes = {
	data: PropTypes.objectOf(PropTypes.oneOfType([
		PropTypes.shape({
			byId: PropTypes.object,
			allIds: PropTypes.arrayOf(propTypeStringOrNumber)
		}),
		// data access mainly control from norms, any redundant (because reuse)
		// field is ok and should not affect dropdown operation.
		PropTypes.any
	])),
	norms: PropTypes.objectOf(PropTypes.shape({
		groupOnly: PropTypes.bool,
		parent: propTypeStringOrNumber,
		sub: PropTypes.oneOfType([
			PropTypes.func,
			PropTypes.shape({
				key: propTypeStringOrNumber,
				nested: PropTypes.oneOfType([
					PropTypes.func,
					PropTypes.string,
					PropTypes.number
				])
			})
		])
	}))
}

const orgAreaNorms = {
	org: {
		sub: { key: 'area', nested: 'areas' }
	},
	area: {
		parent: 'org'
	}
}

const groupOnlyOrgAreaNorms = update(
	orgAreaNorms,
	{ org: { groupOnly: { $set: true } } }
)

const ReactSelectLikedDiv = styled.div`
	-webkit-align-items: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	// background-color: hsl(0,0%,${({ disabled }) => disabled ? '95%' : '100%'});
	background-color: ${({ disabled }) => disabled ? tintLightGray : 'inherit'};
	border-color: ${tintDarkGrey};
	border-radius: ${inputBorderRadius};
	border-style: solid;
	border-width: 1px;
	cursor: ${({ disabled }) => disabled ? 'default' : 'pointer'};
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	min-height: ${inputMinHeight};
	outline: 0 !important;
	position: relative;
	-webkit-transition: all 100ms;
	transition: all 100ms;
	box-sizing: border-box;
	${({ menuOpened }) => menuOpened ? focusedBorderStyled : ''}
	.value-container {
		-webkit-align-items: center;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-flex: 1;
		-ms-flex: 1;
		flex: 1;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		padding: 2px 8px;
		-webkit-overflow-scrolling: touch;
		position: relative;
		overflow: hidden;
		box-sizing: border-box;
		.value {
			border-radius: ${inputBorderRadius};
			color: inherit;
			font-size: ${fontSizeDropdown};
			overflow: hidden;
			padding: 3px;
			padding-left: 6px;
			text-overflow: ellipsis;
			white-space: nowrap;
			box-sizing: border-box;
		}
	}
	.indicator-container {
		-webkit-align-items: center;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-align-self: stretch;
		-ms-flex-item-align: stretch;
		align-self: stretch;
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-flex-shrink: 0;
		-ms-flex-negative: 0;
		flex-shrink: 0;
		box-sizing: border-box;
		.arrow {
			color: hsl(0,0%,80%);
			display: -webkit-box;
			display: -webkit-flex;
			display: -ms-flexbox;
			display: flex;
			padding: 5px;
			-webkit-transition: color 150ms;
			transition: color 150ms;
			box-sizing: border-box;
			cursor: pointer;
		}
	}
`
const ArrowIcon = ({ oldVersion, up }) => (
	<i
		className={classNames(
			{ fas: !oldVersion, fa: oldVersion },
			{ 'fa-caret-down': !up, 'fa-caret-up': up }
		)}
	/>
)

const totalTwoArraysSize = (a, b) => {
	let total = 0
	if (a && a.length) {
		total = a.length
	}
	if (b && b.length) {
		total += b.length
	}
	return total
}

const ReactSelectLikedInput = ({ active, disabled, oldVersion, text }) => (
	<ReactSelectLikedDiv disabled={disabled} menuOpened={active}>
		<div className='value-container'>
			<div className='value'>{text}</div>
		</div>
		<div className='indicator-container'>
			<div aria-hidden="true" className='arrow'>
				<ArrowIcon oldVersion={oldVersion} up={active} />
			</div>
		</div>
	</ReactSelectLikedDiv>
)

export const useCountAsSelectedText = (norms, data, selected) => useMemo(() => {
	let total = 0
	let totalSelected = 0
	each(norms, ({ groupOnly }, key) => {
		if (!groupOnly) {
			const { allIds } = data[key]
			const _selected = selected[key]
			total += allIds ? allIds.length : 0
			totalSelected += _selected ? _selected.length : 0
		}
	})
	if (totalSelected === total) {
		return TXT_ALL
	}
	return TXT_NUMBER_SELECTED.replace('{NUMBER}', totalSelected)
}, [data, norms, selected])

export const NormSelectDropdown = ({ oldVersion, selectedText, ...props }) => {
	const [show, onToggle] = useToggle()
	return (
		<NormDropdown onToggle={onToggle} show={show} tag='div' {...props}>
			<ReactSelectLikedInput
				active={show}
				oldVersion={oldVersion}
				text={selectedText}
			/>
		</NormDropdown>
	)
}

const NormOrgAreaDropdown = withProps({
	textNoItemSelected: TXT_SELECT_ORGS_AREAS
})(NormSelectDropdown)

const getNoSelectionText = (text, def) => {
	if (text) {
		return text
	}
	return TXT_NO_SELECTION
}

export const AreaNormSingleSelect = ({
	idFields = idNameSmall,
	selected,
	textNoItemSelected,
	...props
}) => {
	const { data: { area: { byId } } } = props
	const { name } = idFields
	return (
		<NormOrgAreaDropdown
			idFields={idFields}
			isInitializedSelection={isInitialNoneSingleSelectDatabaseId}
			norms={groupOnlyOrgAreaNorms}
			selected={selected}
			selectedText={useMemo(
				() => {
					if (isInitialNoneSingleSelectDatabaseId(selected)) {
						return getNoSelectionText(textNoItemSelected, TXT_NO_SELECTION)
					}
					const selectedData = byId[selected]
					if (typeof selectedData === 'undefined') {
						return getNoSelectionText(textNoItemSelected, TXT_NO_SELECTION)
					}
					return dataFieldValue(selectedData, name)
				},
				[byId, name, selected, textNoItemSelected]
			)}
			textNoItemSelected={textNoItemSelected}
			{...props}
		/>
	)
}

AreaNormSingleSelect.propTypes = {
	...NormDropdown.propTypes,
	selected: propTypeStringOrNumber
}

// OrgAreaDropdown use normalized orgArea data format. If orgs isn't 'undefined'
// then organisation can be selected as well and not just group select.
export const OrgAreaDropdown = ({ areas, orgs, ...props }) => {
	const orgIsGroupOnly = typeof orgs === 'undefined'
	const selected = useMemo(
		() => {
			const result = { area: areas }
			if (!orgIsGroupOnly) {
				result.org = orgs
			}
			return result
		},
		[areas, orgs, orgIsGroupOnly]
	)
	if(typeof props.data === 'undefined') {
		return null
	}
	const { data: { area: { allIds }, org: { allIds: orgIds } } } = props
	const selectedText = useMemo(() => {
		const selectedTotal = totalTwoArraysSize(
			areas,
			orgIsGroupOnly ? false : orgs
		)
		if (selectedTotal < totalTwoArraysSize(allIds, orgIsGroupOnly ? false : orgIds)) {
			return TXT_NUMBER_SELECTED.replace('{NUMBER}', selectedTotal)
		}
		return TXT_ALL
	}, [allIds, areas, orgs, orgIds, orgIsGroupOnly])
	return (
		<NormOrgAreaDropdown
			groupSelect={true}
			multiSelect={true}
			norms={orgIsGroupOnly ? groupOnlyOrgAreaNorms : orgAreaNorms}
			selectAll={true}
			selectNone={true}
			selected={selected}
			selectedText={selectedText}
			{...props}
		/>
	)
}

OrgAreaDropdown.propTypes = {
	...NormDropdown.propTypes,
	selected: PropTypes.objectOf(PropTypes.arrayOf(propTypeStringOrNumber))
}

const oldMenuActionPadding = `
	.multiselect-btn {
		padding: 15px 0px 15px 0px;
	}
`
const StyledV5DropdownMenu = styled.div`
	&.v5-dropdown {
		.c3-dropdown {
			.CheckUncheckAll {
				min-width: 200px;
			}
			.dropdown-menu {
				text-decoration: none;
				a, button {
					.dropdown-item {
						padding: 7px 20px;
						outline: none;
						border-bottom: none;
					}
				}
				${dropdownMenuActionStyle}
				${() => newDropdownUI ? '' : oldMenuActionPadding}
			}
		}
	}
`
const V5MultiSelectDropdownBase = ({
	multiSelect,
	groupSelect,
	id,
	selectAll,
	selectNone,
	...props
}) => (
	<StyledV5DropdownMenu className="v5-dropdown">
		<AreaDropdown
			id={id}
			multiSelect={multiSelect}
			groupSelect={groupSelect}
			selectAll={selectAll}
			selectNone={selectNone}
			{...props}
		/>
	</StyledV5DropdownMenu>
)

export const V5MultiSelectDropdown = memo(V5MultiSelectDropdownBase)

const PoorSelect = ({multiSelect, groupSelect, id, ...props}) => <V5MultiSelectDropdown id={id} multiSelect={multiSelect} groupSelect={groupSelect} {...props} />;

class SingleOrMultiSelect extends PureComponent {
	constructor(props) {
		super(props);
		this.handleSelect = this.handleSelect.bind(this);
	}
	handleSelect(datas) {
		if (this.props.multiSelect) {
			this.props.onSelect(datas.join(','));
		} else {
			this.props.onSelect(datas);
		}
	}
	render() {
		const {onSelect, selected, multiSelect, groupSelect, id, ...props} = this.props;
		let processedSelected = [], selectedIDs = [];
		if (!this.props.multiSelect) {
			selectedIDs.push(parseInt(selected, 10))
		} else {
			//When none selected, the selected became [""], which array with length 1,
			//and which cause NaN after parseInt, it should remain []
			if(selected != "") {
				if (typeof selected === "string") {
					processedSelected = selected.split(',');
				} else {
					if(typeof selected !== "undefined" && selected !== null) {
						processedSelected = selected;
					}
				}
			}
			if(processedSelected.length > 0) {
				$.each(processedSelected, (i,v) => {
					selectedIDs.push(parseInt(v, 10));
				});
			}
		}
		return (
			<PoorSelect onSelect={this.handleSelect} multiSelect={multiSelect} groupSelect={groupSelect}
				selected={selectedIDs} id={id} {...props}
			/>
		);
	}
}

export const SerialMultiSelect = props => (
	<SingleOrMultiSelect {...props} multiSelect={true} />
);

export const NoFlipMultiSelect = withProps({ flip: false })(SerialMultiSelect)

export const SingleSelectDD = props => (
	<AreaDropdown {...props} multiSelect={false} />
);

export const isInitialIntegerSelection = selected => {
	if (typeof selected === 'number') {
		return selected < 0
	}
	return !selected
}

const noSelectionText = defaultText => {
	if (defaultText) {
		return defaultText
	}
	return TXT_NO_SELECTION
}

const useSelectedNameNormData = (
	byId,
	valueGetter,
	isInitializedSelection,
	selected,
	defaultText,
	invalidSelectedText
) => useMemo(
	() => {
		if (typeof byId[selected] !== 'undefined') {
			return valueGetter(byId[selected])
		} else if (isInitializedSelection(selected) ||
			typeof invalidSelectedText !== 'function') {
			return noSelectionText(defaultText)
		}
		return invalidSelectedText(selected)
	},
	[
		byId,
		defaultText,
		invalidSelectedText,
		isInitializedSelection,
		selected,
		valueGetter
	]
)

const ActiveBorderHighlightSingleSelect = ({
	byId,
	data,
	disabled,
	idFields,
	invalidSelectedText,
	isInitializedSelection = isInitialNoneSingleSelectDatabaseId,
	oldVersion,
	onSelect,
	textNoItemSelected,
	valueGetter,
	...props
}) => {
	const [show, onToggle] = useToggle()
	return (
		<SingleSelectDD
			data={data}
			idFields={idFields}
			isInitializedSelection={isInitializedSelection}
			onSelect={onSelect}
			onToggle={useDisableableCallback(disabled, onToggle)}
			show={show}
			tag='div'
			{...props}
		>
			<ReactSelectLikedInput
				active={show}
				disabled={disabled}
				oldVersion={oldVersion}
				text={useSelectedNameNormData(
					byId,
					valueGetter,
					isInitializedSelection,
					props.selected,
					textNoItemSelected,
					invalidSelectedText
				)}
			/>
		</SingleSelectDD>
	)
}

const _idValue = { id: '_id', name: 'value' }

// Single select which takes in normalized data and has border highlight when
// menu open. 'valueGetter' is needed to get the name to be displayed on
// dropdown.
export const ActiveBorderHighlightNormSingleSelect = ({
	data: { byId, allIds },
	valueGetter,
	...props
}) => (
	<ActiveBorderHighlightSingleSelect
		byId={byId}
		data={useDenormalize(
			byId,
			allIds,
			useCallback(
				(value, id) => ({ _id: id, value: valueGetter(value) }),
				[valueGetter]
			)
		)}
		idFields={_idValue}
		valueGetter={valueGetter}
		{...props}
	/>
)

const identityValueGetter = { valueGetter: identity }

export const SimpleNormSingleSelect = withProps(identityValueGetter)(ActiveBorderHighlightNormSingleSelect)

const useCreateByIdAndValueGetter = (array, idFields) => {
	const { id, name } = idFields
	return [
		useMemo(
			() => {
				const byId = {}
				each(array, v => { byId[dataFieldValue(v, id)] = v })
				return byId
			},
			[array, id, name]
		),
		useCallback(
			v => dataFieldValue(v, name),
			[name]
		)
	]
}

export const ActiveBorderHighlightArraySingleSelect = ({
	data,
	idFields,
	...props
}) => {
	const [byId, valueGetter] = useCreateByIdAndValueGetter(data, idFields)
	return (
		<ActiveBorderHighlightSingleSelect
			byId={byId}
			data={data}
			idFields={idFields}
			valueGetter={valueGetter}
			{...props}
		/>
	)
}
export const UnnestedAreaSingleSelect = ({
	data,
	idFields,
	nested,
	selected,
	disabled,
	isInitializedSelection = isInitialNoneSingleSelectDatabaseId,
	invalidSelectedText,
	...props
}) => {

	const [show, onToggle] = useToggle()
	const [allArea, setAllarea] = useState([])

	const { id, name, keyId, title } = idFields;
	const idKey = id, nameKey = name;

	const getNested = (src, dst, parent, isHeader) => {
		if(src) {
			$.each(src, (i,v) => {
				const id = dataFieldValue(v, idKey);
				dst.push({
					id
					, name: dataFieldValue(v, nameKey)
					, parent
					, isHeader
					, active:(v.Active !== null ? v.Active : true)
				});
			});
		}
	}
	useEffect(()=> {
		let d = [];
		$.each(data, (i,v) =>{
			const id = dataFieldValue(v, idKey)
				, oneData = {
					id
					, name: dataFieldValue(v, nameKey)
					, parent: ''
					, isHeader: !!nested
					, active: (v.Active !== null ? v.Active : true)
				}
				;
			if (keyId) {
				oneData.key = dataFieldValue(v, keyId);
			}
			oneData.title = (title) ? dataFieldValue(v, title) : oneData.name;
			d.push(oneData);
			if (nested) {
				getNested(v[nested.key], d, id+'', false);
			}
			setAllarea(d);
		});
	},[data])

	const mapSelectedId = (d) => {
		let txt = invalidSelectedText;
		$.each(d, (i,v) =>{
			if (v.id === selected) {
				return txt = v.name;
			}
		});
		return txt
	}

	const memoizedmapSelectedId = useMemo(() => mapSelectedId(allArea),[selected, allArea]);

	const valueGetter = useCallback(() => memoizedmapSelectedId,[selected, allArea]);

	return (
		<SingleSelectDD
			data={data}
			idFields={idFields}
			isInitializedSelection={isInitializedSelection}
			onToggle={useDisableableCallback(disabled, onToggle)}
			show={show}
			tag='div'
			nested={nested}
			selected={selected}
			invalidSelectedText={invalidSelectedText}
			{...props}
		>
			<ReactSelectLikedInput
				active={show}
				disabled={disabled}
				text={valueGetter()}
			/>
		</SingleSelectDD>
	)
}

const idValue = { id: 'id', name: 'value' }

export const IdValueSingleSelect = withProps({ idFields: idValue })(ActiveBorderHighlightArraySingleSelect)

export const PlainArraySingleSelect = ({ data, ...props }) => (
	<IdValueSingleSelect data={useArrayToArrayObject(data, idValue)} {...props} />
)

const idName = { id: 'id', name: 'name' };

class OrderSelect extends PureComponent {
	constructor(props) {
		super(props);
	}
	render() {
		const { data: d, idFields, ...props } = this.props;
		let data = [];
		$.each(d.order, (i,v) => {
			data.push({id: v, name: d[v]});
		});
		return <AreaDropdown {...props} idFields={idName} data={data} />;
	}
}

OrderSelect.propTypes = {
	data: PropTypes.shape({
		order: PropTypes.arrayOf(PropTypes.number).isRequired
	})
	, show: PropTypes.bool
	, selected: PropTypes.number.isRequired
	, onSelect: PropTypes.func
	, onToggle: PropTypes.func
};

export const OrderSingleSelect = ({multiSelect, ...props}) => (
	<OrderSelect {...props} multiSelect={false} />
);

export const OrderMultiSelect = ({multiSelect, ...props}) => (
	<OrderSelect {...props} multiSelect={true} />
);

const ActionDDMenuHeaderBase = ({ text }) => (
	<StyledHeaderItem>{text}</StyledHeaderItem>
)

const ActionDDMenuHeader = withUnmountWhenHidden(ActionDDMenuHeaderBase);

const ListWrapper = ({ children, withWrapper }) => {
	if (withWrapper) {
		return (
			<div className="dd-list-container">
				{children}
			</div>
		)
	}
	return children
}

const ActionDropdownMenu = ({
	children,
	hasHeader,
	headerText,
	withWrapper
}) => (
	<Fragment>
		<ActionDDMenuHeader hidden={!hasHeader || !headerText} text={headerText} />
		<ListWrapper withWrapper={withWrapper}>{children}</ListWrapper>
	</Fragment>
)

const OldActionDDMenuItems = ({
	children,
	hasHeader,
	headerText,
	menuClassName
}) => (
	<div className={menuClassName}>
		<ActionDropdownMenu
			hasHeader={hasHeader}
			headerText={headerText}
			withWrapper={true}
		>
			{children}
		</ActionDropdownMenu>
	</div>
)

const withOldActionDropdownMenuContent = Component => props => (
	<Component>
		<OldActionDDMenuItems {...props} />
	</Component>
)

const ActionDDMenuItems = branch(
	() => newDropdownUI,
	withMenuClassName,
	withOldActionDropdownMenuContent
)(FertileMenu)

const withLoadingCover = Component => ({ loading, ...props }) => {
	if (loading) {
		return (
			<ActionDDMenuItems {...props}>
				<DropdownItem>
					<Spinner />&nbsp;{I("Loading dropdown data")}
				</DropdownItem>
			</ActionDDMenuItems>
		);
	}
	return <Component {...props} />;
};

const idValueFields = { id: 'id', value: 'value' }

const ActionDDMenu = ({ data, onSelectItem, ...props }) => {
	let ddItem = null
	if (data && data.length) {
		ddItem = data.map(v => {
			const { id, value, index, title } = getIdValueIndexTitle(idValueFields, v)
			return (
				<SingleItem
					id={id}
					index={index}
					key={id}
					title={title}
					value={value}
					onClick={onSelectItem}
				/>
			)
		})
	}
	return (
		<ActionDDMenuItems data={data} {...props}>
			{ddItem}
		</ActionDDMenuItems>
	);
}

const ActionDDMenu2 = ({ data, onSelectItem, ...props }) => {
	let ddItem = null
	if (data && data.length) {
		ddItem = data.map(v => {
			//console.log(v.src);
			const { id, value, index, title } = getIdValueIndexTitle2(idValueFields, v)
			return (
				<SingleItem
					id={id}
					index={index}
					key={id}
					title={title}
					value={value}
					onClick={onSelectItem}
				/>
			)
		})
	}
	return (
		<ActionDDMenuItems data={data} {...props}>
			{ddItem}
		</ActionDDMenuItems>
	);
}

const withDDHeaderAndToggle = composeWithDisplayName(
	'withDDHeaderAndToggle',
	withDropdownHeaderToggle,
	withLoadingCover
)

const ActionDD = withDDHeaderAndToggle(ActionDDMenu);
const ActionDD2 = withDDHeaderAndToggle(ActionDDMenu2);


const useToggleWithIdAndShow = (id, onToggle, show) => useCallback(
	() => onToggle(id, show),
	[id, onToggle, show]
)

export const ActionDropdown = ({ id, onToggle, show, ...props }) => (
	<ActionDD
		data-qa-id={id}
		onToggle={useToggleWithIdAndShow(id, onToggle, show)}
		show={show}
		{...props}
	/>
)

export const ActionDropdown2 = ({ id, onToggle, show, ...props }) => (
	<ActionDD2
		data-qa-id={id}
		onToggle={useToggleWithIdAndShow(id, onToggle, show)}
		show={show}
		{...props}
	/>
)

const withOldChatDropdownMenuWrapper = Component => ({
	children,
	className,
	hasHeader,
	headerText
}) => (
	<Component className={className}>
		<ActionDropdownMenu hasHeader={hasHeader} headerText={headerText}>
			{children}
		</ActionDropdownMenu>
	</Component>
)

const ChatDropdownMenuWrapper = branch(
	() => newDropdownUI,
	withProps({ dynamicData: true }),
	withOldChatDropdownMenuWrapper
)(FertileMenu)

const ChatDropdownMenu = ({
	className,
	data,
	enableArrow,
	flip,
	hasHeader,
	headerText,
	idFields,
	noItemText,
	onClick,
	progress,
	ready,
	...p
}) => {
	let ddItem = null
	if (typeof noItemText === 'string') {
		headerText = noItemText
	} else if (!ready) {
		ddItem = (
			<SingleItem
				className='dropdown-item-unclikable'
				id={0}
				index={0}
				title={progress}
				value={progress}
			/>
		)
	} else {
		ddItem = data.map(v => {
			const { id, value, index, title } = getIdValueIndexTitle(idFields, v)
			return (
				<SingleItem
					id={id}
					index={index}
					key={id}
					title={title}
					value={value}
					onClick={onClick}
					{...p}
				/>
			)
		})
	}
	return (
		<ChatDropdownMenuWrapper
			className={className}
			data={data}
			enableArrow={enableArrow}
			flip={flip}
			hasHeader={hasHeader}
			headerText={headerText}
		>
			{ddItem}
		</ChatDropdownMenuWrapper>
	)
}

const useToggleAndPostActionIfAlive = (
	dead,
	onToggle,
	postAction,
	visible
) => useCallback(
	() => {
		onToggle()
		if (visible && !dead) {
			postAction()
		}
	},
	[dead, onToggle, postAction, visible]
)

const useToggleAndPostAction = (
	dead,
	id,
	onToggle,
	postAction,
	show
) => useToggleAndPostActionIfAlive(
	dead,
	useToggleWithIdAndShow(id, onToggle, show),
	postAction,
	!show
)

const chatDeadText = I("Forwarding of finished chat is not supported")

const useCheckChatDeadClick = (dead, onClick) => useCallback(
	(...args) => {
		if (dead) {
			alert(chatDeadText)
			return
		}
		onClick(...args)
	},
	[dead, onClick]
)

const noItemTextWhenDead = dead => {
	if (dead) {
		return I('Chat ended.')
	}
}

const ChatDropdown = composeWithDisplayName(
	'ChatDropdown',
	memo,
	renameProp('id', 'data-qa-id'),
	withDropdownHeaderToggle,
	mapProps(({ dead, ...props }) => ({
		noItemText: noItemTextWhenDead(dead),
		...props
	}))
)(ChatDropdownMenu)

const ChatDropdownFav = composeWithDisplayName(
	'ChatDropdownFav',
	memo,
	renameProp('id', 'data-qa-id'),
	mapProps(({ dead, ...props }) => ({
		noItemText: noItemTextWhenDead(dead),
		...props
	}))
)(ChatDropdownMenu)

const withUnmountAndBusy = composeWithDisplayName(
	'withUnmountAndBusy',
	createWithMountWhenPropTrue('chat'),
	branch(({ forwarding }) => forwarding, renderComponent(Spinner)),
	memo
)

const fieldsIdName = { id: 'Id', value: 'Name' }

const ChatAreaDropdownBase = ({
	areas,
	chat,
	errandListChat,
	forwardToArea,
	getAreaCandidates,
	id,
	listView,
	onToggle,
	show,
	...props
}) => {
	const { dead, errand: { data: { area, id: eid } }, sessionId } = chat
	const postAction = useCallback(
		() => getAreaCandidates(area, sessionId),
		[area, getAreaCandidates, sessionId]
	)
	const handleClick = useCallback(areaId => {
		let areaName = ""
		each(areas, v => {
			if (v[fieldsIdName.id] == areaId) {
				areaName = v[fieldsIdName.value]
				return false
			}
		})
		forwardToArea(sessionId, eid, areaId, areaName, errandListChat, listView)
	}, [areas, errandListChat, forwardToArea, listView, sessionId])
	let renderer = (
		<ChatDropdown
			data={areas}
			dead={dead}
			id={id}
			idFields={fieldsIdName}
			onClick={useCheckChatDeadClick(dead, handleClick)}
			onToggle={useToggleAndPostAction(dead, id, onToggle, postAction, show)}
			show={show}
			{...props}
		/>
	)

	if(props.withFavourite){
		const favs = props.favouriteList;
		let areaWithFavouritesOnly = [];

		if(areas && favs) {
			areaWithFavouritesOnly = areas.filter(item => favs.includes(item.Id));
		}

		renderer =
		<DropdownWithFavourite
			title={<i className="icon-arrow-right"/>}
			titleRegular={props.listTitle}
			titleFavourite={I("Favourites")}
			tooltip={(hide)=>
				<StandardTooltip hide={hide} text={I("Forward to area")} />
			}
			enableToggleArrow={true}
			dataQAId={"forwardArea-chat"}
			onToggle={useToggleAndPostAction(dead, id, onToggle, postAction, show)}
			onSelect={useCheckChatDeadClick(dead, handleClick)}
			onToggleFavAccordian={props.onToggleFavAccordian}
			onToggleRegAccordian={props.onToggleRegAccordian}
			favAccordianOpen = {props.favAccordianOpen}
			regAccordianOpen = {props.regAccordianOpen}
			favouriteList={areaWithFavouritesOnly}
			favouriteDD={
				<ChatDropdownFav
					data={areaWithFavouritesOnly}
					dead={dead}
					id={id}
					idFields={fieldsIdName}
					onClick={useCheckChatDeadClick(dead, handleClick)}
					show={true}
					{...props}
					isHeader={false}//place last to prevent override
				/>
			}
			regularDD={
				<ChatDropdownFav
					data={areas}
					dead={dead}
					id={id}
					idFields={fieldsIdName}
					onClick={useCheckChatDeadClick(dead, handleClick)}
					show={true}
					{...props}
					isHeader={false} //place last to prevent override
				/>
			}
		/>
	}

	return renderer
}

// TODO: there is a bug here where after forward area, the live chat list did
// not update chat's area. If agent shutdown chat view and open the chat view
// again the old area will be there. The dropdown area list also not update
// which likely related to the same issue.
export const ChatAreaDropdown = composeWithDisplayName(
	'ChatAreaDropdown',
	withUnmountAndBusy
)(ChatAreaDropdownBase)

const ChatAgentDropdownBase = ({
	agents,
	chat,
	errandListChat,
	forwardToAgent,
	getAgentCandidates,
	id,
	listView,
	onToggle,
	show,
	...props
}) => {
	const { dead, errand: { id: errandId }, sessionId } = chat
	const postAction = useCallback(
		() => getAgentCandidates(sessionId),
		[getAgentCandidates, sessionId]
	)
	const handleClick = useCallback(
		agentId => forwardToAgent(
			sessionId,
			agentId,
			errandListChat,
			listView,
			errandId
		),
		[agents, errandListChat, forwardToAgent, listView, sessionId, errandId]
	)
	return (
		<ChatDropdown
			data={agents}
			dead={dead}
			id={id}
			idFields={idValueFields}
			onClick={useCheckChatDeadClick(dead, handleClick)}
			onToggle={useToggleAndPostAction(dead, id, onToggle, postAction, show)}
			show={show}
			{...props}
		/>
	)
}

export const ChatAgentDropdown = composeWithDisplayName(
	'ChatAgentDropdown',
	withUnmountAndBusy
)(ChatAgentDropdownBase)

export class SortFilterDropdown extends PureComponent {
	constructor(props){
		super(props);
		this.toggle = this.toggle.bind(this);
		this.handleSortDir = this.handleSortDir.bind(this);
		this.handleSortAttr = this.handleSortAttr.bind(this);

		this.state = {
			dropdownOpen: false
		};
	}

	toggle() {
		this.setState({
			dropdownOpen: !this.state.dropdownOpen
		});
	}

	handleSortAttr(AttrId){
		this.props.handleSortAttribute(AttrId);
	}

	handleSortDir(DirId){
		this.props.handleSortDirection(DirId);
	}

	render() {

		const {
			currentSortAttribute
			,currentSortDirection
			,dataSortAttributes
			,dataSortDirections
		} = this.props
		;

		let show, sortBySelectedText, sortByTitle,  ddSortAttribute = "", ddSortDirection = "";
		show = this.state.dropdownOpen;

		ddSortAttribute = dataSortAttributes.map((v)=>{
			let selectedAttr = false;
			if(v.id === currentSortAttribute){
				selectedAttr = true;
				sortBySelectedText = L(v.name);
			}
			return (
				<OneItem key={v.id} children={L(v.name)} index={v.id} me={"sortFilter-"+v.name} onClick={this.handleSortAttr} selected={selectedAttr}/>
			);
		});

		ddSortDirection = dataSortDirections.map((v)=>{
			let selectedDir = false;
			if(v.id === currentSortDirection){
				selectedDir = true;
			}
			return (
				<OneItem key={v.id} children={L(v.name)} index={v.id} me={"sortFilter-"+v.name} onClick={this.handleSortDir} selected={selectedDir}/>
			)
		});
		sortByTitle = (
			<div>{I('Sort by')}&nbsp;
				<span className="dynamic-title">{L(sortBySelectedText)}</span>
				<i className={getChevronIcon(show)} />
			</div>
		);

		return (
			<BSDropdown className={"search-single-dropdown-sorting"} isOpen={this.state.dropdownOpen} toggle={this.toggle} size="lg">
				<DropdownToggle tag="span"
					className={"search-single-dropdown-text"}
					onClick={this.toggle}
					data-toggle="dropdown"
					aria-expanded={this.state.dropdownOpen}
				>
				{sortByTitle}
				</DropdownToggle>
				<DropdownMenu
					className={"search-single-dropdown-menu"}
				>
					{ddSortAttribute}
					<DropdownItem divider />
					{ddSortDirection}
				</DropdownMenu>
			</BSDropdown>
		)
	}
}

// TODO: still in test, maybe need sort in better function and props
// TODO: useless
class SortAdminTableDropdown extends React.Component {
	constructor(props){
		super(props);
		this.toggle = this.toggle.bind(this);
		this.handleSortDir = this.handleSortDir.bind(this);
		this.handleSortAttr = this.handleSortAttr.bind(this);
		this.state = {
			dropdownOpen: false
		};
	}
	toggle() {
		this.setState({
			dropdownOpen: !this.state.dropdownOpen
		});
	}
	handleSortAttr(AttrId){
		this.props.handleSortAttribute(AttrId);
	}
	handleSortDir(DirId){
		this.props.handleSortDirection(DirId);
	}
	render() {
		const {
			currentSortAttribute
			,currentSortDirection
			,dataSortAttributes
			,dataSortDirections
			,className
		} = this.props
		;
		let show, sortBySelectedText, sortByTitle,  ddSortAttribute = "", ddSortDirection = "";
		show = this.state.dropdownOpen;
		ddSortAttribute = dataSortAttributes.map((v)=>{
			let selectedAttr = false;
			if(v.name === currentSortAttribute){
				selectedAttr = true;
				sortBySelectedText = v.name;
			}
			return (
				<OneItem key={v.id} children={v.name} index={v.name} me={"sortFilter-"+v.name} onClick={this.handleSortAttr} selected={selectedAttr}/>
			);
		});
		sortByTitle = (
			<div>{I('Sort by')}&nbsp;
				<span className="dynamic-title">{sortBySelectedText}</span>
				<i className={getChevronIcon(show)} />
			</div>
		);
		return (
			<BSDropdown className={"single-dropdown-sorting "+className} isOpen={this.state.dropdownOpen} toggle={this.toggle} size="lg">
				<DropdownToggle tag="span"
					className={"search-single-dropdown-text"}
					onClick={this.toggle}
					data-toggle="dropdown"
					aria-expanded={this.state.dropdownOpen}
				>
				{sortByTitle}
				</DropdownToggle>
				<DropdownMenu
					className={"search-single-dropdown-menu"}
				>
					{ddSortAttribute}
					<DropdownItem divider />
					{/* {ddSortDirection} */}
					<OneItem key={"asc"} children={"asc"} index={"asc"} me={"sortFilter-asc"} onClick={this.handleSortDir} selected={currentSortDirection == "asc"}/>
					<OneItem key={"desc"} children={"desc"} index={"desc"} me={"sortFilter-desc"} onClick={this.handleSortDir} selected={currentSortDirection == "desc"}/>
				</DropdownMenu>
			</BSDropdown>
		)
	}
}

const DDContentPreviewBase = ({ className, children, target }) => {
	const [open, onToggle] = useToggle()
	return (
		<Tooltip
			className={classNames("content-preview", className)}
			placement="auto-start"
			autohide={false}
			hideArrow={true}
			isOpen={open}
			target={target}
			toggle={onToggle}
		>
			{children}
		</Tooltip>
	)
}

const DDContentPreview = styled(DDContentPreviewBase)`
	&.content-preview {
		.tooltip {
			z-index: 100001;
			background: #fff;
			border-color: #ccc;
			color: #000;
			box-shadow: 2px 3px 5px 1px ${tintDarkGrey};
			white-space: normal;
			width: max-content;
			min-width: 200px;
			max-width: 400px;
			&.show {
				opacity: 1;
				padding: 0px
			}
			.tooltip-inner {
				// background: #FFF;
				color: #808080;
				padding: 0px;
				max-width: 100%;
				border-radius: unset;
			}
			.content-preview-header {
				padding: 5px;
				background: ${tintDarkGrey};
			}
			.content-preview-inner {
				padding: 5px;
				.content-preview-body {
					text-align: left;
					max-height: 170px;
					overflow: auto;
				}
			}
		}
	}
`
const assistDataQAId = (id, textValue, title) => {
	let dataQAId
	if (id) {
		if (title || textValue) {
			dataQAId = title + "_" + textValue.replace(/\s+/g, '_') + "_" + id
		} else {
			dataQAId = id
		}
	} else {
		dataQAId = title + "_" + textValue
	}
	return 'QA_Assist_' + dataQAId
}

const getContent = (idFields, value) => {
	if (idFields && idFields.content) {
		return dataFieldValue(value, idFields.content)
	}
	return value.content
}

const withItemPreview = Component => ({
	className,
	'data-qa-id': dataQAId,
	id,
	idFields,
	index,
	onClick,
	textValue,
	value
}) => {
	const target = id + '-ddValue' + index // TODO: should carry random string
	return (
		<Component className={className}>
			<span id={target}>{textValue}</span>
			<DDContentPreview id={'DDContentPreview' + index} target={target}>
				<div>
					<div className='content-preview-header'>{textValue}</div>
					<div className='content-preview-inner'>
						<div
							className='content-preview-body'
							dangerouslySetInnerHTML={createMarkup(getContent(
								idFields,
								value
							))}
						/>
					</div>
				</div>
			</DDContentPreview>
			<Anchor
				className='assist-item-preview-add'
				data-qa-id={dataQAId}
				onClick={onClick}
			>
				<i className='fa fa-plus-circle dropdown-add-item' />
			</Anchor>
		</Component>
	)
}

const withOldNoPreviewItem = Component => ({
	className,
	'data-qa-id': dataQAId,
	onClick,
	value
}) => (
	<Component className={className} data-qa-id={dataQAId} onClick={onClick}>
		{value}
	</Component>
)

const withAssistSingleItem = Component => ({
	'data-qa-id': dataQAId,
	itemId,
	onAppendItem,
	onClick,
	textValue,
	value,
	...props
}) => (
	<Component
		data-qa-id={dataQAId}
		onAppendItem={onAppendItem}
		onClick={useMemo(
			() => {
				if (typeof onAppendItem === 'function') {
					return () => onAppendItem(itemId, value)
				}
				return () => onClick(itemId)
			},
			[itemId, onAppendItem, onClick, value]
		)}
		textValue={textValue}
		value={value}
		{...props}
	/>
)

const AssistItem = composeWithDisplayName(
	'AssistItem',
	memo,
	withAssistSingleItem,
	branch(
		({ onAppendItem, appendAction }) => onAppendItem && appendAction,
		withItemPreview,
		compose(
			mapProps(({ textValue, value, ...props }) => ({
				value: textValue,
				...props
			})),
			branch(() => !newDropdownUI, withOldNoPreviewItem)
		)
	),
)(newDropdownUI ? SingleItem : 'li')

const StyledAssistItem = styled(AssistItem)`
	display: flex;
	span {
		display: inline-block;
		margin: 0px;
		overflow: hidden;
		text-overflow: ellipsis;
		width: 80%;
	}
	i {
		&.dropdown-add-item {
			font-size: ${fontSizeDropdownAppendIcon};
		}
	}
`
const withOldMenu = Component => ({
	children,
	className,
	hideReset,
	onReset
}) => (
	<Component>
		<span
			hidden={hideReset}
			className="assist-dropdown-header"
			onClick={onReset}
		>
			{I("Reset")}
		</span>
		<ul className={className}>{children}</ul>
	</Component>
)

const AssistMenuWrapper = branch(
	() => newDropdownUI,
	removeProps(['hideReset']),
	withOldMenu
)(FertileMenu)

const isFalsy = selected => !selected

const useAssistAutoInitialization = (isInitializedSelection, onAppendItem) => {
	if (typeof isInitializedSelection === 'function') {
		return isInitializedSelection
	} else if (onAppendItem) {
		return
	}
	return isFalsy
}

const withAssistMenuProp = Component => ({
	appendAction,
	customClass,
	data,
	ddCustomClass,
	direction,
	disabled = false,
	disallowReset,
	enableArrow,
	flip,
	forceDirectionDown,
	id,
	idFields,
	isInitializedSelection,
	onAppendItem,
	onReset,
	onSelectItem,
	onToggle,
	open,
	selected,
	showToggleArrow,
	title,
	...props
}) => {
	const handleToggle = useCallback(() => {
		if (typeof onToggle === 'function') {
			onToggle(open)
		}
	}, [onToggle, open])
	const handleReset = useMemo(() => {
		if (disallowReset || onAppendItem) {
			return
		}
		return () => {
			onSelectItem(0)
			if (typeof onReset === 'function') {
				onReset()
			}
		}
	}, [disallowReset, onReset, onAppendItem, onSelectItem])
	let noSelection = true
	let ddItem = null
	let selectedTitle = title
	let hideReset = true
	let toggleArrow = null
	if (data && data.length > 0) {
		if (showToggleArrow) {
			toggleArrow = <i className={getChevronIcon(open)} />
		}
		ddItem = data.map((v, i) => {
			const itemId = idFields != null ? dataFieldValue(v, idFields.id) : v.id
			const textValue = idFields != null ? dataFieldValue(v, idFields.value) : v.value
			let isSelected
			if (itemId == selected) {
				isSelected = true
				selectedTitle = textValue
				if (noSelection) {
					noSelection = false
				}
			}
			if (!disallowReset && selected && selected > 0) {
				hideReset = false;
			}
			return (
				<StyledAssistItem
					appendAction={appendAction}
					data-qa-id={assistDataQAId(itemId, textValue, title)}
					id={id}
					idFields={idFields}
					index={i}
					itemId={itemId}
					key={id + '-' + itemId + '-' + i}
					onAppendItem={onAppendItem}
					onClick={onSelectItem}
					selected={isSelected}
					textValue={textValue}
					value={v}
				/>
			)
		})
	}
	return (
		<Component
			disabled={disabled}
			className={ddCustomClass}
			direction={direction}
			inNavbar={forceDirectionDown ? true : false}
			menu={
				<AssistMenuWrapper
					className={classNames('assist-dropdown', customClass)}
					data={data}
					enableArrow={enableArrow}
					flip={flip}
					headerText={title}
					hideReset={hideReset}
					isInitializedSelection={useAssistAutoInitialization(
						isInitializedSelection,
						onAppendItem
					)}
					noSelection={noSelection}
					onReset={handleReset}
					selected={selected}
				>
					{ddItem}
				</AssistMenuWrapper>
			}
			onToggle={handleToggle}
			open={open}
			{...props}
		>
			<Anchor>
				{truncateHtml(selectedTitle, 15)} {toggleArrow}
			</Anchor>
		</Component>
	)
}

const AssistMenuFromProp = ({ menu }) => menu

export const CtrlAssistDropdown = composeWithDisplayName(
	'CtrlAssistDropdown',
	memo,
	withProps(({ open, title }) => {
		if (newDropdownUI) {
			return
		}
		return { 'aria-expanded': open, 'data-toggle': "dropdown" }
	}),
	withProps(({ title }) => ({
		'data-qa-id': "QA_Assist_" + title,
		toggleDataQAId: title
	})),
	withAssistMenuProp,
	renameProp('open', 'show'),
	withDropdownHeaderToggleNoClassName
)(AssistMenuFromProp)

// TODO: useless
export class AssistDropdown extends PureComponent {
	constructor(props) {
		super(props);
		this.toggle = this.toggle.bind(this);
		this.state = {
			dropdownOpen: false
		};
	}
	toggle(openState) {
		this.setState({dropdownOpen: !openState});
	}
	render() {
		return <CtrlAssistDropdown open={this.state.dropdownOpen}
			onToggle={this.toggle} {...props} />;
	}
}

//Dropdown with search and multiselect
export class CustomSearchableDD extends React.Component {
	options = () =>
		options.map((user) => ({
			label: user.label,
			value: user.value
		}));

	customContentRenderer = ({ props, state, methods }) => (
		<div style={{ cursor: "pointer" }}>
			{state.values.length} of {props.options.length} {I("selected")}
		</div>
	);

	customDropdownRenderer = ({ props, state, methods }) => {
		const regexp = new RegExp(state.search, "i");

		return (
			<div className='custom-searchable-dropdown'>
				<div className="SearchAndToggle">
					<div className="Search">
						<div className='SearchLabel'>{I("Search and Select:")}</div>
						{methods.areAllSelected() ? (
							<button type="button" className="Button clear" onClick={methods.clearAll}>
								{I("Clear all")}
							</button>
						) : (
							<button type="button" onClick={methods.selectAll}>{I("Select all")}</button>
						)}
					</div>
					<input
						className='SearchField'
						type="text"
						value={state.search}
						onChange={methods.setSearch}
						placeholder={I("Type name or phone number")}
					/>
				</div>
				<div className="Items">
					{props.options
						.filter((item) => regexp.test(item[props.searchBy] || item[props.labelField]))
						.map((option) => {
							if (!props.keepSelectedInList && methods.isSelected(option)) {
								return null;
							}
							return (
								<div className='Item'
									disabled={option.disabled}
									key={option[props.valueField]}
									onClick={option.disabled ? null : () => methods.addItem(option)}>
									<input
										type="checkbox"
										data-qa-id={"QA_checkbox_" + option[props.valueField]}
										onChange={() => (option.disabled ? undefined : methods.addItem(option))}
										checked={state.values.map(sv => sv.value).indexOf(option.value) !== -1}
									/>
									<div className='ItemLabel' data-qa-id={"QA_label_" + option[props.valueField]}>{option[props.labelField]}</div>
								</div>
							);
						})}
				</div>
			</div>
		);
	};

	render() {
		return (
			<div>
				<Select
					placeholder="Select"
					multi
					contentRenderer={this.customContentRenderer}
					dropdownRenderer={this.customDropdownRenderer}
					onChange={this.props.onChange}
					options={this.props.options}
					values={this.props.optSelected}
					className={"custom-dd"}
				/>
			</div>
		);
	}
}

const DropdownAccordianHeader = ({
	onToggle
	, title
	, icon
}) => {
	return (
		<div className='dropdown-header toggle' onClick={onToggle}>
			<span>{title}</span>
			<i className={icon}></i>
		</div>
	)
}

// dropdown with accordian that takes two passed-in components (favourite, and regular)
// render each in separate accordian body.
// TODO: can be factorized to be more generic

export const DropdownWithFavourite = ({
	title
	, titleRegular
	, titleFavourite
	, tooltip
	, enableToggleArrow
	, onToggle
	, favouriteDD
	, regularDD
	, onSelect
	, favouriteList
	, dataQAId
	, onToggleFavAccordian
	, onToggleRegAccordian
	, favAccordianOpen
	, regAccordianOpen
}) => {

	const [showDD, setShowDD] = useState(false);
	const [showFav, setShowFav] = useState(favAccordianOpen);
	const [showRegular, setShowRegular] = useState(regAccordianOpen);

	const handleToggle = () => {
		toggleState(setShowDD)
		onToggle();
	}

	const toggleState = (stateFunction) => {
		stateFunction((prevState) => !prevState);
	};

	const handleToggleFavAccordian = () => {
		onToggleFavAccordian(!showFav)
		toggleState(setShowFav)
	}
	const handleToggleRegAccordian = () => {
		onToggleRegAccordian(!showRegular)
		toggleState(setShowRegular)
	}

	const selectItemHandler = (a, b, c, d) => {
		onSelect(a, b, c, d);
		setShowDD(!showDD);
	}

	const  iconUp = "icon-chevron-up", iconDown = "icon-chevron-down"

	return (
		<ReactstrapDropdown
			className="dropdown__with-favourites"
			isOpen={showDD}
			toggle={handleToggle}
			data-qa-id={dataQAId}
		>
			<DropdownToggle
				tag="div"
				data-toggle="dropdown"
				aria-expanded={showDD}
				className='dropdown-main-toggler'
			>
				{title}
				{enableToggleArrow && <i className={showDD ? iconUp : iconDown }></i>}
			</DropdownToggle>
			<DropdownMenu>
				<div hidden={!showDD} className='section-wrapper'>
					<div className='fav-section'>
						<DropdownAccordianHeader
							title={titleFavourite}
							onToggle={handleToggleFavAccordian}
							icon={showFav ? iconUp : iconDown}
						/>
						{favouriteList && favouriteList.length > 0
						?
						<Collapse className="list" isOpen={showFav}>
							{typeof favouriteDD === 'function' ?
								favouriteDD(selectItemHandler)
								:
								favouriteDD
							}
						</Collapse>
						:
						<p hidden={!showFav} className='text__no-favourite'>{I("None")}</p>
						}
					</div>
					<DropdownItem divider />
					<div className='regular-section'>
						<DropdownAccordianHeader
							title={titleRegular}
							onToggle={handleToggleRegAccordian}
							icon={showRegular ? iconUp : iconDown}
						/>
						<Collapse className="list" isOpen={showRegular}>
							{typeof regularDD === 'function' ?
								regularDD(selectItemHandler)
								:
								regularDD
							}
						</Collapse>
					</div>
				</div>
			</DropdownMenu>
			{typeof tooltip === 'function' ?
				tooltip(!!showDD)
				:
				tooltip
			}
		</ReactstrapDropdown>
	)
}