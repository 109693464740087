import bowser from 'bowser';
import { Observable } from 'rxjs';
import { first, map, repeat } from 'rxjs/operators';
import { WS_EVENT_CONNECTED, WS_EVENT_DISCONNECTED } from 'socket';
import { doNothing } from '../../../common/constants';
import { V5 } from '../../../common/path';
import { UNSELECT, emptyArray } from '../../../common/v5/constants';
import { push } from '../../../common/v5/utils';
import { hasPrefix } from '../../../common/v5/helpers';
import {
	AE_ACCEPT
	, AE_CALLING
	, AGS_ASSIGNING
	, AS_CONFIRM_NO
	, AS_CONFIRM_YES
	, CALLS_CONNECTED
	, CALLS_CONNECTING
	, CALLS_CONNECTING_REMOTE
	, CALLS_GETTING_TOKEN
	, CALLS_IDLE
	, CALLS_NO_TOKEN
	, CALLS_OUTBOUNDING
	, CALLT_INBOUND
	, CALLT_OUTBOUND
	, CALLT_UNKNOWN
	, SIP_CONNECTED
	, SIP_CALL_CONNECTING
	, SIP_CALL_CONNECTED
	, CALL_STR_HOLD
	, CALL_STR_RESUME
	, CHECK_SAFARI11
	, DBG_TWILIO
	, KO_ACK
	, OK_ACK
	, RS_CHECKING_ACCEPT
	, RS_FAILED_LIB
	, RS_FAILED_SERVER
	, RS_LOADING_LIB
	, RS_NO_SAFARI
	, RS_READY
	, RS_STPD_BRWSR
	, SCS_INCOMING_CALL
	, TM_AGENT_ID
	, TM_PHONE_NUMBER
	, TWILIO_SOCKET_NAMESPACE
	, TWILIO_TOKEN_EXPIRED_ERROR_CODE
	, XFER_ST_INVITE
	, evtACCEPT
	, evtAGENTS_CHANGES
	, evtCALL_AGENTS
	, evtCLIENT
	, evtMY_CHANGES
	, evtMY_STATUS
	, evtOUTBOUND
	, evtRECONNECTING
	, evtREFRESH_TOKEN
	, evtREPLACE_WORKPLACE
	, evtTRANSFER
	, txtErrandNotCreated
	, txtNoSelectedCallerId
	, txtPreviousCallNoCreateErrand
	, wf_PhoneNumber_TYPE_TWILIO
} from '../../../common/v5/callConstants';
import {
	keyChangeAcceptCall
	, keyGetAcceptCall
	, keyGetOutboundPhones
	, keyAgentCallLog
	, keyGetAllSipAgents
	, keyGetActiveCalls
	, keyGetSipNumberInUse
} from '../../constants/keys';
import {
	getAcceptCall
	, getOutboundPhones
	, postOutboundApiCall
	, postHangupApiCall
	, postAcceptCall
	, postAgentCallLog
	, getAllSipAgents
	, getActiveCallLogs
	, getSipNumberInUse
	, getSipIsSipAvailable
} from './ajax';
import {
	async
	, createActionCreators
	, loadOnceCreator
} from '../../util';
import websocket from '../../../common/v5/agentsocket';
import {
	changeTransfereeAgent
	, clearTwilioToken
	, closeCallPadPopup
	, phoneArea
	, resetInboundErrandAreaId
	, resetPhone
	, resetTransfer
	, resetTwilioStatus
	, toggleCallPadPopup
	, updateAccept
	, updateAgentChanges
	, updateBusy
	, updateC3ConnectedPhone
	, updateAventaStatus
	, updateInboundCallSid
	, updateInboundErrand
	// , updateInboundErrandId
	, updateMe
	, updateMyCall
	, updateMyExist
	, updateOutboundCallSid
	, updateOutboundDialBack
	, updateOutboundErrandId
	, updateOthers
	, updatePhone
	, updateReady
	, updateReplacing
	, updateTransfer
	, updateTransferState
	, updateTwilioCallType
	, updateTwilioStatus
	, updateTwilioToken
} from '../call';
import {
	subscribeWebsocketConnectionStateSubject
} from '../../../common/v5/websocketConnectionState';
import {
	searchOthers
	, startAutoOpenErrandTicker
	, stopAutoOpenErrandTicker
} from '../../reducers/call';
import store from '../../store/configureStore';
import {
	isCallMemoize
	, getOutboundPhoneTo
	, getCallOutboundPhoneTo
	, manualCallMinimize
	, manualErrandSelectedAreaSelector
	, getManualInputUpdateTo
} from '../../selectors/manual';
import {
	callMap as call
	, callStateByKey
	, callStatusMemoize
	, canWarmXfer
	, getOutboundPhoneId
	, isAcceptCall
	, inboundErrandId
	, isReplacingWorkplace
	, isTwilioOutbounding
	, isXferCallInProgress
	, meState
	, onlyOtherAgents
	, outboundCallSid
	, outboundDialBack
	, sipCallConn
	, selectedAudioInputMemo
	, selectedPhoneMemoize
	, selectedForwardee
	, selectedTransfereeAgentId
	, transferMethod
	, transferableOptionsMemo
	, twilioCallStatus
	, twilioCallType
	, xfereeName
	, phoneNumber
} from '../../selectors/call';
import {
	dialBackCallerIdPhoneNumberMemoize
	, firstUpdateToMemoize
	, getCurrentErrand
	, getCurrentErrandAreaId
} from '../../selectors/errand';
import { openErrandExtraFieldsMemoize } from '../../selectors/workflow';
import { popErrorOnly, togglePopAlert } from '../hmf';
import { errandAreaData, openErrandFromList } from './errand';
import { checkBeforeFetchAvatar, fetchAreaForwardAgents } from './workflow';
import {
	makeSipVoiceCall
	//, onHoldSIPCall
	, onEndSIPCall
} from './sippRtc.js';
import { isPossiblePhoneNumber } from 'react-phone-number-input';

const loadOnce = (key, ajax) => loadOnceCreator(call, key, ajax, callStateByKey);

export const onceOutboundPhones = loadOnce(keyGetOutboundPhones, getOutboundPhones);

class callDeviceConnection {
	set connection(conn) {
		this.conn = conn;
	}
	set device(dvc) {
		this.dvc = dvc;
	}
	get connection() {
		return this.conn;
	}
	get device() {
		return this.dvc;
	}
}

let twilioCall = new callDeviceConnection();
let globalTwilioDevice;

function isOkAck(s, res) {
	if (!s) {
		if (typeof res === 'object') {
			res.error = "invalid string " + s;
		}
		return false;
	}
	let n = s.indexOf(OK_ACK);
	if (n != 0) {
		if (typeof res === 'object') {
			n = s.indexOf(KO_ACK);
			if (n != 0) {
				res.error = s;
			} else {
				res.error = s.substr(KO_ACK.length);
			}
		}
		return false;
	}
	if (typeof res === 'object') {
		res.message = s.substr(OK_ACK.length);
	}
	return true;
}

function decodeURIComponentAndParseJSON(s) {
	let decoded
		, json
		;
	try {
		decoded = decodeURIComponent(s);
	} catch (e) {
		console.log("ERROR decode first argument URI component:", s, e);
	}
	try {
		json = JSON.parse(decoded);
	} catch (e) {
		console.log("ERROR parsing json `" + json + " `: " + e);
	}
	return json;
}

function decodeAckObject(s) {
	const decoded = decodeURIComponent(s);
	let json;
	try {
		json = JSON.parse(decoded);
	} catch (e) {
		console.log("ERROR decode ack object `" + decoded + "`: " + e);
	}
	return json;
}

function emit(ws) {
	return (event, data, ...args) => {
		return ws.emit(
			TWILIO_SOCKET_NAMESPACE
			, event
			, encodeURIComponent(JSON.stringify(data))
			, ...args
		);
	};
}

// TODO: unbindEvents
const unbindEvents = ws => {
	// ws.off(evtRECONNECTING);
	// // don't unbind disconnect event here, explicitly use unbindDisconnectEvent
	// // to unbind it.
	// ws.off(evtCALL_AGENTS);
	// ws.off(evtAGENTS_CHANGES);
	// ws.off(evtREPLACE_WORKPLACE);
	// ws.off(evtMY_STATUS);
	// ws.off(evtMY_CHANGES);
	// ws.off(evtOUTBOUND);
	// ws.off(evtTRANSFER);
};

const resetCallConnection = call => dispatch => {
	call.connection = null;
	dispatch(updateBusy(false));
	dispatch(updateTwilioCallType(CALLT_UNKNOWN));
	dispatch(updateTwilioStatus(CALLS_IDLE));
};

const twilioRelease = () => (dispatch, getState) => {
	const audioInput = selectedAudioInputMemo(getState());
	if (audioInput) {
		existingTwilioDevice().audio.unsetInputDevice(audioInput);
	}
	dispatch(resetCallConnection(twilioCall));
	dispatch(updateInboundCallSid(""));
	dispatch(stopAutoOpenErrandTicker());
	dispatch(resetInboundErrandAreaId());
	dispatch(resetPhone());
	dispatch(resetTransfer());
	// TODO: reset onCalling to false
};

const startCallSocket = ws => new Promise(resolve => {
	ws.emit(TWILIO_SOCKET_NAMESPACE, "start", () => {
		resolve();
	});
});

const endCallSocket = ws => new Promise(resolve => {
	ws.emit(TWILIO_SOCKET_NAMESPACE, "end", () => {
		resolve();
	});
});

const destroyAndResetTwilio = () => dispatch => {
	if (twilioCall.device) {
		twilioCall.device.disconnectAll();
		twilioCall.device.destroy();
	} else {
		dispatch(resetTwilioStatus());
	}
}

const disconnectSocket = ws => dispatch => endCallSocket(ws).then(() => {
	if (process.env.NODE_ENV !== 'production') {
		console.log('dbg: call socket disconnected, proceed reset Twilio state');
	}
	dispatch(destroyAndResetTwilio());
})

// TODO: listen on socket disconnection and logout then trigger this function.
// TODO: use this function to release call related memory.
const disconnectCallsock = ws => dispatch => {
	if (process.env.NODE_ENV !== 'production') {
		console.log('dbg: will disconnect call socket and release twilio');
	}
	// disconnect call socket and also release Twilio websocket
	dispatch(twilioRelease());
	return dispatch(disconnectSocket(ws));
};

let websocketConnectedUnsubscriber;

const unsubscribeWebsocketConnected = () => {
	if (typeof websocketConnectedUnsubscriber === 'function') {
		websocketConnectedUnsubscriber()
		websocketConnectedUnsubscriber = null
	}
}

const assignWebsocketConnectedUnsubscriber = unsubscriber => {
	unsubscribeWebsocketConnected()
	websocketConnectedUnsubscriber = unsubscriber
}

// NOTE: ready state is updated through reducer.
const updateAcceptState = (ws, accept, disconnSocket) => dispatch => {
	if (accept) {
		startCallSocket(ws).then(() => {
			assignWebsocketConnectedUnsubscriber(
				subscribeWebsocketConnectionStateSubject({
					next(state) {
						if (process.env.NODE_ENV !== 'production') {
							console.log('websocket state:', state)
						}
						if (state === WS_EVENT_CONNECTED) {
							startCallSocket(ws)
						} else if (state === WS_EVENT_DISCONNECTED) {
							dispatch(twilioRelease());
							dispatch(destroyAndResetTwilio());
						}
					}
				})
			)
		})
	} else {
		if (disconnSocket) {
			unsubscribeWebsocketConnected();
			dispatch(disconnectCallsock(ws));
		}
	}
};

export const updateAgentCallLog = (errandId, phone, callType, start) => {
	const param = {
		errandId, phone, callType, start
	}
	return async(postAgentCallLog(param), call[keyAgentCallLog]);
};

export const sipGetAllAgentList = () => {
	return async(getAllSipAgents(), call[keyGetAllSipAgents]);
}

export const sipGetActiveCalls = () => {
	return async(getActiveCallLogs(), call[keyGetActiveCalls]);
}

export const sipGetSipNumberInUse = () => {
	return async(getSipNumberInUse(), call[keyGetSipNumberInUse]);
}

const changeAcceptBase = accept => {
	const param = {accept};
	return async(postAcceptCall(param), call[keyChangeAcceptCall], param);
};

export const changeAccept = accept => dispatch => {
	return dispatch(changeAcceptBase(accept))
		.then(() => dispatch(updateAcceptState(websocket, accept, true)))
		.catch(() => dispatch(disconnectCallsock(websocket)));
};

const getAccept = () => async(getAcceptCall(), call[keyGetAcceptCall]);

const checkAccept = ws => (dispatch, getState) => {
	dispatch(updateReady(RS_CHECKING_ACCEPT));
	return dispatch(getAccept())
		.then(() => dispatch(updateAcceptState(ws, isAcceptCall(getState()))));
};

function isUnsupportedSafari() {
	// console.log("dbg: browser:", bowser.version, bowser);
	if (!bowser.safari) {
		return false;
	} else if (CHECK_SAFARI11 && bowser.version >= 11) {
		return false;
	}
	return true;
}

function cancelTwilioOutbound(ws) {
	// use socket to disconnect outbounding call
	emit(ws)(evtOUTBOUND, {cancel: true}, ackstr => {
		const ack = decodeAckObject(ackstr);
		if (ack.success) {
			console.log("request cancel outbound success and in process.");
		} else {
			console.log("request cancel outbound failed: ", ack.messsage);
		}
	});
}

const twilioDisconnectOngoing = ws => (dispatch, getState) => {
	// disconnect any ongoing process such as incoming call, outbounding
	// call, transfering, etc.
	const state = getState()
		, callStatus = twilioCallStatus(state)
		, callType = twilioCallType(state)
		, { connection } = twilioCall
		;
	if ((callStatus === CALLS_OUTBOUNDING || callStatus === CALLS_CONNECTING)
		&& connection) {
		// console.log("dbg: connection clash with expired token:", connection);
		connection.disconnect();
	}
	if (callType === CALLT_OUTBOUND) {
		cancelTwilioOutbound(ws);
	}
};

const twilioDeviceSetup = (twilioDevice, token) => dispatch => {
	if (!twilioDevice) {
		dispatch(togglePopAlert("invalid Twilio device:" + twilioDevice));
		return;
	}
	twilioDevice.setup(token, {debug: DBG_TWILIO});
};

function existingTwilioDevice() {
	const { device } = twilioCall;
	if (device) {
		return device;
	}
	// TODO: try remove this code not directly need global variable.
	return globalTwilioDevice;
}

function getTwilioDevice(Device) {
	let device = existingTwilioDevice();
	if (!device) {
		device = new Device();
		globalTwilioDevice = device;
	}
	return device;
}

const refreshToken = (ws, callType, Device) => dispatch => {
	// if (!ws.conn) {
	// 	// console.log("dbg: websocket is not ready during refresh token");
	// 	return;
	// }
	dispatch(updateBusy(true));
	emit(ws)(
		evtREFRESH_TOKEN
		, {type: callType}
		, ackstr => {
			const res = {};
			if (isOkAck(ackstr, res)) {
				const token = res.message;
				let { device } = twilioCall;
				// console.log("dbg: refresh token ok done.", token);
				dispatch(updateTwilioToken(token));
				dispatch(twilioDeviceSetup(getTwilioDevice(Device), token));
			} else {
				console.log('ERROR refresh token:', res.error);
				dispatch(updateBusy(false));
			}
		}
	);
};

const noWebsocketError = (dispatch, prefix, error) => {
	if (error) {
		if (process.env.NODE_ENV !== 'production') {
			console.log("dbg: error " + prefix + ":", error.message);
		}
		dispatch(togglePopAlert(error.message));
		return false;
	}
	return true;
};

const checkAndUpdateClient = (number, mailOrigin) => dispatch => {
	if (mailOrigin) {
		dispatch(checkBeforeFetchAvatar(mailOrigin, true));
	}
	dispatch(updatePhone(number, mailOrigin));
};

const listenTwilioAudioDeviceChange = device => new Observable(observer => {
	const event = 'deviceChange'
	const handler = () => { observer.next(device.audio.availableInputDevices) }
	device.audio.on(event, handler)
	return () => { device.audio.removeListener(event, handler) }
})

const listenGetUserMedia = () => new Observable(observer => {
	console.log('getting user media permission')
	navigator.mediaDevices.getUserMedia({ audio: true })
		.then(stream => {
			stream.getTracks().forEach(track => track.stop())
			observer.next(stream)
		})
		.catch(error => { observer.error(error) })
	return doNothing
})

// Callback to let us know Twilio Client is ready
const listenTwilioDeviceReady = device => dispatch => new Observable(
	observer => {
		const event = 'ready'
		const handler = device => {
			console.log("DEVICE ready!")
			twilioCall.device = device
			dispatch(resetCallConnection(twilioCall))
			// TODO: set requesting token false
			observer.next(device)
		}
		device.on(event, handler)
		return () => { device.removeListener(event, handler) }
	}
)

// Callback for when Twilio Client offline
const listenTwilioDeviceOffline = device => dispatch => new Observable(
	observer => {
		const event = 'offline'
		const handler = device => {
			console.log('Twilio device: offline')
			dispatch(resetTwilioStatus())
			twilioCall.connection = null
			twilioCall.device = null
			observer.next(device)
		}
		device.on(event, handler)
		return () => { device.removeListener(event, handler) }
	}
)

const TXT_UNRESOLVE_AUDIO_INPUT_LABEL = I('Unknown audio input device')

const jsMapToArray = inputDevices => {
	const array = []
	if (process.env.NODE_ENV !== 'production') {
		console.log('convert audio input device Map to primitive array of object')
	}
	let cardinal = 1
	inputDevices.forEach(({ deviceId: id, label: name }) => {
		if (process.env.NODE_ENV !== 'production') {
			console.log(`device #${cardinal} id=${id} label=${name}`)
			cardinal++
		}
		if (id) {
			if (!name) {
				name = TXT_UNRESOLVE_AUDIO_INPUT_LABEL
			}
			array.push({ id, name })
		}
	})
	return array
}

const setupTwilioDeviceListeners = (
	ws
	, device
	, Device
) => (dispatch, getState) => {
	const audioInputListS = new Observable(observer => {
		let offlineS
		let userMediaS
		let deviceListS
		const readyS = dispatch(listenTwilioDeviceReady(device))
			.pipe(first())
			.subscribe({
				next(device) {
					offlineS = dispatch(listenTwilioDeviceOffline(device))
						.pipe(first())
						.subscribe({ complete() { observer.complete() } })
					userMediaS = listenGetUserMedia(device).pipe(first()).subscribe({
						error(error) { observer.error(error) },
						next() {
							observer.next(device.audio.availableInputDevices)
							deviceListS = listenTwilioAudioDeviceChange(device).subscribe({
								next(availableInputDevices) {
									observer.next(availableInputDevices)
								}
							})
						}
					})
				}
			})
		return () => {
			readyS.unsubscribe()
			if (offlineS) {
				offlineS.unsubscribe()
			}
			if (userMediaS) {
				userMediaS.unsubscribe()
				if (deviceListS) {
					deviceListS.unsubscribe()
				}
			}
		}
	}).pipe(map(jsMapToArray), repeat())
	// Callback for when Twilio Client receives a new incoming call
	device.on("incoming", connection => {
		const state = getState()
			, status = twilioCallStatus(state)
			, { CallSid, From, To } = connection.parameters
			, clientPhoneAndArea = connection.customParameters
			;
		let type = twilioCallType(state)
			;
		if (process.env.NODE_ENV !== 'production') {
			console.log(
				"dbg: incoming CallSid:"
				, {CallSid, From, To, clientPhoneAndArea}
			);
			console.log("dbg: incoming call connection:", connection);
		}
		twilioCall.connection = connection;
		if (status === CALLS_OUTBOUNDING && type === CALLT_OUTBOUND) {
			type = CALLT_OUTBOUND;
		} else {
			type = CALLT_INBOUND;
			ws.emit(
				TWILIO_SOCKET_NAMESPACE
				, evtCLIENT
				, CallSid
				, wf_PhoneNumber_TYPE_TWILIO
				, false
				, (error, client, source) => {
					if (noWebsocketError(dispatch, "client event", error)) {
						const { phone, phoneMailOrigin } = client
							, { phone: phoneC3 } = source
							;
						if (process.env.NODE_ENV !== 'production') {
							console.log(
								"dbg: client event:"
								, {phone, phoneC3, phoneMailOrigin}
							);
						}
						dispatch(checkAndUpdateClient(phone, phoneMailOrigin));
					}
				}
			);
			const phone = clientPhoneAndArea.get("phone")
				, area = parseInt(clientPhoneAndArea.get("area"))
				;
			if (phone && area > 0) {
				if (process.env.NODE_ENV !== 'production') {
					console.log(
						"dbg: inbound custom parameters:"
						, {phone, area}
					);
				}
			}
			dispatch(updateC3ConnectedPhone(From));
		}
		dispatch(updateTwilioCallType(type));
		dispatch(updateTwilioStatus(CALLS_CONNECTING));
		if (type != CALLT_OUTBOUND || !isCallMemoize(state)) {
			dispatch(toggleCallPadPopup(true));
		}
		// TODO: set onCalling to true
	});
	// Callback to let us know Twilio Client is canceled
	device.on("cancel", connection => {
		console.log("connection cancelled", connection);
		dispatch(twilioRelease());
	});
	// Callback to let us know Twilio Client is connected during
	// inbound/outbound call
	device.on("connect", connection => {
		console.log("in/out bound call connect", connection);
		twilioCall.connection = connection;
		dispatch(updateTwilioStatus(CALLS_CONNECTED));
	});
	// Callback for when a call ends
	device.on("disconnect", connection => {
		// Disable the hangup button and enable the call buttons
		console.log("connection disconnect", connection);
		let state = getState();
		let phone = phoneNumber(state);
		let callType = twilioCallType(state);
		dispatch(updateAgentCallLog(0, phone, callType, false));
		dispatch(twilioRelease());
	});
	// Callback for when got error
	device.on("error", error => {
		const { dispatch } = store;
		if (error && error.code === TWILIO_TOKEN_EXPIRED_ERROR_CODE) {
			// token expire and refresh from server
			console.log("twilio token expired", error);
			dispatch(twilioDisconnectOngoing(ws));
			dispatch(clearTwilioToken()); // clear token indicate expired
			dispatch(refreshToken(ws, 'twilio', Device));
		} else {
			console.log("ERROR twilio:", error);
			dispatch(updateBusy(false)); // try to release UI lock
			dispatch(togglePopAlert("Twilio error: " + error.message));
		}
	});
	return audioInputListS;
};

const twilioSetup = (ws, { Device }) => setupTwilioDeviceListeners(
	ws
	, getTwilioDevice(Device)
	, Device
);

const twilioAutoConnect = (me, twilioDevice) => (dispatch, getState) => {
	const status = twilioCallStatus(getState());
	if (status === CALLS_NO_TOKEN
		|| (status === CALLS_GETTING_TOKEN && !twilioDevice)) {
		if (me && me.tokens && me.tokens.twilio) {
			dispatch(updateTwilioStatus(CALLS_CONNECTING_REMOTE));
			dispatch(twilioDeviceSetup(twilioDevice, me.tokens.twilio));
		}
	} else if (status !== CALLS_NO_TOKEN && twilioDevice) {
		twilioDevice.destroy();
	}
};

const connectAll = me => twilioAutoConnect(me, existingTwilioDevice());

const setupAndAccept = (ws, twilio) => dispatch => {
	const audioInputListS = dispatch(twilioSetup(ws, twilio));
	dispatch(checkAccept(ws));
	return audioInputListS;
};

const dynLoadTwilioLib = ws => dispatch => {
	// dynamic load twilio
	// https://webpack.js.org/api/module-methods/#import-
	// https://webpack.js.org/guides/code-splitting/#dynamic-imports
	return import(/* webpackChunkName: 'twilio-client' */ 'twilio-client')
		.then(twilio => dispatch(setupAndAccept(ws, twilio)))
		.catch(err => {
			console.log('An error occurred while loading the component', err);
			dispatch(updateReady(RS_FAILED_LIB));
		});
};

export const checkBrowser = ws => dispatch => {
	if ("ActiveXObject" in window) {
		dispatch(updateReady(RS_STPD_BRWSR));
	} else if (isUnsupportedSafari()) {
		dispatch(updateReady(RS_NO_SAFARI));
	} else {
		dispatch(updateReady(RS_LOADING_LIB));
		return dispatch(dynLoadTwilioLib(ws));
	}
	return Promise.resolve();
};

const statusEventError = ws => dispatch => {
	dispatch(disconnectSocket(ws));
	dispatch(updateReady(RS_FAILED_SERVER));
}

function decodeFirstArgumentUriComponentAndParseJSON(callback) {
	return (d, cb) => callback(decodeURIComponentAndParseJSON(d), cb);
}

// TODO: make listenEvents as redux-thunk format.
export const listenEvents = ws => {
	// this.callsock.on('connect', function() {}.bind(this));
	// this.bindEvents();
	// this.callsock.connect();
	// var s = this.callsock;

	// s.on('reconnecting', function() {
	// 	console.log("dbg-call: reconnecting");
	// });
	// s.on('disconnect', function(reason) {
	// 	console.log("dbg-call: disconnected:", reason);
	// 	if(this.state.status.twilio.dvc) {
	// 		this.state.status.twilio.dvc.disconnectAll();
	// 		this.state.status.twilio.dvc.destroy();
	// 	}
	// 	if(!this.callsock) {
	// 		this.unbindDisconnectEvent(s);
	// 	}
	// }.bind(this));

	const on = (event, cb) => ws.on(
		TWILIO_SOCKET_NAMESPACE
		, event
		, decodeFirstArgumentUriComponentAndParseJSON(cb)
	);
	on(evtCALL_AGENTS, (d, cb) => {
		// console.log("dbg-call: call agents event:", d);
		cb('ack');
	});
	on(evtAGENTS_CHANGES, (d, cb) => {
		// console.log("dbg-call: call agents changes event:", d);
		cb('ack');
		store.dispatch(updateAgentChanges(d));
	});
	on(evtREPLACE_WORKPLACE, (d, cb) => {
		// console.log("dbg-call: call", evtREPLACE_WORKPLACE, d);
		const { dispatch } = store;
		if (d.replaced) {
			if (twilioCall.device) {
				twilioCall.device.destroy();
			}
			dispatch(disconnectSocket(ws));
			dispatch(updateMyExist(true));
		}
		cb('ack');
	});
	on(evtMY_STATUS, (d, cb) => {
		const { dispatch, getState } = store;
		cb('ack');
		if (d.error) {
			// console.log("dbg: error:", d.error);
			dispatch(statusEventError(ws));
			return;
		} else if (d.disconnected) {
			dispatch(updateReady(RS_READY));
			dispatch(updateAccept(AS_CONFIRM_NO));
			dispatch(updateReplacing(false));
			dispatch(disconnectSocket(ws));
			return;
		}
		const state = getState()
			, selectedTransferee = selectedTransfereeAgentId(state)
			;
		let { others } = d;
		delete d.others;
		if (!others) {
			others = emptyArray;
		}
		dispatch(updateReady(RS_READY));
		dispatch(updateMe(d));
		dispatch(updateOthers(others));
		if (selectedTransferee !== UNSELECT && selectedTransferee) {
			// if selected agent can't be found in list then selected agent
			// should be emptied as the selection no long valid.
			const f = parseInt(selectedTransferee, 10);
			let found;
			$.each(others, (i, { id }) => {
				if (f === id) {
					found = true;
					return false;
				}
			});
			if (!found) {
				dispatch(changeTransfereeAgent(UNSELECT));
			}
		}
		if (!d.exist) {
			dispatch(connectAll(d));
		} else if (isReplacingWorkplace(state)) {
			emit(ws)(
				evtREPLACE_WORKPLACE
				, {useMine: true}
				, ack => {
					dispatch(updateReplacing(false));
					if (isOkAck(ack)) {
						// continue with replace current socket
						console.log("calling connect all ACK:", ack);
					} else {
						console.log("invalid response replace workplace:", ack);
					}
				}
			);
		} else {
			// connection already existed - Not error let it being replace
			dispatch(disconnectSocket(ws));
		}
	});
	on(evtMY_CHANGES, (d, cb) => {
		// console.log("dbg-call: my changes event:", d);
		const { dispatch } = store;
		cb('ack');
		if (d.type === AE_ACCEPT) {
			dispatch(updateAccept(d.accept));
		} else if (d.type === AE_CALLING) {
			// console.log("dbg: my changes on calling:", d);
			if (d.status == d.state.end) {
				dispatch(updateMyCall(null));
			} else {
				dispatch(updateMyCall(d));
				if (d.area > 0) {
					const { area, phone } = d;
					console.log(
						"incoming call from phone"
						, phone
						, "connected to area"
						, area
					);
					dispatch(errandAreaData(area));
					dispatch(phoneArea({phone, area}));
				}
			}
		}
	});
	on(evtOUTBOUND, (d, cb) => {
		const { dispatch, getState } = store;
		if (isTwilioOutbounding(getState())) {
			dispatch(twilioRelease());
		}
		cb('ack');
	});
	on(evtTRANSFER, (d, cb) => {
		const { dispatch } = store;
		dispatch(updateTransferState(d.state));
		if (d.state !== XFER_ST_INVITE) {
			dispatch(updateBusy(false));
		}
		cb('ack');
	});
};

export const openErrandFromInboundCall = () => (dispatch, getState) => {
	const state = getState()
		, id = inboundErrandId(state)
		;
	if (id <= 0) {
		return Promise.reject({
			error: "invalid opening inbound call errand id "+id
		});
	}
	dispatch(closeCallPadPopup());
	dispatch(push(V5));
	return dispatch(openErrandFromList(
		id
		, false
		, openErrandExtraFieldsMemoize(state)
		, false
	));
};

export const accept = ws => (dispatch, getState) => {
	console.log("accepting call ...");
	const state = getState();
	const status = twilioCallStatus(state);
	if (status === CALLS_CONNECTING) {
		const { connection } = twilioCall;
		dispatch(updateBusy(true));
		connection.on("accept", () => {
			const state = getState()
				, { CallSid } = connection.parameters
				, callType = twilioCallType(state)
				;
			if (process.env.NODE_ENV !== 'production') {
				console.log("dbg: accepted call sid:", CallSid);
			}
			let callback, isOutbound;
			if (callType === CALLT_INBOUND) {
				dispatch(updateInboundCallSid(CallSid));
				callback = createAcceptCallback(
					dispatch
					, "inbound"
					, (dispatch, errandId, areaId) => {
						if (errandId > 0) {
							dispatch(startAutoOpenErrandTicker(openErrandFromInboundCall));
						}
						if (canWarmXfer(state) && areaId > 0) {
							dispatch(fetchAreaForwardAgents(areaId+""));
						}
						dispatch(updateInboundErrand(errandId, areaId));
						let phone = phoneNumber(state);
						dispatch(updateAgentCallLog(errandId, phone, 1, true));
						// dispatch(updateInboundErrandId(errandId));
					}
				);
			} else if (callType === CALLT_OUTBOUND) {
				if (!outboundDialBack(state)) {
					// update call sid
					dispatch(updateOutboundCallSid(CallSid));
					isOutbound = true;
					callback = createAcceptCallback(
						dispatch
						, "outbound"
						, (dispatch, errandId) => {
							dispatch(updateOutboundErrandId(errandId));
							let phone = phoneNumber(state);
							dispatch(updateAgentCallLog(errandId, phone, 2, true));
						}
					);
				} else {
					let phone = phoneNumber(state);
					let errandId = getCurrentErrand(state);
					dispatch(updateAgentCallLog(errandId, phone, 2, true));
				}
			}
			if (typeof callback !== "undefined") {
				ws.emit(
					TWILIO_SOCKET_NAMESPACE
					, evtACCEPT
					, CallSid
					, wf_PhoneNumber_TYPE_TWILIO
					, !!isOutbound
					, callback
				);
			}
			dispatch(updateBusy(false));
		});
		new Promise(resolve => {
			const audioInput = selectedAudioInputMemo(state);
			let p
			if (audioInput) {
				p = existingTwilioDevice().audio.setInputDevice(audioInput);
			}
			resolve(p);
		}).catch(err => {
			dispatch(popErrorOnly(err));
		}).then(() => {
			connection.accept();
		});
	} else {
		console.log("ERROR accept in non-connecting:", status);
	}
};

const createAcceptCallback = (dispatch, prefix, func) => (error, ...args) => {
	if (noWebsocketError(dispatch, "accept "+prefix, error)) {
		func(dispatch, ...args);
	}
};

export const reject = () => (dispatch, getState) => {
	const status = twilioCallStatus(getState())
		, { connection } = twilioCall
		;
	if (status === CALLS_CONNECTING) {
		console.log("rejecting call ...");
		connection.reject();
		dispatch(twilioRelease());
	} else {
		console.log("hanging up call ...");
		let state = getState();
		let phone = phoneNumber(state);
		let callType = twilioCallType(state);
		dispatch(updateAgentCallLog(0, phone, callType, false));
		dispatch(updateBusy(true));
		connection.disconnect();
	}
};

const resetCallTransfer = () => dispatch => {
	dispatch(resetTransfer());
	dispatch(updateBusy(false));
};

const cancelTransfer = (ws, targetName) => dispatch => {
	if (!targetName) {
		// ack rejected transfer
		dispatch(resetCallTransfer());
		return;
	}
	// if cancel is called, target is the name of the transfer target
	console.log("canceling warm transfer to:", targetName);
	emit(ws)(evtTRANSFER, {cancel: true}, ack => {
		// console.log("dbg: ack from warm transfer call:", ack);
		if (!isOkAck(ack)) {
			console.log("error in cancel warm transfer:", ack);
		}
		dispatch(resetCallTransfer());
	});
};

const methodFieldMap = {
	[TM_AGENT_ID]: "targetId"
	, [TM_PHONE_NUMBER]: "forward"
};

const callTransferBase = (ws, { id, name }, method) => (dispatch, getState) => {
	console.log("transfering call to:", id);
	dispatch(updateBusy(true));
	return new Promise((resolve, reject) => {
		emit(ws)(
			evtTRANSFER
			, {
				agent: meState(getState()).id
				, [methodFieldMap[method]]: id
				, transfer: true
			}
			, ack => {
				if (process.env.NODE_ENV !== 'production') {
					console.log("dbg: ack from call transfer:", ack);
				}
				if (!isOkAck(ack)) {
					dispatch(resetCallTransfer());
					reject(new Error("Error ack " + ack));
				} else {
					dispatch(updateTransfer({
						inProgress: true
						, name
						, st: XFER_ST_INVITE
					}));
					resolve();
				}
			}
		);
	});
};

const callTransfer = (ws, target) => callTransferBase(ws, target, TM_AGENT_ID);

const externalForwardTransfer = (ws, target) => callTransferBase(
	ws
	, target
	, TM_PHONE_NUMBER
);

function isValidSelected(selected) {
	if (!selected || selected === UNSELECT) {
		console.log("no valid selection for transfer:", selected);
		return false;
	}
	return true;
}

export const warmTransfer = ws => (dispatch, getState) => {
	const state = getState()
		, selected = selectedTransfereeAgentId(state)
		;
	if (!isValidSelected(selected)) {
		return;
	}
	const others = onlyOtherAgents(state)
		, id = parseInt(selected, 10)
		, { found, index } = searchOthers(others, id, {})
		;
	if (!found) {
		dispatch(togglePopAlert("Transfer failed: can not find agent with id: " + id));
		return;
	}
	const target = others[index];
	if (target.statusId === AGS_ASSIGNING) {
		dispatch(togglePopAlert("Can't transfer: agent engage."));
		return;
	}
	return dispatch(callTransfer(ws, target))
		.catch(err => dispatch(popErrorOnly(err)));
};

const transferToPhone = ws => (dispatch, getState) => {
	const forwardee = selectedForwardee(getState());
	if (!forwardee) {
		return Promise.reject(new Error("Invalid selection"));
	}
	const { number: id, name } = forwardee;
	return dispatch(externalForwardTransfer(ws, {id, name}));
};

export const externalForward = ws => (
	dispatch
	, getState
) => dispatch(transferToPhone(ws))
	.then(() => {
		const state = getState()
			, status = callStatusMemoize(state)
			;
		if (process.env.NODE_ENV !== 'production') {
			console.log("dbg: call status:", status);
		}
		if (status === SCS_INCOMING_CALL || !canWarmXfer(state)) {
			if (process.env.NODE_ENV !== 'production') {
				console.log("dbg: rejecting call after forward:", status);
			}
			dispatch(reject());
		}
	})
	.catch(err => popErrorOnly(err))
	;

function createCancelTransferThunk(ws, getter) {
	return (dispatch, getState) => {
		const state = getState();
		if (isXferCallInProgress(state)) {
			return dispatch(cancelTransfer(ws, getter(state)));
		}
	};
}

export const cancelWarmTransfer = ws => createCancelTransferThunk(
	ws
	, state => {
		const name = xfereeName(state);
		if (name) {
			return name;
		}
		return "<no transferee name>";
	}
);

export const resetWarmTransfer = ws => createCancelTransferThunk(
	ws
	, () => null
);

const cancelOutboundCall = (ws, phoneNumber) => (dispatch, getState) => {
	const state = getState()
		, status = twilioCallStatus(state)
		// , type = twilioCallType(state)
		;
	// if (type !== CALLT_OUTBOUND) {
	// 	console.log("ERROR invalid call type for cancel outbound:", type);
	// 	return;
	// }
	const { connection } = twilioCall;
	console.log("cancel/drop outbound call:", phoneNumber);
	if ((status === CALLS_OUTBOUNDING || status === CALLS_CONNECTING
		|| status === CALLS_CONNECTED) && connection) {
		connection.disconnect();
	}
	cancelTwilioOutbound(ws);
};

const twilioOutboundAccepted = (
	twilioCall
	, phoneNumber
	, mailOrigin
	, agent
	, backend
	, dialBack
) => dispatch => {
	if (mailOrigin) {
		dispatch(checkBeforeFetchAvatar(mailOrigin, true));
	}
	dispatch(updatePhone(phoneNumber, mailOrigin));
	if (!backend) {
		twilioCall.connection = twilioCall.device.connect({
			outgoing: phoneNumber
			, agent
		});
	} else {
		twilioCall.connection = null;
	}
	dispatch(updateOutboundDialBack(!!dialBack));
	dispatch(updateTwilioCallType(CALLT_OUTBOUND));
	dispatch(updateTwilioStatus(CALLS_OUTBOUNDING));
};

const twilioOutbound = (ws, phoneNumber, dialBack) => (dispatch, getState) => {
	const state = getState()
		, status = twilioCallStatus(state)
		, { connection } = twilioCall
		;
	if (status === CALLS_IDLE) {
		if (!phoneNumber || phoneNumber.length <= 0) {
			const msg = "invalid phone number for outbound: " + phoneNumber;
			console.log(msg);
			dispatch(togglePopAlert(msg));
			return;
		}
		dispatch(updateBusy(true));
		if (hasPrefix(phoneNumber, "00")) {
			phoneNumber = "+" + phoneNumber.substring(2);
		} else if (phoneNumber[0] != "+") {
			phoneNumber = "+" + phoneNumber;
		}
		const { id, backendOutbound } = meState(state)
			, param = {
				agent: id
				, backend: backendOutbound
				, to: phoneNumber
			}
			;
		if (dialBack) {
			param.area = getCurrentErrandAreaId(state);
			param.dialBack = true;
			param.callerIdNumber = dialBackCallerIdPhoneNumberMemoize(state);
			param.errandId = getCurrentErrand(state);
		} else {
			param.area = manualErrandSelectedAreaSelector(state);
			param.fromCallerId = selectedPhoneMemoize(state);
		}
		emit(ws)(
			evtOUTBOUND
			, param
			, ackstr => {
				const ack = decodeAckObject(ackstr);
				if (ack.success) {
					if (process.env.NODE_ENV !== 'production') {
						console.log(
							"dbg: outbound request:"
							, {phoneNumber, id, backend: ack.backend, dialBack}
						);
					}
					const mo = ack.mailOrigin;
					dispatch(twilioOutboundAccepted(
						twilioCall
						, phoneNumber
						, typeof mo === "number" ? mo : 0
						, id
						, ack.backend
						, dialBack
					));
				} else {
					const msg = "outbound call failed: " + ack.message;
					console.log(msg);
					dispatch(togglePopAlert(msg));
				}
				dispatch(updateBusy(false));
			}
		);
	} else if (status === CALLS_CONNECTED && connection) {
		console.log("previous call in progress when outbound:", connection);
		connection.disconnect();
	} else {
		console.log("invalid call status for outbound:", status);
	}
};

const outboundCall = (ws, cancel) => (dispatch, getState) => {
	const state = getState()
		, phoneNumber = getOutboundPhoneTo(state)
		;
	if (cancel) {
		return dispatch(cancelOutboundCall(ws, phoneNumber));
	}
	const previousCallSid = outboundCallSid(state);
	if (previousCallSid) {
		dispatch(togglePopAlert(txtPreviousCallNoCreateErrand.replace(
			'{CALL_SID}'
			, previousCallSid
		)));
		return;
	}
	const selectedCallerId = getOutboundPhoneId(state);
	if (selectedCallerId <= 0) {
		dispatch(togglePopAlert(txtNoSelectedCallerId));
		return;
	}
	console.log("outbound call to:", phoneNumber);
	dispatch(twilioOutbound(ws, phoneNumber));
};

export const makeOutboundCall = () => outboundCall(websocket);

const fixPhoneNumber = (phoneNumber) => {
	if(phoneNumber.startsWith("sip:") || phoneNumber.startsWith("+")){
		return phoneNumber;
	}
	let newNumber =  "+"+phoneNumber;
	if (isPossiblePhoneNumber(newNumber)){
			return newNumber;
	}
	return phoneNumber;
}

const outboundAPICall = (isFromManual) => (dispatch, getState) => {
	const state = getState()
		, sipServerName = state.app.workflow.fetchWfSettings.data.sipServerName
		;
	let phoneNumber = (manualCallMinimize(state) ? getCallOutboundPhoneTo(state) : getOutboundPhoneTo(state))
	, areaId = state.app.errand.inputs.area
	, errandId = state.app.errand.ui.manualCall.createdId;
	if(state.app.call.ui.status.sip.sipCallFromErrand) {
		console.log("Making call from current errand", errandId);
		const manualCallTarget = getManualInputUpdateTo(state);
		phoneNumber = manualCallTarget[0] ? manualCallTarget[0].value : firstUpdateToMemoize(state);
		//Check if there's a callback request (chat) set up
		if(state.app.errand.chat && state.app.errand.chat.enableCallbackRequest) {
			if(state.app.errand.chat.callbackNumber) {
				phoneNumber = state.app.errand.chat.callbackNumber;
			}
		}
	} else {
		if(state.app.errand.ui.manualCall.createdId === 0){
			dispatch(togglePopAlert(txtErrandNotCreated));
			return
		}
	}
	if(isFromManual == true) {
		areaId = state.app.errand.manualCallInputs.area;
	}
	if(state.app.workflow.connectedAgentAreas.data == null||
		state.app.workflow.connectedAgentAreas.data.areas.length == 0){
		console.info("unable to find connected areas for agent");
		return
	}
	let callPlanId = "";
	for(let index = 0; index < state.app.workflow.connectedAgentAreas.data.areas.length; index++){
		$.each(state.app.workflow.connectedAgentAreas.data.areas[index].Areas,
			(i, area) =>{
			if(area.Id == areaId){
				callPlanId = area.SipOutgoingDialPlanId;
				return false;
			}
		});
		if (callPlanId != "") {
			break;
		}
	}
	phoneNumber = fixPhoneNumber(phoneNumber);
	console.log("Phone number called: " + phoneNumber + " callplan id:[" +
		callPlanId + "]");
	let sipNumber = phoneNumber;
	if(sipNumber.includes("sip:") == false ){
		sipNumber = "sip:"+ sipNumber;
	}
	if(sipNumber.includes("@") == false ){
		sipNumber = sipNumber +"@"+sipServerName;
	}
	dispatch(makeSipVoiceCall(sipCallConn(state),sipNumber,
		errandId, phoneNumber, isFromManual, callPlanId));
	dispatch(postOutboundApiCall({errand: errandId,
		number: phoneNumber, type: "outbound"}))
	.then(data => {
		if(typeof data.result !== 'undefined' && data.result == "fail"){
			alert(data.message);
			return
		}
	});
};

export const makeOutboundAPICall = (isFromManual) => (dispatch, getState) =>{
	const state = getState();
	const wfSettings = state.app.workflow.fetchWfSettings.data;
	if(typeof wfSettings['sip.manual-call-checking'] === 'undefined' ||
		wfSettings['sip.manual-call-checking'] == false){
		dispatch(outboundAPICall(isFromManual));
		return
	}
	let phoneNumber = (manualCallMinimize(state) ? getCallOutboundPhoneTo(state) : getOutboundPhoneTo(state))
	let param = {};
	param.sipid = phoneNumber;
	dispatch(getSipIsSipAvailable(param))
	.then(data => {
		if(data.result == "success"){
			if(typeof data.data !== "undefined" && data.data != null){
				if(data.data.isavailableforcall == false){
					dispatch(togglePopAlert(data.data.extrainfo));
				} else {
					dispatch(outboundAPICall(isFromManual));
				}
			}
		}
	});
}

const hangupAPICall = () => (dispatch, getState) => {
	const state = getState()
		, phoneNumber = getOutboundPhoneTo(state)
		;
	dispatch(onEndSIPCall(sipCallConn(state)));
	dispatch(postHangupApiCall({errand: state.app.errand.ui.manualCall.createdId,
		number: phoneNumber, type: "hangup"}))
	.then(data => {
		dispatch(updateAventaStatus(SIP_CONNECTED));
	});
};

export const makeHangupAPICall = () => hangupAPICall();

const dialBackCall = (ws, cancel) => (dispatch, getState) => {
	const state = getState()
		, phoneNumber = firstUpdateToMemoize(state)
		;
	if (cancel) {
		return dispatch(cancelOutboundCall(ws, phoneNumber));
	}
	console.log("outbound dial-back to:", phoneNumber);
	dispatch(twilioOutbound(ws, phoneNumber, true));
};

export const cancelOutbound = ws => (dispatch, getState) => {
	if (outboundDialBack(getState())) {
		return dispatch(dialBackCall(ws, true));
	}
	return dispatch(outboundCall(ws, true))
};

export const dialBack = () => dispatch => {
	dispatch(toggleCallPadPopup(true));
	return dispatch(dialBackCall(websocket));
};

export const reconnect = () => (dispatch, getState) => {
	console.log("reconnect to phone server...");
	const me = meState(getState());
	if (me && me.tokens && me.tokens.twilio) {
		dispatch(twilioDeviceSetup(existingTwilioDevice(), me.tokens.twilio));
	} else {
		console.log("no valid token for reconnect to Twilio server:", me);
	}
};

export const retry = ws => checkAccept(ws);

export const replaceWorkplace = ws => dispatch => {
	dispatch(updateReplacing(true));
	startCallSocket(ws);
};
