import { createActions } from 'redux-actions';
import { V5, SEARCH, STATISTICS } from '../../../common/path';
import { I } from '../../../common/v5/config';
import { push, pushV5 } from '../../../common/v5/utils';
import {
	getAgentLastSeen,
	getAgentPing,
	getChatSessionCount,
	postAcceptChat,
	postAgentCurrentStatus,
	postAgentGetSelectedStatus,
	postErrandHeaderSettings,
	getAgentChatSourceConfig,
	postAventaLogin,
	postAgentChatSourceConfig,
	getAgentFavourite,
	postAgentFavourite,
	getAgentStatusList,
	getAgentLoggedStatus
} from './ajax';
import {
	keyAgentCurrentStatus,
	keyAgentGetSelectedStatus,
	keyAgentLastSeen,
	keyAgentPing,
	keyChangeAcceptChat,
	keyChangeHeaderTicker,
	keyChangePickupNext,
	keyChatSessionsCount,
	keyGetAgentChatSourceConfig,
	keyUpdateAgentAventaLogin,
	keyUpdateAgentChatSourceConfig,
	keyAgentFavourite,
	keyAgentStatusList,
	keyAgentLoggedIn
} from '../../constants/keys';
import {
	ALERT_CONFIRM_ENABLE_CUSTOM,
	ALERT_CONFIRM_AUTO_LOGOUT,
	ERRAND_ENABLE_CONFIRM,
	GLOBAL_SEARCH_FROM_BODY,
	SEARCH_ERRAND,
	SEARCH_COLLABORATION,
	SEARCH_AREA,
	SEARCH_TAGS,
	SEARCH_KB,
	SEARCH_CONTACTCARD,
	ALERT_CONFIRM_TO_LOGIN
} from '../../constants/constants';
import {
	ALRT_CFRM_OPR_NORMAL,
	ALRT_CFRM_OPR_OPTIONAL,
	NEW_ERRANDS,
	MY_ERRANDS,
	SEARCH_ERRANDS,
	PREF_LIST_VIEW,
	PREF_CONVERSATION_VIEW,
	RPLY_ERRAND,
	RPLY_COMMENT,
	RPLY_COLLABORATE,
	RPLY_EXT_FWD,
	REFRESH,
	TOGGLE_ALL,
	DELETE_SELECTED,
	CLOSE_SELECTED,
	SWITCH_VIEW,
	NEXT_PAGE,
	PREV_PAGE,
	NEXT_PAGE_SEARCH,
	PREV_PAGE_SEARCH,
	CLEAR_SEARCH,
	START_SEARCH,
	CLEAR_ANSWER,
	DELETE,
	TOGGLE_REPLY,
	TOGGLE_COMMENT,
	TOGGLE_COLLAB,
	TOGGLE_FRWD_XTRNL,
	ATTACH_FILE,
	RETURN_ERRAND,
	SPELLCHECK,
	SAVE,
	SEND,
	SEND_TO_ALL,
	CLOSE,
	DELETE_MESSAGE,
	NEW_MESSAGE,
	TOGGLE_ALL_MESSAGE,
	RPLY_MANUAL,
	STATISTICS_PAGE,
	MENU_ERRANDS,
	STATUS_ACQUIRED,
	AUTO_LOGOUT_COUNTDOWN_SECONDS,
	TXT_SYSTEM_NEED_RELOGIN
} from '../../../common/v5/constants';
import {
	alertConfirmClosed,
	alertPromiseStart,
	enablePickUpNext,
	showChatSourceConfig,
	updateChatSourceStatus,
	toggleErrandFavouriteFTADropdown,
	toggleErrandFTADropdown,
} from '../hmf';
import {
	agentSetErrandView,
	setAgentPreference
} from './workflow';
import {
	doDeleteErrand,
	doCloseErrand,
	putBackToInbox,
	sendReply,
	submitManualErrand,
	createOrEditNote,
	buttonClickSaveErrand
} from './errand';
import {
	submitCollaboration
} from './collaborate';
import {
	selectAllErrands,
	changeContext,
	setSelectedFolder,
	toggleWorkflowPopup,
	changeParamsThenReload
} from '../workflow';
import {
	clearInputText,
	selectShowReply,
	showUploadAttachment
} from '../errand';
import {
	handleResetSearch,
	doGlobalSearchByWS,
	handlePagination as handleSearchPagination
} from '../search';
import { isCallMemoize, isManualPopup } from '../../selectors/manual';
import {
	getErrandAllSelected,
	getSelectedIds as getSelectedIdsSelector
} from '../../selectors/workflow';
import {
	async,
	createActionCreators,
	loadOnceTracker,
	periodicPoll
} from '../../util';

export const headerMenuFooter = createActionCreators([
	keyAgentCurrentStatus,
	keyAgentGetSelectedStatus,
	keyAgentLastSeen,
	keyAgentPing,
	keyChangeAcceptChat,
	keyChangeHeaderTicker,
	keyChangePickupNext,
	keyChatSessionsCount,
	keyGetAgentChatSourceConfig,
	keyUpdateAgentAventaLogin,
	keyUpdateAgentChatSourceConfig,
	keyAgentFavourite,
	keyAgentStatusList,
	keyAgentLoggedIn
]);

// header
export const acceptChat = acceptChat => async(postAcceptChat({acceptChat}),
	headerMenuFooter[keyChangeAcceptChat]
);

export const changeHeaderTicker = tickerSelected => async(
	postErrandHeaderSettings({tickerSelected}),
	headerMenuFooter[keyChangeHeaderTicker]
);

export const changePickupNext = pickupSelected => (dispatch, getState) => {
	dispatch(async(postErrandHeaderSettings({pickupSelected}), headerMenuFooter[keyChangePickupNext]))
	.then( () =>{
		return dispatch(enablePickUpNext(pickupSelected));
	});
}

export const chatSessionCount = () => async(getChatSessionCount(),
	headerMenuFooter[keyChatSessionsCount]
);

export const updateFTAFavourite = (p) => (dispatch, getState) => {
	return dispatch(setAgentPreference({toggleForwardToAreaFavourites: p}))
		.then(()=> {
			dispatch(toggleErrandFavouriteFTADropdown(p));
		})
};

export const updateFTA = (p) => (dispatch, getState) => {
	return dispatch(setAgentPreference({toggleForwardToArea: p}))
		.then(()=> {
			dispatch(toggleErrandFTADropdown(p));
		})
};

// menu
export const agentCurrentStatus = (flag, autoRefresh) => async(
	postAgentCurrentStatus({flag, autoRefresh}),
	headerMenuFooter[keyAgentCurrentStatus]
);

export const selectedStatus = status => async(
	postAgentGetSelectedStatus({status}),
	headerMenuFooter[keyAgentGetSelectedStatus]
);

// footer
export const agentPing = () => async(getAgentPing(),
	headerMenuFooter[keyAgentPing]
);

export const agentLastSeen = (autoRefresh) => (dispatch, getState) => {
	return dispatch(async(getAgentLastSeen({autoRefresh}), headerMenuFooter[keyAgentLastSeen]))
	.then(result => {
		if(autoLogout != undefined && autoLogout.enabled ) {
			let now = Math.round(new Date().getTime()/1000)
			, cutoff = now - (autoLogout.timeout * 60) + AUTO_LOGOUT_COUNTDOWN_SECONDS
			;
			if( result.lastSeen <= cutoff ) {
				dispatch(stopPeriodicAutomaticLogout());
				dispatch(autoLogoutConfirm())
				.then(() => { // user logout or timeout
					window.location.href = webserverRoot + 'logout';
				})
				.catch(() => { // cancel logout
					dispatch(agentPing())
					.then(() => {
						dispatch(startPeriodicAutomaticLogout());
					})
				});
			}
		}
		return result;
	})
};

// enable confirmation modal and return promise that trigger then when yes is
// choosen and trigger catch when no is choosen.
export const enableConfirm = (opr, text, data) => dispatch => {
	dispatch({ type: ERRAND_ENABLE_CONFIRM, payload: { opr, text, data } })
	const p = alertPromiseStart(true)
	p.close = () => dispatch(alertConfirmClosed())
	return p
};

// Yes or No optional confirmation where cancel or No will reject promise and
// Yes will resolve promise.
export const optionalConfirm = text => enableConfirm(
	ALRT_CFRM_OPR_OPTIONAL
	, text
	, null
);

export const BT_YES = 1;
export const BT_NO = 2;
export const YES_NO_BUTTONS = [
	{
		type: BT_YES
		, text: I('Yes')
		, color: 'blue'
	}
	, {
		type: BT_NO
		, text: I('No')
		, color: 'grey'
		, cancel: true
	}
];

export const DISMISS_BUTTONS = [
	{
		type: BT_YES
		, text: I('Dismiss')
		, color: 'blue'
	}
];

// work like enable confirm - popup async windows and return promise where
// reject mean cancel. But this allow buttons to be customized where buttons
// is array of object with fields:
//   type (any - must unique) identifier for the button and fill up button field
//                            during resolve/reject.
//   cancel (boolean) determine whether it is being resolve or reject.
//   color (string) colour the button.
//   text (string) string that show on the button.
export const customConfirm = (text, buttons, data) => dispatch => {
	dispatch({
		type: ALERT_CONFIRM_ENABLE_CUSTOM
		, payload: {opr: ALRT_CFRM_OPR_NORMAL, text, buttons, data}
	});
	return alertPromiseStart(true);
};

export const autoLogoutConfirm = () => dispatch => {
	dispatch({
		type: ALERT_CONFIRM_AUTO_LOGOUT,
		payload: {opr: ALRT_CFRM_OPR_NORMAL}
	});
	return alertPromiseStart(true);
};

export const backToLoginForm = text => dispatch => {
	dispatch({
		type: ALERT_CONFIRM_TO_LOGIN,
		payload: {opr: ALRT_CFRM_OPR_NORMAL, text}
	});
	return alertPromiseStart(true);
};

export const redirectToErrands = (ctx) => dispatch => {
	return new Promise((resolve, reject) => {
		resolve(dispatch(pushV5(ctx)));
	})
	.then(() => {
		dispatch(changeContext(ctx));
	});
};

export const hotkeysAction = (act) => (dispatch, getState) => {
	const state = getState();
	let params = {};
	let currentActiveMenu = state.app.menu.mainMenu.activeMenuTitle;
	let wf = state.app.workflow;
	let wfs = state.app.workflow.fetchWfSettings.data;
	let wfsInput = state.app.workflow.listInputs;
	params.user = wfs.activeUserId;
	let currentReply = state.app.errand.ui.reply.current;
	let eid = state.app.errand.currentErrand.id, multiple = false;
	let ids = getSelectedIdsSelector(state);

	const search = state.app.search;
	let sb = search.ui.bodySearch;
	let gs = search.ui.globalSearch;
	let currentPagination = sb.currentPagination;
	let offset = 0, page = 0;

	//Looking for active pagination...
	if(currentPagination){
		page = sb[currentPagination+"Page"]+1;
	}else{
		//Note: The only way to know which pagination is active now is "currentPagination"
		//Multiple pagination can be shown at once in a global search page
		//But exception when certain search type is open for Example Search Contact card
		//clicked from the sidebar
		//if currentPagination empty, set it to the search type
		if(gs.isErrands){
			if(sb.showAllErrands){
				currentPagination = SEARCH_ERRAND;
			}
		}else if(gs.isCollaborations){
			if(sb.showAllCollabErrands){
				currentPagination = SEARCH_COLLABORATION;
			}
		}else if(gs.isAreas){
			if(sb.showAllArea){
				currentPagination = SEARCH_AREA;
			}
		}else if(gs.isTags){
			if(sb.showAllTag){
				currentPagination = SEARCH_TAGS;
			}
		}else if(gs.isContactCard){
			if(sb.showAllContact){
				currentPagination = SEARCH_CONTACTCARD;
			}
		}else if(gs.isKnowledgeBase){
			if(sb.showAllKB){
				currentPagination = SEARCH_KB;
			}
		}
	}

	switch (act) {
		//common
		case NEW_ERRANDS:
			if(currentActiveMenu !== MENU_ERRANDS){
				return dispatch(redirectToErrands(act));
			}else{
				if (isManualPopup(state)) {
					dispatch(toggleWorkflowPopup(1,false));
				}
				if(wfs.preferredNewErrandsAreaID.length > 0) {
					params.filterList = wfs.preferredNewErrandsAreaID.join(',');
				}
				params.source = act;
				dispatch(changeContext(act));
				dispatch(setSelectedFolder(0));
				dispatch(changeParamsThenReload(params));
			}
			break;
		case MY_ERRANDS:
			if(currentActiveMenu !== MENU_ERRANDS){
				return dispatch(redirectToErrands(act));
			}else{
				if (isManualPopup(state)) {
					dispatch(toggleWorkflowPopup(1,false));
				}
				if(wfs.preferredMyErrandsAreaID.length > 0) {
					params.filterList = wfs.preferredMyErrandsAreaID.join(',');
				}
				params.source = act;
				dispatch(changeContext(act));
				dispatch(changeParamsThenReload(params));
			}
			break;

		case STATISTICS_PAGE:
			dispatch(push(STATISTICS));
			break;

		case SEARCH_ERRANDS:
			dispatch(push(SEARCH));
			break;

		//workflow
		case REFRESH:
			dispatch(changeParamsThenReload({offset: 0}));
			break;
		case TOGGLE_ALL:
			let ctx = wfsInput.source;
			let allSelected = getErrandAllSelected(getState());
			dispatch(selectAllErrands(ctx, !allSelected));
			break;
		case DELETE_SELECTED:
			if(ids.length > 0){
				dispatch(doDeleteErrand(ids, true));
			}else{
				alert(I("Please select errand"));
			}
			break;
		case CLOSE_SELECTED:
			if(ids.length > 0){
				dispatch(doCloseErrand(ids, true));
			}else{
				alert(I("Please select errand"));
			}
			break;
		case SWITCH_VIEW:
			let changeViewTo = PREF_LIST_VIEW;
			if(wfs.agentErrandListViewPref){
				if(wfs.agentErrandListViewPref === PREF_LIST_VIEW){
					changeViewTo = PREF_CONVERSATION_VIEW;
				}else{
					changeViewTo = PREF_LIST_VIEW;
				}
			}
			dispatch(agentSetErrandView({preferredErrandsView: changeViewTo}));
			break;

		//workflow + list view
		case NEXT_PAGE:
			let totalErrands = wf.errandList.data.totalErrandCount;
			let totalPages = Math.ceil(totalErrands / wfsInput.size);
			let nextPage =((wfsInput.offset + 1) >= 0 ? (wfsInput.offset + 1 ) : 0);
			params.offset = nextPage;
			params.size = wfsInput.size;
			params.user = wfsInput.user;
			if(params.offset < totalPages) {
				return dispatch(changeParamsThenReload(params));
			}
			break;
		case PREV_PAGE:
			let prevPage =((wfsInput.offset - 1) < 0 ? 0 : (wfsInput.offset - 1));
			params.offset = prevPage;
			params.size =  wfsInput.size;
			params.user = wfsInput.user;
			if(params.offset >= 0) {
				return dispatch(changeParamsThenReload(params));
			}
			break;

		//search
		case CLEAR_SEARCH:
			dispatch(handleResetSearch());
			break;
		case START_SEARCH:
			//This will not working if the search input still focused/active
			dispatch(doGlobalSearchByWS(GLOBAL_SEARCH_FROM_BODY));
			break;
		case NEXT_PAGE_SEARCH:
			page = sb[currentPagination+"Page"]+1;
			if(page > 0){
				offset = Math.ceil((page) * sb.topResults);
			}
			dispatch(handleSearchPagination(offset, currentPagination, page));
			dispatch(doGlobalSearchByWS(GLOBAL_SEARCH_FROM_BODY));
			break;
		case PREV_PAGE_SEARCH:
			if(sb[currentPagination+"Page"] > 0){
				page = sb[currentPagination+"Page"]-1;
			}
			offset = Math.ceil((page) * sb.topResults);
			dispatch(handleSearchPagination(offset, currentPagination, page));
			dispatch(doGlobalSearchByWS(GLOBAL_SEARCH_FROM_BODY));
			break;

		//errand
		case CLEAR_ANSWER:
			if (isManualPopup(state)) {
				dispatch(clearInputText('update_answer', RPLY_MANUAL));
			}else{
				switch(currentReply) {
					case RPLY_ERRAND:
						dispatch(clearInputText('update_answer'));
						break;
					case RPLY_EXT_FWD:
						dispatch(clearInputText('update_answer'));
						break;
					case RPLY_COLLABORATE:
						dispatch(clearInputText('update_answer',
							RPLY_COLLABORATE));
						break;
					case RPLY_COMMENT:
						dispatch(clearInputText('internal_comment'));
						break;
				}
			}
			break;
		case DELETE:
			if(ids)
				dispatch(doDeleteErrand(ids));
			break;
		case TOGGLE_REPLY:
			dispatch(selectShowReply(RPLY_ERRAND));
			break;
		case TOGGLE_COMMENT:
			dispatch(selectShowReply(RPLY_COMMENT));
			break;
		case TOGGLE_COLLAB:
			dispatch(selectShowReply(RPLY_COLLABORATE));
			break;
		case TOGGLE_FRWD_XTRNL:
			dispatch(selectShowReply(RPLY_EXT_FWD));
			break;
		case ATTACH_FILE:
			if (isManualPopup(state)) {
				dispatch(showUploadAttachment(true));
			}else{
				dispatch(selectShowReply(RPLY_ERRAND, true));
				dispatch(showUploadAttachment(true));
			}
			break;
		case RETURN_ERRAND:
			if(eid === 0){
				multiple = true;
			}
			dispatch(putBackToInbox(ids, multiple));
			break;
		case SPELLCHECK:
			if (isManualPopup(state)) {
				CKEDITOR.instances["ckev5me"].execCommand('spellchecker');
			}else{
				CKEDITOR.instances["ckeditorv5"].execCommand('spellchecker');
			}
			break;
		case SAVE:
			if (currentReply == RPLY_COMMENT) {
				dispatch(createOrEditNote(false));
			} else {
				dispatch(buttonClickSaveErrand());
			}
		case SEND:
			if (isManualPopup(state)) {
				const isCall = isCallMemoize(state);
				let type = (isCall ? state.app.errand.ui.manualCall.tab : state.app.errand.ui.manual.tab), createType = 0;
				dispatch(submitManualErrand(type, createType, false, false));
			}else{
				switch(currentReply) {
					case RPLY_ERRAND:
						dispatch(sendReply({all: false, currentReply}));
						break;
					case RPLY_EXT_FWD:
						dispatch(sendReply({all: false, currentReply}));
						break;
					case RPLY_COLLABORATE:
						dispatch(submitCollaboration());
						break;
					case RPLY_COMMENT:
						dispatch(createOrEditNote(false));
				}
			}
			break;
		case SEND_TO_ALL:
			dispatch(sendReply({all: true, RPLY_ERRAND}));
			break;
		case CLOSE:
			if(ids)
				dispatch(doCloseErrand(ids));
			break;
		default:
			break;
	}
};

const FLG_INACTIVE = 0
	, FLG_ONLOAD = FLG_INACTIVE
	, FLG_ACTIVE = 1
	, quickStatusPollingTime = 20000
	;
const customPeriodicAgentStatusHandler = (data, error) => {
	if (typeof error !== "undefined"){
		//TODO(Mujibur): Other error codes might be handled later.
		//Currently, 401(StatusUnauthorized) where "401" represent the session cleared in backend.
		if (error.status === 401){
			return [isAgentLoggedIn(), 1000];
		}
	}
	if (data && data.timeLeft) {
		return [
			agentCurrentStatus(FLG_ACTIVE, true)
			, data.timeLeft * 1000
		];
	} else {
		return [
			agentCurrentStatus(FLG_INACTIVE, true)
			, quickStatusPollingTime
		];
	}
};

const isAgentLoggedIn = () => (dispatch, getState) =>{
	return dispatch(async(getAgentLoggedStatus(), headerMenuFooter[keyAgentLoggedIn]))
		.then( data =>{
			console.debug("dbg: it could be a false alarm, so ignore....", data);
			return
		})
		.catch( error =>{
			if ( error.status === 401 ){
				dispatch(stopPeriodicAgentStatus());
				return dispatch(backToLoginForm( TXT_SYSTEM_NEED_RELOGIN ))
					.then( () => {
						let path = window.location.protocol + "//" +window.location.hostname;
						window.location.href = path;
						return
					})
			} else {
				//other errors just ignore to popup
				return
			}
		});
}

const periodicAgentStatusHandlers = periodicPoll(
	agentCurrentStatus(FLG_ONLOAD, true)
	, null
	, customPeriodicAgentStatusHandler
);

export const stopPeriodicAgentStatus = periodicAgentStatusHandlers.stop;

const restartPeriodicAgentStatus = periodicAgentStatusHandlers.restart;

export const updateAgentStatus = status => (dispatch, getState) => {
	return dispatch(selectedStatus(status))
		.then(data => {
			const { status } = data;
			if (status) {
				dispatch(restartPeriodicAgentStatus());
			}
			return data;
		});
};

export const agentStatusOnLoad = () => updateAgentStatus("");

export const reloadAllLoadedOnce = () => loadOnceTracker.thunk();

export const activateChatSourceConfig = (open) => (dispatch, getState) => {
	return dispatch(async(getAgentChatSourceConfig(), headerMenuFooter[keyGetAgentChatSourceConfig]))
		.then(result => {
			if(!result.error) {
				dispatch(showChatSourceConfig(open));
				dispatch(updateChatSourceStatus());
			}
		});
}

export const fetchAgentFavourite = (name) => async(getAgentFavourite(name), headerMenuFooter[keyAgentFavourite]);
export const updateAgentFavourite = (name, id) => async(postAgentFavourite(name, id), headerMenuFooter[keyAgentFavourite]);

export const fetchAgentStatusList = (p) => async(getAgentStatusList(p), headerMenuFooter[keyAgentStatusList]);

export const updateChatSourceConfig = list => (dispatch, getState) => {
	dispatch(async(postAgentChatSourceConfig({disabled: list}), headerMenuFooter[keyUpdateAgentChatSourceConfig]))
	.then( result =>{
		if(!result.error) {
			dispatch(updateChatSourceStatus());
		}
	});
}

const retryAventaLogin = (dispatch) =>{
	if(typeof retryAventaLogin.intervalId === 'undefined'){
		retryAventaLogin.intervalId  = 0;
	}
	clearInterval(retryAventaLogin.intervalId);
	var retryfunc = function () {
		dispatch(async(postAventaLogin({login: true}),
		headerMenuFooter[keyUpdateAgentAventaLogin]))
		.then((data) => {
			if(data.status === "success"){
				clearInterval(retryAventaLogin.intervalId);
			}
		});
	};
	retryAventaLogin.intervalId = setInterval(retryfunc, 30000, dispatch);
}

export const updateAventaLogin = enable => (dispatch, getState) => {
	const st = getState();
	if(st.app.workflow.fetchWfSettings.data.aventaEnabled == false){
		return;
	}
	return dispatch(async(postAventaLogin({login: enable}),
	headerMenuFooter[keyUpdateAgentAventaLogin]))
	.then((data) => {
		if(enable == true && data.status !== "success"){
			retryAventaLogin(dispatch);
		}
	});
}

const customePeriodicAutomaticLogoutHandler = (data, error) => {
	if (data && data.lastSeen) {
		let n = new Date();
		let now = Math.round(n.getTime()/1000)
		, cutoff = now - (autoLogout.timeout * 60) + AUTO_LOGOUT_COUNTDOWN_SECONDS
		, timeLeft = data.lastSeen - cutoff
		;
		if( timeLeft > 0 ) {
			return [
				agentLastSeen(true)
				, timeLeft * 1000
			]
		}
	}
	return [
		agentLastSeen(true)
		, 30000
	];
};

const periodicAutomaticLogoutHandlers = periodicPoll(
	agentLastSeen(true)
	, null
	, customePeriodicAutomaticLogoutHandler
);

export const stopPeriodicAutomaticLogout = periodicAutomaticLogoutHandlers.stop;
export const startPeriodicAutomaticLogout = periodicAutomaticLogoutHandlers.start;
