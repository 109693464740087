import moment from 'moment';
import {
	DISABLE_PRINT,
	ENABLE_PRINT_COL_CONVERSATION,
	ENABLE_PRINT_COL_MESSAGE,
	ENABLE_PRINT_ERRANDS
} from '../constants/constants';

export const printCollaborationMessage = (thread, answer, id) => ({
	type: ENABLE_PRINT_COL_MESSAGE,
	payload: {thread, answer, id, time: moment().unix()}
});

export const printCollaborationConversation = thread => ({
	type: ENABLE_PRINT_COL_CONVERSATION,
	payload: {thread, time: moment().unix()}
});

export const printErrands = identifiers => ({
	type: ENABLE_PRINT_ERRANDS,
	payload: identifiers
});

export const disablePrint = () => ({type: DISABLE_PRINT});
